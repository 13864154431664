import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Spin, Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { getUserInfoRequest } from '../../actions/user';
import { logout } from '../../actions/auth';
import './ProfileButton.less';

function ProfileButton({ user, dispatch }) {
  const userInfo = user.info || {};
  const overlay = () => (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined style={{ marginRight: 15 }} />
        <FormattedMessage id="profilebutton.logout" />
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    dispatch(getUserInfoRequest());
  }, [dispatch]);

  useEffect(() => {
    if ((user.info || {}).name) {
      window.ZohoHCAsapSettings = {
        hideLauncherIcon: true,
        ticketsSettings: {
          preFillFields: {
            email: {
              defaultValue: user.info.contactEmail,
            },
            contactId: {
              defaultValue: `${user.info.contactFirstname} ${user.info.contactLastname}`,
            },
          },
        },
      };
    }
  }, [user.info]);

  return (
    <nav className="profile-button">
      <Spin spinning={!!user.currentlySending} size="small" width="100%">
        <Dropdown overlay={overlay} trigger={['click']}>
          <Button
            shape="round"
            type="text"
            icon={<Avatar size="small" style={{ marginRight: 8 }} icon={<UserOutlined />} />}
          >
            {userInfo.contactFirstname} {userInfo.contactLastname}
            <DownOutlined style={{ marginLeft: 5 }} />
          </Button>
        </Dropdown>
      </Spin>
    </nav>
  );
  function onMenuClick(e) {
    switch (e.key) {
      case 'logout':
        dispatch(logout());
        break;
      default:
        break;
    }
  }
}

export default connect(state => ({ user: state.user }))(ProfileButton);
