import {
  GETTING_CATALOG_HISTORY,
  SET_CATALOG_HISTORY,
  SET_CATALOG_HISTORY_ERROR,
} from '../actions/catalogHistory';

let initialState = {
  history: [],
  gettingHistory: false,
  error: '',
};

function catalogHistory(state = initialState, action) {
  const { type, getting, history, error } = action;
  switch (type) {
    case SET_CATALOG_HISTORY:
      return { ...state, history };
    case GETTING_CATALOG_HISTORY:
      return { ...state, gettingHistory: getting };
    case SET_CATALOG_HISTORY_ERROR:
      return { ...state, error };
    default:
      return state;
  }
}

export default catalogHistory;
