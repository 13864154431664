import {
  ADD_RULE,
  CREATING_RULE,
  GETTING_MISSING_REQUIRED_RULES,
  GETTING_RULE_TYPES,
  GETTING_RULES,
  SET_CREATE_RULE_ERROR,
  SET_MISSING_REQUIRED_RULES,
  SET_MISSING_REQUIRED_RULES_ERROR,
  SET_RULE_TYPES,
  SET_RULE_TYPES_ERROR,
  SET_RULES,
  SET_RULES_ERROR,
  SET_UPDATE_RULE_ERROR,
  UPDATING_RULE,
} from '../actions/strategy';

let initialState = {
  rules: {},
  ruleTypes: {},
  missingRules: {},
  gettingRules: false,
  gettingRuleTypes: false,
  gettingMissingRules: false,
  updatingRule: false,
  creatingRule: false,
  rulesError: '',
  ruleTypesError: '',
  missingRulesError: '',
  updateRuleError: '',
  createRuleError: '',
};

export function strategy(state = initialState, action) {
  let rules, type;
  switch (action.type) {
    case SET_RULES:
      return { ...state, rules: action.rules };
    case SET_MISSING_REQUIRED_RULES:
      return { ...state, missingRules: action.missingRules };
    case ADD_RULE:
      rules = { ...state.rules };
      type = action.rule.type.toLowerCase();
      if (rules[type]) {
        rules[type].push(action.rule);
      } else {
        rules[type] = [action.rule];
      }
      return { ...state, rules };
    case GETTING_RULES:
      return { ...state, gettingRules: action.gettingRules };
    case GETTING_MISSING_REQUIRED_RULES:
      return { ...state, gettingMissingRules: action.gettingMissingRules };
    case SET_RULES_ERROR:
      return { ...state, rulesError: action.rulesError };
    case SET_MISSING_REQUIRED_RULES_ERROR:
      return { ...state, missingRulesError: action.error };
    case SET_RULE_TYPES:
      return { ...state, ruleTypes: action.ruleTypes };
    case GETTING_RULE_TYPES:
      return { ...state, gettingRuleTypes: action.gettingRuleTypes };
    case SET_RULE_TYPES_ERROR:
      return { ...state, ruleTypesError: action.ruleTypesError };
    case UPDATING_RULE:
      return { ...state, updatingRule: action.updatingRule };
    case SET_UPDATE_RULE_ERROR:
      return { ...state, updateRuleError: action.updateRuleError };
    case CREATING_RULE:
      return { ...state, creatingRule: action.creatingRule };
    case SET_CREATE_RULE_ERROR:
      return { ...state, createRuleError: action.createRuleError };
    default:
      return state;
  }
}
