export const GETTING_CHANNELS = 'GETTING_CHANNELS';
export const CHANNELS_ERROR = 'CHANNELS_ERROR';
export const CLEAR_CHANNELS_ERROR = 'CLEAR_CHANNELS_ERROR';

export const GET_CHANNELS = 'GET_CHANNELS';
export const SET_CHANNELS = 'SET_CHANNELS';

export const getChannels = () => {
  return { type: GET_CHANNELS };
};
