import React from 'react';
import Icon from '@ant-design/icons';

import Rocket from './rocket.js';
import Calendar from './calendar.js';
import SettingsTable from './settings-table.js';
import Settings from './settings.js';
import FraisDePort from './frais-de-port.js';
import Active from './active.js';
import Ajustement from './ajustement.js';
import Comission from './comission.js';
import Comportement from './comportement.js';
import CoutTransport from './cout-transport.js';
import CoutsAdditionnels from './couts-additionnels.js';
import LimiteMax from './limite-max.js';
import LimiteMin from './limite-min.js';
import MargeMin from './marge-min.js';
import OffresSansConcurrent from './offres-sans-concurrent.js';
import PrixAchat from './prix-achat.js';
import PrixFinal from './prix-final.js';
import Taxes from './taxes.js';
import Wand from './wand.js';

export const RocketIcon = props => <Icon component={Rocket} {...props} />;
export const CalendarIcon = props => <Icon component={Calendar} {...props} />;
export const SettingsTableIcon = props => <Icon component={SettingsTable} {...props} />;
export const SettingsIcon = props => <Icon component={Settings} {...props} />;
export const FraisDePortIcon = props => <Icon component={FraisDePort} {...props} />;
export const ActiveIcon = props => <Icon component={Active} {...props} />;
export const AjustementIcon = props => <Icon component={Ajustement} {...props} />;
export const ComissionIcon = props => <Icon component={Comission} {...props} />;
export const ComportementIcon = props => <Icon component={Comportement} {...props} />;
export const CoutTransportIcon = props => <Icon component={CoutTransport} {...props} />;
export const CoutsAdditionnelsIcon = props => <Icon component={CoutsAdditionnels} {...props} />;
export const LimiteMaxIcon = props => <Icon component={LimiteMax} {...props} />;
export const LimiteMinIcon = props => <Icon component={LimiteMin} {...props} />;
export const MargeMinIcon = props => <Icon component={MargeMin} {...props} />;
export const OffresSansConcurrentIcon = props => (
  <Icon component={OffresSansConcurrent} {...props} />
);
export const PrixAchatIcon = props => <Icon component={PrixAchat} {...props} />;
export const PrixFinalIcon = props => <Icon component={PrixFinal} {...props} />;
export const TaxesIcon = props => <Icon component={Taxes} {...props} />;
export const WandIcon = props => <Icon component={Wand} {...props} />;

const IconComponents = {
  adjustment: <AjustementIcon />,
  cost: <CoutsAdditionnelsIcon />,
  margin: <MargeMinIcon />,
  commission: <ComissionIcon />,
  tax_rate: <TaxesIcon />,
  price_without_competitor: <OffresSansConcurrentIcon />,
  min_price: <LimiteMinIcon />,
  shipping_price: <FraisDePortIcon />,
  final_price: <PrixFinalIcon />,
  enable: <ActiveIcon />,
  behavior: <ComportementIcon />,
  cost_shipping: <CoutTransportIcon />,
  additional_cost: <PrixAchatIcon />,
  max_price: <LimiteMaxIcon />,
};

export default function getIcon(icon) {
  if (!icon) {
    return '';
  }
  if (Object.keys(IconComponents).indexOf(icon.toLowerCase()) < 0) {
    throw new Error(`${icon} does not exist!`);
  }
  return IconComponents[icon.toLowerCase()];
}
