export const BEHAVIOR_CAUTIOUS = 'cautious';
export const BEHAVIOR_MODERATE = 'moderate';
export const BEHAVIOR_AGGRESSIVE = 'aggressive';
export const BEHAVIOR_CAUTIOUS_V1 = 'normal';
export const BEHAVIOR_MODERATE_V1 = 'aggressive';
export const BEHAVIOR_AGGRESSIVE_V1 = 'harakiri';

export const FIELD_TYPE_RANGE = 'range';
export const FIELD_TYPE_SELECT = 'select';
export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_DATE = 'date';

export const ENUM_OPTIONS = {
  behavior: [BEHAVIOR_CAUTIOUS, BEHAVIOR_MODERATE, BEHAVIOR_AGGRESSIVE],
  behavior_v1: [BEHAVIOR_CAUTIOUS_V1, BEHAVIOR_MODERATE_V1, BEHAVIOR_AGGRESSIVE_V1],
  matching_status: ['pending', 'associated', 'not_associated'],
  status: [
    'no_offers',
    'challenger',
    'disable',
    'not_associated',
    'out_of_competition',
    'error',
    'buy_box_winner',
  ],
};

// TODO replace wth real logo
export class Product {
  constructor(product) {
    Object.assign(this, product); // first, grab all the api props, just in case we missed something

    this.uid = product.seller_sku || product.seller_reference;

    this.pricing = product.pricing || [];

    // TODO Hadrien format dates properly :
    // "created_at": "2018-12-24T13:10:31+00:00",
    // "updated_at": "2018-12-24T13:10:31+00:00"
  }

  static asList(products) {
    return (products || []).map(it => new Product(it));
  }

  static getFieldType(field) {
    switch (field) {
      case 'position':
      case 'target_position':
      case 'adjusted_price':
      case 'best_offer':
      case 'base_cost':
      case 'commission_amount':
      case 'tax_amount':
      case 'margin_amount':
      case 'base_price':
      case 'cost':
      case 'final_margin':
      case 'final_price':
      case 'margin_for_bbw':
      case 'max_price':
      case 'min_price':
      case 'final_min_price':
      case 'final_max_price':
      case 'price_excl_tax':
      case 'price_incl_tax':
      case 'recommended_price':
      case 'shipping_excl_tax':
      case 'shipping_incl_tax':
      case 'shipping_price':
      case 'special_price':
      case 'stock':
      case 'taxes':
      case 'weight':
        return FIELD_TYPE_RANGE;
      case 'bbw_name':
      case 'bbw_reference':
      case 'bestseller':
      case 'brand':
      case 'buy_box_winner':
      case 'category':
      case 'channel':
      case 'channel_reference':
      case 'condition':
      case 'delay':
      case 'ean':
      case 'image_url':
      case 'manufacturer_reference':
      case 'seller_reference':
      case 'supplier':
      case 'supplier_reference':
      case 'title':
        return FIELD_TYPE_TEXT;
      case 'active':
      case 'behavior':
      case 'has_changed':
      case 'matching_status':
      case 'status':
        return FIELD_TYPE_SELECT;
      case 'updated_at':
        return FIELD_TYPE_DATE;
      default:
        return FIELD_TYPE_TEXT;
    }
  }

  static getFieldSelectOptions(field) {
    switch (field) {
      case 'active':
        return [
          { label: '', value: '' },
          { label: 'channel.offers.pricing.filter.product-status.active', value: '1' },
          { label: 'channel.offers.pricing.filter.product-status.inactive', value: '0' },
        ];
      case 'behavior':
        return [
          { label: '', value: '' },
          { label: 'channel.offers.pricing.filter.behavior.cautious', value: BEHAVIOR_CAUTIOUS_V1 },
          { label: 'channel.offers.pricing.filter.behavior.moderate', value: BEHAVIOR_MODERATE_V1 },
          {
            label: 'channel.offers.pricing.filter.behavior.aggressive',
            value: BEHAVIOR_AGGRESSIVE_V1,
          },
        ];
      case 'has_changed':
        return [
          { label: '', value: '' },
          { label: 'word.yes', value: '1' },
          { label: 'word.no', value: '0' },
        ];
      case 'matching_status':
        return [
          { label: '', value: '' },
          {
            label: 'channel.offers.pricing.filter.matching-status.associated',
            value: 'associated',
          },
          {
            label: 'channel.offers.pricing.filter.matching-status.not_associated',
            value: 'not_associated',
          },
          { label: 'channel.offers.pricing.filter.matching-status.pending', value: 'pending' },
        ];
      case 'status':
        return [
          { label: '', value: '' },
          { label: 'channel.offers.pricing.filter.status.buy_box_winner', value: 'buy_box_winner' },
          { label: 'channel.offers.pricing.filter.status.challenger', value: 'challenger' },
          {
            label: 'channel.offers.pricing.filter.status.out_of_competition',
            value: 'out_of_competition',
          },
          { label: 'channel.offers.pricing.filter.status.no_offers', value: 'no_offers' },
          { label: 'channel.offers.pricing.filter.status.not_associated', value: 'not_associated' },
          { label: 'channel.offers.pricing.filter.status.error', value: 'error' },
          { label: 'channel.offers.pricing.filter.status.disable', value: 'disable' },
        ];
      default:
        return [];
    }
  }
}
