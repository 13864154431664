import {
  CHANGE_FORM,
  SET_AUTH,
  AUTHENTICATING,
  RESET_PASSWORD,
  RESETTING_REQUEST,
  AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  LOGOUT,
} from '../actions/auth';
import auth from '../api/auth';

// The initial application state
let initialState = {
  formState: {
    email: '',
    password: '',
  },
  error: '',
  currentlySending: false,
  resetPassword: false,
  askResetPassword: false,
  resetting: false,
  loggedIn: auth.loggedIn(),
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FORM:
      return { ...state, formState: action.newFormState };
    case SET_AUTH:
      return { ...state, loggedIn: action.newAuthState };
    case AUTHENTICATING:
      return { ...state, currentlySending: action.sending };
    case RESETTING_REQUEST:
      return { ...state, resetting: action.resetting };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.resetPassword,
        askResetPassword: action.askResetPassword,
      };
    case AUTH_ERROR:
      return { ...state, error: action.error };
    case CLEAR_AUTH_ERROR:
      return { ...state, error: '' };
    case LOGOUT:
      return { ...state, loggedIn: false };
    default:
      return state;
  }
}

export default reducer;
