export const GET_CACHE_VALUE = 'GET_CACHE_VALUE';
export const GETTING_CACHE_VALUE = 'GETTING_CACHE_VALUE';
export const GET_CACHE_VALUE_ERROR = 'GET_CACHE_VALUE_ERROR';
export const SET_CACHE_VALUE = 'SET_CACHE_VALUE';

export const UPDATE_CACHE_VALUE = 'UPDATE_CACHE_VALUE';
export const UPDATING_CACHE_VALUE = 'UPDATING_CACHE_VALUE';
export const UPDATE_CACHE_VALUE_ERROR = 'UPDATE_CACHE_VALUE_ERROR';

export const DELETE_CACHE_VALUE = 'DELETE_CACHE_VALUE';
export const DELETING_CACHE_VALUE = 'DELETING_CACHE_VALUE';
export const DELETE_CACHE_VALUE_ERROR = 'DELETE_CACHE_VALUE_ERROR';

export const getTrackedProducts = () => {
  return { type: GET_CACHE_VALUE, key: 'tracked_products' };
};

export const updateTrackedProduct = trackedProducts => {
  return { type: UPDATE_CACHE_VALUE, key: 'tracked_products', value: trackedProducts };
};
