import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';

import '../less/style.less';
// todo Find a way to let webpack include only the necessary file per component.
// http://pro.ant.design/docs/use-components-alone is suppose to work but for some reason, it doesn't.
import 'ant-design-pro/dist/ant-design-pro.css';

import routes from '../routes';

if (process.env.REACT_APP_CURRENT === 'local' && process.env.REACT_APP_MOCK) {
  require('../api/mocks.api');
}

let fetchedTrads = false;

const App = ({ updateTrads, history, loggedIn }) => {
  useEffect(() => {
    if (!+localStorage.getItem('sawOnboard')) {
      localStorage.setItem('sawOnboard', 1);
      history.push('/onboard');
    }
  }, [history]);

  useEffect(() => {
    if (loggedIn && !fetchedTrads) {
      fetchedTrads = true;
      updateTrads();
    }
  }, [loggedIn, updateTrads]);

  return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
};

App.propTypes = {
  history: PropTypes.object,
};

export default connect(state => ({ loggedIn: state.auth.loggedIn }))(App);
