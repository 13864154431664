import { mapKeys, camelCase } from 'lodash';
import { call, put, take } from 'redux-saga/effects';

import user from '../api/user';
import { GET_USER_INFO_REQUEST, REQUEST_ERROR, GETTING_USER, SET_USER_INFO } from '../actions/user';

export function* getInfo() {
  yield put({
    type: GETTING_USER,
    sending: true,
  });

  try {
    return yield call(user.getInfo);
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error: error.message });
    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: GETTING_USER, sending: false });
  }
}

/**
 * Log in saga
 */
export function* getInfoFlow() {
  while (true) {
    yield take(GET_USER_INFO_REQUEST);
    let userInfo = yield call(getInfo);
    if (userInfo) {
      userInfo = mapKeys(userInfo.body, (v, k) => camelCase(k));
      yield put({ type: SET_USER_INFO, info: userInfo });
    }
  }
}
