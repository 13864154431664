import Images from '../../tools/images';

// capture "amazon_de" from "amazon_de_final_price"
const REGEX_CAPTURE_CHANNEL = '^([^_]+_[^_]+)';

export class Channel {
  constructor(channel) {
    Object.assign(this, channel); // first, grab all the api props, just in case we missed something

    this.id = channel.id;
    this.name = channel.organization;
    this.country = channel.locale;
    this.seller_ref = `${channel.organization}_${channel.locale}`;
    this.api_ref = channel.uid || `${channel.organization}_${channel.locale}_default`;
    this.status = channel.status;
    this.logo = Images.getLogo(this.name);
    this.flag = Images.getFlag(this.country);
    this.currency = channel.currency;
  }

  withConfiguration() {
    return Object.assign(this, { api: {}, progress: [] });
  }

  static asList(channels) {
    return (channels || []).map(it => new Channel(it));
  }
}

export function extractChannelLabel(channelRef) {
  const match = channelRef.match(REGEX_CAPTURE_CHANNEL);
  if (!match || match.length < 2) {
    return '';
  }
  return channelRef.match(REGEX_CAPTURE_CHANNEL)[1];
}

export function isApiLinkConfigurable(channel) {
  return !/google|bol|custom/.test(channel);
}

export function extractChannelCountry(channelRef) {
  const shortChannel = extractChannelLabel(channelRef);
  return shortChannel ? shortChannel.substr(-2) : null;
}
