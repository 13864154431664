import { call, put, take } from 'redux-saga/effects';

import ChannelsApi from '../api/channels';
import {
  SEND_CHANNELS_SUBSCRIPTION_ERROR,
  SEND_SUBSCRIPTION_MAIL,
  SENDING_CHANNELS_SUBSCRIPTION_MAIL,
  SET_CHANNELS_SUBSCRIPTION_MAIL_SENT,
} from '../actions/channelsSubscription';

export function* sendChannelSubscriptionMail() {
  while (true) {
    const params = yield take(SEND_SUBSCRIPTION_MAIL);

    yield put({
      type: SENDING_CHANNELS_SUBSCRIPTION_MAIL,
      sending: true,
      channelId: params.channelId,
    });
    try {
      let result = yield call(ChannelsApi.sendSubscriptionMail, params.channelId);
      if (result.error) {
        yield put({ type: SEND_CHANNELS_SUBSCRIPTION_ERROR, error: result.error });
      } else {
        yield put({ type: SET_CHANNELS_SUBSCRIPTION_MAIL_SENT, channelId: result.channelId });
      }
    } catch (error) {
      yield put({ type: SEND_CHANNELS_SUBSCRIPTION_ERROR, error: error.message });
    } finally {
      yield put({
        type: SENDING_CHANNELS_SUBSCRIPTION_MAIL,
        sending: false,
        channelId: params.channelId,
      });
    }
  }
}
