export const GETTING_USER = 'GETTING_USER';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const SET_USER_INFO = 'SET_USER_INFO';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const getUserInfoRequest = data => {
  return { type: GET_USER_INFO_REQUEST, data };
};

export const requestError = error => {
  return { type: REQUEST_ERROR, error };
};

export const clearError = () => {
  return { type: CLEAR_ERROR };
};
