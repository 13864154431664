import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Alert } from 'antd';

class Error extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <Alert
        message={intl.formatMessage({
          id: this.props.message,
          defaultMessage: this.props.message,
        })}
        type="error"
        style={{ fontSize: '1rem', ...this.props.style }}
      />
    );
  }
}

export default injectIntl(Error);

Error.propTypes = {
  message: PropTypes.string,
};
