import { call, put, take, all } from 'redux-saga/effects';

import { SETTING_BEHAVIOR, SET_PRODUCTS_BEHAVIOR, REQUEST_ERROR } from '../actions/behavior';
import ChannelsApi from '../api/channels';

export function* setProductsBehaviorFlow() {
  while (true) {
    const params = yield take(SET_PRODUCTS_BEHAVIOR);
    yield put({ type: SETTING_BEHAVIOR, sending: true });
    try {
      const calls = params.channels.map(channelId =>
        call(ChannelsApi.setProductsBehavior, params.products, params.behavior, channelId)
      );
      yield all(calls);
    } catch (error) {
      yield put({ type: REQUEST_ERROR, error: error.message });
    } finally {
      yield put({ type: SETTING_BEHAVIOR, sending: false });
    }
  }
}
