import React, { useMemo } from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HomeOutlined from '@ant-design/icons/HomeOutlined';

import { ROUTES, routeWithParams } from '../../routes/routes';
import { useFormatMessage } from '../../hooks/intl.hook';

import './MainMenu.less';

function MainMenu({ channels, reports, match }) {
  const intl = useFormatMessage();
  const defaultSelectedKeys = getDefaultSelectedKeys();
  const items = useMemo(() => {
    return [
      {
        key: ROUTES.HOME,
        type: 'home',
        icon: <HomeOutlined style={{ margin: 0, padding: 8 }} />,
      },
      {
        key: 'channels',
        type: 'shop',
        id: 'menu.channels',
        subItems: channels.map(chan => {
          const goTo = routeWithParams(ROUTES.CHANNEL, {
            channel_id: chan.id + '',
            channel_name: chan.name,
          });
          return { goTo, key: chan.id + '', label: chan.label };
        }),
      },
      {
        key: ROUTES.CATALOG,
        type: 'book',
        id: 'menu.catalog',
      },
      {
        key: 'strategies',
        type: 'pie-chart',
        id: 'menu.strategies',
        subItems: [
          {
            goTo: ROUTES.STRATEGIES,
            label: intl({ id: 'strategies.menubar.rules' }),
          },
          {
            goTo: ROUTES.STRATEGIES_CARRIERS,
            label: intl({ id: 'strategies.menubar.carriers' }),
          },
        ],
      },
      {
        key: ROUTES.REPORTS,
        type: 'bar-chart',
        id: 'menu.reports',
        disabled: !reports.length,
      },
      {
        key: 'configuration',
        type: 'setting',
        id: 'menu.configurations',
        subItems: [
          {
            goTo: routeWithParams(ROUTES.CONFIGURATIONS, { segment: 'catalog' }),
            label: intl({ id: 'menu.catalog' }),
          },
          {
            goTo: routeWithParams(ROUTES.CONFIGURATIONS, { segment: 'channel' }),
            label: intl({ id: 'configuration.menu.channel' }),
          },
        ],
      },
    ];
  }, [channels, intl, reports]);

  function getDefaultSelectedKeys() {
    return match.params.channel_id ? [match.params.channel_id] : [window.location.pathname];
  }
  return (
    <div className="main-menu">
      <Menu
        className="main-menu__container"
        mode="horizontal"
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {items.map(item => {
          if (item.subItems) {
            return (
              <Menu.SubMenu
                key={item.key}
                title={item.id ? <span>{intl({ id: item.id })}</span> : item.icon || null}
              >
                {item.subItems.map(subItem => {
                  return (
                    <Menu.Item key={subItem.key || subItem.goTo} disabled={item.disabled}>
                      <Link to={subItem.goTo}>
                        <span>{subItem.label}</span>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
            );
          } else {
            return item.disabled ? null : (
              <Menu.Item className="main-menu__item" key={item.key} style={{ paddingLeft: 0 }}>
                <Link to={item.key}>
                  {item.id ? <span>{intl({ id: item.id })}</span> : item.icon || null}
                </Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </div>
  );
}

MainMenu.propTypes = {
  channels: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

export default MainMenu;
