// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place

import { push } from 'react-router-redux';
import { delay } from 'redux-saga';
import { take, call, put, race } from 'redux-saga/effects';

import auth from '../api/auth';
import {
  AUTHENTICATING,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  SET_AUTH,
  LOGOUT,
  CHANGE_FORM,
  AUTH_ERROR,
  PASSWORD_RESET_REQUEST,
  RESETTING_REQUEST,
  RESET_PASSWORD,
  SEND_PASSWORD_RESET_REQUEST,
} from '../actions/auth';
import { ROUTES } from '../routes/routes';

/**
 * Effect to handle authorization
 * @param  {string} email               The email of the user
 * @param  {string} password               The password of the user
 * @param  {object} options                Options
 * @param  {boolean} options.isRegistering Is this a register request?
 */
export function* authorize({ email, password, isRegistering }) {
  // We send an action that tells Redux we're sending a request
  yield put({
    type: AUTHENTICATING,
    sending: true,
  });

  // We then try to register or log in the user, depending on the request
  try {
    let response;

    // For either log in or registering, we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    if (isRegistering) {
      response = yield call(auth.register, email, password);
    } else {
      response = yield call(auth.login, email, password);
    }

    return response;
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put({ type: AUTH_ERROR, error: error.message });

    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: AUTHENTICATING, sending: false });
  }
}

/**
 * Effect to handle logging out
 */
export function* logout() {
  // We tell Redux we're in the middle of a request
  yield put({ type: AUTHENTICATING, sending: true });

  // Similar to above, we try to log out by calling the `logout` function in the
  // `auth` module. If we get an error, we send an appropiate action. If we don't,
  // we return the response.
  try {
    const response = yield call(auth.logout);
    yield put({ type: AUTHENTICATING, sending: false });

    return response;
  } catch (error) {
    yield put({ type: AUTH_ERROR, error: error.message });
  }
}

/**
 * Log in saga
 */
export function* loginFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(LOGIN_REQUEST);
    const { email, password } = request.data;

    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const winner = yield race({
      autho: call(authorize, { email, password, isRegistering: false }),
      logout: take(LOGOUT),
    });

    // If `authorize` was the winner...
    if (winner.autho) {
      // ...we send Redux appropiate actions
      yield put({ type: SET_AUTH, newAuthState: true }); // User is logged in (authorized)
      yield put({
        type: CHANGE_FORM,
        newFormState: { email: '', password: '' },
      }); // Clear form

      yield put(push('/home'));
    }
  }
}

/**
 * Ask Reset password
 */
export function* askResetPasswordFlow() {
  while (true) {
    const request = yield take(PASSWORD_RESET_REQUEST);
    yield put({ type: RESETTING_REQUEST, resetting: true });

    try {
      let response = yield call(auth.askResetPassword, request.email);
      if (response) {
        yield put({ type: RESET_PASSWORD, askResetPassword: true });
      }
      return response;
    } catch (error) {
      yield put({ type: AUTH_ERROR, error: error.message });
      return false;
    } finally {
      yield put({ type: RESETTING_REQUEST, resetting: false });
    }
  }
}

/**
 * Reset password
 */
export function* resetPasswordFlow() {
  while (true) {
    const { token, password } = yield take(SEND_PASSWORD_RESET_REQUEST);
    yield put({ type: RESETTING_REQUEST, resetting: true });

    try {
      let response = yield call(auth.resetPassword, { password, token });
      if (response) {
        yield put({ type: RESET_PASSWORD, resetPassword: true });

        yield delay(2000);
        yield put(push(ROUTES.LOGIN));
      }
      return response;
    } catch (error) {
      yield put({ type: AUTH_ERROR, error: error.message });
      return false;
    } finally {
      yield put({ type: RESETTING_REQUEST, resetting: false });
    }
  }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export function* logoutFlow() {
  while (true) {
    yield take(LOGOUT);
    yield put({ type: SET_AUTH, newAuthState: false });

    yield call(logout);
    yield put(push('/login'));
  }
}

/**
 * Register saga
 * Very similar to log in saga!
 */
export function* registerFlow() {
  while (true) {
    // We always listen to `REGISTER_REQUEST` actions
    const request = yield take(REGISTER_REQUEST);
    const { email, password } = request.data;

    // We call the `authorize` task with the data, telling it that we are registering a user
    // This returns `true` if the registering was successful, `false` if not
    const wasSuccessful = yield call(authorize, {
      email,
      password,
      isRegistering: true,
    });

    // If we could register a user, we send the appropiate actions
    if (wasSuccessful) {
      yield put({ type: SET_AUTH, newAuthState: true }); // User is logged in (authorized) after being registered
      yield put({
        type: CHANGE_FORM,
        newFormState: { email: '', password: '' },
      }); // Clear form
      yield put(push('/home')); // Go to dashboard page
    }
  }
}
