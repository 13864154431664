import { call, put, take } from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  DELETE_CACHE_VALUE,
  DELETE_CACHE_VALUE_ERROR,
  DELETING_CACHE_VALUE,
  GET_CACHE_VALUE,
  GET_CACHE_VALUE_ERROR,
  GETTING_CACHE_VALUE,
  SET_CACHE_VALUE,
  UPDATE_CACHE_VALUE,
  UPDATE_CACHE_VALUE_ERROR,
  UPDATING_CACHE_VALUE,
} from '../actions/cache';
import Cache from '../api/cache';

export function* getCacheValue() {
  while (true) {
    const { key } = yield take(GET_CACHE_VALUE);
    yield put({ type: GETTING_CACHE_VALUE, processing: true });
    try {
      const result = yield call(Cache.getCacheValue, key);
      yield put({
        type: SET_CACHE_VALUE,
        key: _.camelCase(key),
        value: result === '' ? [] : result,
      });
    } catch (error) {
      yield put({ type: GET_CACHE_VALUE_ERROR, error });
    } finally {
      yield put({ type: GETTING_CACHE_VALUE, processing: false });
    }
  }
}

export function* updateCacheValue() {
  while (true) {
    const { key, value } = yield take(UPDATE_CACHE_VALUE);
    yield put({ type: UPDATING_CACHE_VALUE, processing: true });
    try {
      yield call(Cache.setCacheValue, key, value);
      yield put({ type: SET_CACHE_VALUE, key: _.camelCase(key), value });
    } catch (error) {
      yield put({ type: UPDATE_CACHE_VALUE_ERROR, error });
    } finally {
      yield put({ type: UPDATING_CACHE_VALUE, processing: false });
    }
  }
}

export function* deleteCacheValue() {
  while (true) {
    const { key, value } = yield take(DELETE_CACHE_VALUE);
    yield put({ type: DELETING_CACHE_VALUE, processing: true });
    try {
      yield call(Cache.deleteCacheValue, key, value);
    } catch (error) {
      yield put({ type: DELETE_CACHE_VALUE_ERROR, error });
    } finally {
      yield put({ type: DELETING_CACHE_VALUE, processing: false });
    }
  }
}
