import {
  CLEAR_ERROR_CHANNEL_MATCHING_STATUS,
  GETTING_CHANNEL_MATCHING_STATUS,
  GETTING_COMPETITORS_STATS,
  GETTING_MARGIN_INFO,
  GETTING_POSITION_BY_DATE,
  GETTING_STATUS_BY_DATE,
  GETTING_TARGET_POSITION_BY_DATE,
  SET_CHANNEL_MATCHING_STATUS,
  SET_CHANNEL_MATCHING_STATUS_ERROR,
  SET_COMPETITORS_STATS,
  SET_COMPETITORS_STATS_ERROR,
  SET_MARGIN_INFO,
  SET_MARGIN_INFO_ERROR,
  SET_POSITION_BY_DATE,
  SET_POSITION_BY_DATE_ERROR,
  SET_STATUS_BY_DATE,
  SET_STATUS_BY_DATE_ERROR,
  SET_TARGET_POSITION_BY_DATE,
  SET_TARGET_POSITION_BY_DATE_ERROR,
} from '../actions/channelStats';

let initialState = {
  matching: {},
  gettingMatching: false,
  matchingError: '',

  margin: {},
  gettingMargin: false,
  marginError: '',

  competitorsStats: {},
  gettingCompetitorsStats: false,
  competitorsStatsError: '',

  positionByDate: [],
  gettingPositionByDate: false,
  positionByDateError: '',

  targetPositionByDate: [],
  gettingTargetPositionByDate: false,
  targetPositionByDateError: '',

  statusByDate: [],
  gettingStatusByDate: false,
  statusByDateError: '',
};

export function channelStats(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL_MATCHING_STATUS:
      return { ...state, matching: action.matching };
    case GETTING_CHANNEL_MATCHING_STATUS:
      return { ...state, gettingMatching: action.gettingMatching };
    case SET_CHANNEL_MATCHING_STATUS_ERROR:
      return { ...state, matchingError: action.error };
    case CLEAR_ERROR_CHANNEL_MATCHING_STATUS:
      return { ...state, matchingError: '' };
    case SET_MARGIN_INFO:
      return { ...state, margin: action.margin };
    case GETTING_MARGIN_INFO:
      return { ...state, gettingMargin: action.gettingMargin };
    case SET_MARGIN_INFO_ERROR:
      return { ...state, marginError: action.error };
    case SET_COMPETITORS_STATS:
      return { ...state, competitorsStats: action.competitorsStats };
    case GETTING_COMPETITORS_STATS:
      return { ...state, gettingCompetitorsStats: action.gettingCompetitorsStats };
    case SET_COMPETITORS_STATS_ERROR:
      return { ...state, competitorsStatsError: action.error };
    case SET_POSITION_BY_DATE:
      return { ...state, positionByDate: action.positionByDate };
    case GETTING_POSITION_BY_DATE:
      return { ...state, gettingPositionByDate: action.gettingPositionByDate };
    case SET_POSITION_BY_DATE_ERROR:
      return { ...state, positionByDateError: action.error };
    case SET_TARGET_POSITION_BY_DATE:
      return { ...state, targetPositionByDate: action.targetPositionByDate };
    case GETTING_TARGET_POSITION_BY_DATE:
      return { ...state, gettingTargetPositionByDate: action.gettingTargetPositionByDate };
    case SET_TARGET_POSITION_BY_DATE_ERROR:
      return { ...state, targetPositionByDateError: action.error };
    case SET_STATUS_BY_DATE:
      return { ...state, statusByDate: action.statusByDate };
    case GETTING_STATUS_BY_DATE:
      return { ...state, gettingStatusByDate: action.gettingStatusByDate };
    case SET_STATUS_BY_DATE_ERROR:
      return { ...state, statusByDateError: action.error };
    default:
      return state;
  }
}
