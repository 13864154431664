import { call, put, select, take, all } from 'redux-saga/effects';

import ChannelsApi from '../api/channels';
import {
  CLEAR_ERROR_CHANNEL_API_CONFIGURATION,
  GET_CHANNEL_API_CONFIGURATION,
  GET_CHANNEL_CONFIGURATION_STATUS,
  GET_CHANNEL_PRODUCT_INFORMATION,
  GET_CHANNEL_PRODUCT_OFFERS,
  GET_CHANNEL_SELLER_REFERENCE,
  GET_CHANNELS_PRODUCT_INFORMATION,
  GETTING_CHANNEL_API_CONFIGURATION,
  GETTING_CHANNEL_CONFIGURATION_STATUS,
  GETTING_CHANNEL_PRODUCT_INFORMATION,
  GETTING_CHANNEL_PRODUCT_OFFERS,
  GETTING_CHANNEL_SELLER_REFERENCE,
  POST_CHANNEL_SELLER_REFERENCE,
  POSTING_CHANNEL_SELLER_REFERENCE,
  PUT_CHANNEL_API_CONFIGURATION,
  PUTTING_CHANNEL_API_CONFIGURATION,
  SET_CHANNEL_API_CONFIGURATION,
  SET_CHANNEL_CONFIGURATION_STATUS,
  SET_CHANNEL_PRODUCT_INFORMATION,
  SET_CHANNEL_PRODUCT_INFORMATION_ERROR,
  SET_CHANNEL_PRODUCT_OFFERS,
  SET_CHANNEL_PRODUCT_OFFERS_ERROR,
  SET_CHANNEL_SELLER_REFERENCE,
  SET_SIMULATION_PRICING_ERROR,
  SET_SIMULATION_PRICING_INFO,
  SIMULATE_PRICING,
  SIMULATING_PRICING,
  GET_OFFERS_HISTORY,
  GETTING_OFFERS_HISTORY,
  SET_OFFERS_HISTORY,
  SET_OFFERS_HISTORY_ERROR,
  GET_OFFERS_HISTORIES,
  GET_CHANNELS_SELLER_REFERENCES,
  GET_CHANNELS_PRODUCT_OFFERS,
  SIMULATE_MULTIPLE_PRICING,
  GET_PRICING_HISTORY,
  GETTING_PRICING_HISTORY,
  SET_PRICING_HISTORY,
  SET_PRICING_HISTORY_ERROR,
  GET_CHANNEL_PRODUCT_BEHAVIOR,
  GETTING_CHANNEL_PRODUCT_BEHAVIOR,
  SET_CHANNEL_PRODUCT_BEHAVIOR,
  SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR,
  GET_CHANNELS_PRODUCT_BEHAVIOR,
} from '../actions/channel';
import ChannelsSellerApi from '../api/channelSeller.api';
import { throwError } from '../api';
import {
  GET_CHANNELS_CONFIGURATION_STATUS,
  GETTING_CHANNELS_CONFIGURATION_STATUS,
  SET_CHANNELS_CONFIGURATION_STATUS,
} from '../actions/channelsConfiguration';
import { apiDateToReadableDate } from '../tools/date';

export function* getChannelConfigurationStatus() {
  while (true) {
    const request = yield take(GET_CHANNEL_CONFIGURATION_STATUS);

    yield put({ type: GETTING_CHANNEL_CONFIGURATION_STATUS, gettingProgress: true });
    try {
      const response = yield call(ChannelsApi.getChannelConfigurationStatus, {
        channel: request.channel_id,
      });
      const progress = [
        { id: 'api', label: 'status-timeline.api-label', status: '', checked: response.connected },
        {
          id: 'frequency',
          label: 'status-timeline.frequency-label',
          status: response.frequency,
          checked: true,
        },
        {
          id: 'sync-price',
          label: 'status-timeline.sync-price-label',
          status: '',
          checked: response.sync_price,
        },
      ];
      if (progress.length >= 0) {
        yield put({ type: SET_CHANNEL_CONFIGURATION_STATUS, progress });
      }
    } catch (error) {
      yield put({ type: SET_CHANNEL_CONFIGURATION_STATUS, progress: [] });
    } finally {
      yield put({ type: GETTING_CHANNEL_CONFIGURATION_STATUS, gettingProgress: false });
    }
  }
}

export function* getChannelsConfigurationStatus() {
  while (true) {
    const request = yield take(GET_CHANNELS_CONFIGURATION_STATUS);
    yield put({ type: GETTING_CHANNELS_CONFIGURATION_STATUS, getting: true });
    try {
      const statusesPromises = request.channels.map(e =>
        ChannelsApi.getChannelConfigurationStatus({ channel: e.uid })
      );
      const responses = yield Promise.all(statusesPromises);
      const statuses = responses.map(response => [
        { id: 'api', label: 'status-timeline.api-label', status: '', checked: response.connected },
        {
          id: 'frequency',
          label: 'status-timeline.frequency-label',
          status: response.frequency,
          checked: true,
        },
        {
          id: 'sync-price',
          label: 'status-timeline.sync-price-label',
          status: '',
          checked: response.sync_price,
        },
      ]);
      yield put({ type: SET_CHANNELS_CONFIGURATION_STATUS, statuses });
    } catch (error) {
      yield put({ type: SET_CHANNELS_CONFIGURATION_STATUS, statuses: [] });
    } finally {
      yield put({ type: GETTING_CHANNELS_CONFIGURATION_STATUS, getting: false });
    }
  }
}

export function* getChannelSellerReference() {
  while (true) {
    const request = yield take(GET_CHANNEL_SELLER_REFERENCE);
    yield put({ type: GETTING_CHANNEL_SELLER_REFERENCE, getting: true });
    try {
      const reference = yield call(ChannelsSellerApi.getSellerReference, {
        channel: request.channel_id,
      });
      yield put({ type: SET_CHANNEL_SELLER_REFERENCE, reference });
    } catch (error) {
      yield put({ type: SET_CHANNEL_SELLER_REFERENCE, reference: { error: error.message } });
    } finally {
      yield put({ type: GETTING_CHANNEL_SELLER_REFERENCE, getting: false });
    }
  }
}

export function* getChannelsSellerReferences() {
  while (true) {
    const request = yield take(GET_CHANNELS_SELLER_REFERENCES);
    yield put({ type: GETTING_CHANNEL_SELLER_REFERENCE, getting: true });
    try {
      const channels = request.channels;

      if (!channels) {
        throwError(0, 'empty channels');
      }
      const calls = channels.map(channelId =>
        call(ChannelsSellerApi.getSellerReference, { channel: channelId })
      );
      const results = yield all(calls);
      const references = {};
      let i = 0;
      channels.forEach(channelId => {
        references[channelId] = results[i] ? results[i] : {};
        i++;
      });
      yield put({
        type: SET_CHANNEL_SELLER_REFERENCE,
        reference: references,
      });
    } catch (error) {
      yield put({ type: SET_CHANNEL_SELLER_REFERENCE, reference: error.message });
    } finally {
      yield put({ type: GETTING_CHANNEL_SELLER_REFERENCE, getting: false });
    }
  }
}

export function* updateChannelSellerReference() {
  while (true) {
    const request = yield take(POST_CHANNEL_SELLER_REFERENCE);
    yield put({ type: POSTING_CHANNEL_SELLER_REFERENCE, posting: true });
    try {
      if (!request.channel_id && request.reference) {
        // TODO I18N
        throwError(0, 'Soumission invalide');
      }

      yield call(ChannelsSellerApi.saveSellerReference, {
        channel: request.channel_id,
        reference: request.reference,
      });
      yield put({ type: SET_CHANNEL_SELLER_REFERENCE, reference: request.reference });
    } catch (error) {
      yield put({ type: SET_CHANNEL_SELLER_REFERENCE, reference: { error: error.message } });
    } finally {
      yield put({ type: POSTING_CHANNEL_SELLER_REFERENCE, posting: false });
    }
  }
}

export function* getChannelApiCredentials() {
  while (true) {
    const request = yield take(GET_CHANNEL_API_CONFIGURATION);
    yield put({ type: GETTING_CHANNEL_API_CONFIGURATION, getting: true });

    try {
      const api = yield call(ChannelsSellerApi.getApiCredentials, request.channel_id);
      yield put({ type: SET_CHANNEL_API_CONFIGURATION, api });
    } catch (error) {
      yield put({ type: SET_CHANNEL_API_CONFIGURATION, api: {} });
    } finally {
      yield put({ type: GETTING_CHANNEL_API_CONFIGURATION, getting: false });
    }
  }
}

export function* setChannelApiCredentials() {
  while (true) {
    const request = yield take(PUT_CHANNEL_API_CONFIGURATION);
    yield put({ type: PUTTING_CHANNEL_API_CONFIGURATION, putting: true });
    yield put({ type: CLEAR_ERROR_CHANNEL_API_CONFIGURATION });
    try {
      if (!request.channel && Object.keys(request.credentials).length) {
        // TODO I18N
        throwError(0, 'Soumission invalide');
      }

      yield call(ChannelsSellerApi.saveApiCredentials, {
        channel: request.channel,
        credentials: request.credentials,
      });
      yield put({ type: SET_CHANNEL_API_CONFIGURATION, api: { ...request.credentials } });
    } catch (error) {
      const channel = yield select(state => state.channel);
      channel.api.error = error.message;
      yield put({ type: SET_CHANNEL_API_CONFIGURATION, api: channel.api });
    } finally {
      yield put({ type: PUTTING_CHANNEL_API_CONFIGURATION, putting: false });
    }
  }
}

export function* getChannelProductInformation() {
  while (true) {
    const request = yield take(GET_CHANNEL_PRODUCT_INFORMATION);
    yield put({ type: GETTING_CHANNEL_PRODUCT_INFORMATION, gettingInfo: true });
    try {
      if (!request.channelReference) {
        throwError(0, 'Reference vide');
      }
      const info = yield call(
        ChannelsApi.getChannelProductInformation,
        request.channelReference,
        request.channelId
      );
      yield put({ type: SET_CHANNEL_PRODUCT_INFORMATION, info });
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_INFORMATION, info: {} });
      yield put({ type: SET_CHANNEL_PRODUCT_INFORMATION_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_INFORMATION, gettingInfo: false });
    }
  }
}

export function* getChannelsProductInformation() {
  while (true) {
    const request = yield take(GET_CHANNELS_PRODUCT_INFORMATION);
    yield put({ type: GETTING_CHANNEL_PRODUCT_INFORMATION, gettingInfo: true });
    try {
      const channelsReferences = request.channelsReferences;
      if (!channelsReferences) {
        throwError(0, 'Reference vide');
      }
      const calls = Object.keys(channelsReferences).map(channelId =>
        call(ChannelsApi.getChannelProductInformation, channelsReferences[channelId], channelId)
      );
      const results = yield all(calls);
      const info = {};
      let i = 0;
      Object.keys(channelsReferences).forEach(channelId => {
        info[channelId] = results[i] ? results[i] : {};
        i++;
      });
      yield put({
        type: SET_CHANNEL_PRODUCT_INFORMATION,
        info,
      });
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_INFORMATION, info: {} });
      yield put({ type: SET_CHANNEL_PRODUCT_INFORMATION_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_INFORMATION, gettingInfo: false });
    }
  }
}

export function* getChannelProductOffers() {
  while (true) {
    const request = yield take(GET_CHANNEL_PRODUCT_OFFERS);
    yield put({ type: GETTING_CHANNEL_PRODUCT_OFFERS, gettingOffers: true });
    try {
      if (!request.channelReference) {
        throwError(0, 'Reference vide');
      }
      const offers = yield call(
        ChannelsApi.getProductOffers,
        request.channelReference,
        request.channelId
      );
      yield put({ type: SET_CHANNEL_PRODUCT_OFFERS, offers });
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_OFFERS, offers: {} });
      yield put({ type: SET_CHANNEL_PRODUCT_OFFERS_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_OFFERS, gettingOffers: false });
    }
  }
}

export function* getChannelsProductOffers() {
  while (true) {
    const request = yield take(GET_CHANNELS_PRODUCT_OFFERS);
    yield put({ type: GETTING_CHANNEL_PRODUCT_OFFERS, gettingOffers: true });
    try {
      const channelsReferences = request.channelsReferences;
      if (!channelsReferences) {
        throwError(0, 'Reference vide');
      }
      const calls = Object.keys(channelsReferences).map(channelId =>
        call(ChannelsApi.getProductOffers, channelsReferences[channelId], channelId)
      );
      const results = yield all(calls);
      const offers = {};
      let i = 0;
      Object.keys(channelsReferences).forEach(channelId => {
        offers[channelId] = results[i] ? results[i] : {};
        i++;
      });
      yield put({
        type: SET_CHANNEL_PRODUCT_OFFERS,
        offers,
      });
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_OFFERS, offers: {} });
      yield put({ type: SET_CHANNEL_PRODUCT_OFFERS_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_OFFERS, gettingOffers: false });
    }
  }
}

export function* simulatePricing() {
  while (true) {
    const request = yield take(SIMULATE_PRICING);
    yield put({ type: SIMULATING_PRICING, simulatingPricing: true });
    try {
      const info = yield simulationProcess(request.productId, request.channelId, request.rules);
      yield put({ type: SET_SIMULATION_PRICING_INFO, info });
    } catch (error) {
      yield put({ type: SET_SIMULATION_PRICING_INFO, info: {} });
      yield put({ type: SET_SIMULATION_PRICING_ERROR, error });
    } finally {
      yield put({ type: SIMULATING_PRICING, simulatingPricing: false });
    }
  }
}

function* simulationProcess(productId, channelId, rules) {
  const calls = rules.map(behavior =>
    call(ChannelsApi.simulatePricing, productId, channelId, behavior)
  );
  const results = yield all(calls);
  const info = {};
  let i = 0;
  rules.forEach(behavior => {
    info[behavior] = results[i] ? results[i] : {};
    i++;
  });

  return info;
}

export function* simulateMultiplePricing() {
  while (true) {
    const request = yield take(SIMULATE_MULTIPLE_PRICING);
    yield put({ type: SIMULATING_PRICING, simulatingPricing: true });
    try {
      const info = {};
      //todo: see if we can parallelize all the calls
      for (let i = 0; i < request.channelIds.length; i++) {
        let channelId = request.channelIds[i];
        info[channelId] = yield simulationProcess(request.productId, channelId, request.rules);
      }
      yield put({ type: SET_SIMULATION_PRICING_INFO, info });
    } catch (error) {
      yield put({ type: SET_SIMULATION_PRICING_INFO, info: {} });
      yield put({ type: SET_SIMULATION_PRICING_ERROR, error });
    } finally {
      yield put({ type: SIMULATING_PRICING, simulatingPricing: false });
    }
  }
}

export function* getChannelProductBehavior() {
  while (true) {
    const request = yield take(GET_CHANNEL_PRODUCT_BEHAVIOR);
    yield put({ type: GETTING_CHANNEL_PRODUCT_BEHAVIOR, gettingBehavior: true });
    try {
      const behavior = yield call(
        ChannelsApi.getChannelProductBehavior,
        request.productId,
        request.channelId
      );
      if (Object.keys(behavior).length >= 0) {
        yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR, behavior });
      }
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR, behavior: 'undefined' });
      yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_BEHAVIOR, gettingBehavior: false });
    }
  }
}

export function* getChannelsProductBehavior() {
  while (true) {
    const request = yield take(GET_CHANNELS_PRODUCT_BEHAVIOR);
    yield put({ type: GETTING_CHANNEL_PRODUCT_BEHAVIOR, gettingBehavior: true });
    try {
      const productId = request.productId;
      const channelIds = request.channelIds;
      const calls = channelIds.map(channelId =>
        call(ChannelsApi.getChannelProductBehavior, productId, channelId)
      );
      const results = yield all(calls);
      const behaviors = {};
      let i = 0;
      channelIds.forEach(channelId => {
        behaviors[channelId] = results[i] ? results[i] : '';
        i++;
      });

      yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR, behavior: behaviors });
    } catch (error) {
      yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR, behavior: '' });
      yield put({ type: SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_PRODUCT_BEHAVIOR, gettingBehavior: false });
    }
  }
}

export function* getOffersHistory() {
  while (true) {
    const request = yield take(GET_OFFERS_HISTORY);
    yield put({ type: GETTING_OFFERS_HISTORY, gettingOffersHistory: true });
    try {
      const result = yield call(ChannelsApi.getOffersHistory, request.channel, request.reference);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_OFFERS_HISTORY, offersHistory: result });
      }
    } catch (error) {
      yield put({ type: SET_OFFERS_HISTORY, offersHistory: {} });
      yield put({ type: SET_OFFERS_HISTORY_ERROR, error });
    } finally {
      yield put({ type: GETTING_OFFERS_HISTORY, gettingOffersHistory: false });
    }
  }
}

export function* getPricingHistory() {
  while (true) {
    const request = yield take(GET_PRICING_HISTORY);
    yield put({ type: GETTING_PRICING_HISTORY, gettingPricingHistory: true });
    try {
      const result = yield call(ChannelsApi.getPricingHistory, request.channel, request.reference);
      if (Object.keys(result).length >= 0) {
        [
          'base_cost',
          'batch_id',
          'bbw_name',
          'best_offer',
          'brand',
          'buy_box_winner',
          'category',
          'channel',
          'client_id',
          'commission_amount',
          'count_competitors',
          'created_at',
          'current',
          'debug',
          'delay',
          'error',
          'has_changed',
          'id',
          'margin_amount',
          'margin_for_bbw',
          'matching_status',
          'name',
          'reference',
          'rules_result',
          'shipping_price',
          'stock',
          'supplier',
          'tax_amount',
        ].forEach(key => result.forEach(pricing => delete pricing[key]));

        result.forEach(pricing => (pricing.updated_at = apiDateToReadableDate(pricing.updated_at)));
        yield put({ type: SET_PRICING_HISTORY, pricingHistory: result });
      }
    } catch (error) {
      yield put({ type: SET_PRICING_HISTORY, pricingHistory: {} });
      yield put({ type: SET_PRICING_HISTORY_ERROR, error });
    } finally {
      yield put({ type: GETTING_PRICING_HISTORY, gettingPricingHistory: false });
    }
  }
}

export function* getOffersHistories() {
  while (true) {
    const request = yield take(GET_OFFERS_HISTORIES);
    yield put({ type: GETTING_OFFERS_HISTORY, gettingOffersHistory: true });
    try {
      const channelsReferences = request.channelsReferences;
      if (!channelsReferences) {
        throwError(0, 'Reference vide');
      }
      const calls = Object.keys(channelsReferences).map(channelId =>
        call(ChannelsApi.getOffersHistory, channelId, channelsReferences[channelId])
      );
      const results = yield all(calls);
      const offersHistory = {};
      let i = 0;
      Object.keys(channelsReferences).forEach(channelId => {
        offersHistory[channelId] = results[i] ? results[i] : {};
        i++;
      });
      yield put({
        type: SET_OFFERS_HISTORY,
        offersHistory,
      });
    } catch (error) {
      yield put({ type: SET_OFFERS_HISTORY, offersHistory: {} });
      yield put({ type: SET_OFFERS_HISTORY_ERROR, error });
    } finally {
      yield put({ type: GETTING_OFFERS_HISTORY, gettingOffersHistory: false });
    }
  }
}
