import { API_BASE_URL } from '../tools/constants';

import { privateCall } from './tools/privateCall';
import { Rule } from './model/rule.model';

const API_GET_RULES = API_BASE_URL + 'pricer/rule';
const API_GET_RULE_TYPES = API_BASE_URL + 'pricer/rule/create';
const API_GET_MISSING_REQUIRED_RULES = API_BASE_URL + 'pricer/configuration/check';

const StrategyApi = {
  async getRules() {
    const response = await privateCall(API_GET_RULES, 'GET');
    return Rule.categorize(response.body);
  },

  async getRuleTypes() {
    const response = await privateCall(API_GET_RULE_TYPES, 'GET');
    response.body.rules_fields = Rule.categorizeRuleTypes(response.body.rules_fields);
    return response.body;
  },

  async getMissingRequiredRules() {
    const response = await privateCall(API_GET_MISSING_REQUIRED_RULES, 'GET');
    return response.body;
  },

  async updateRule({ ...rule }) {
    rule.type = rule.type.toLowerCase();
    delete rule.translation;
    delete rule.last_indexation;
    delete rule.updated_at;
    delete rule.error;
    delete rule.has_error;
    const response = await privateCall(API_GET_RULES + '/' + rule.id, 'PUT', rule);
    return response.body;
  },

  async createRule({ ...rule }) {
    rule.type = rule.type.toLowerCase();
    delete rule.id;
    const response = await privateCall(API_GET_RULES, 'POST', rule);
    response.body.has_error = '0';
    if (response.body.enable === 0) {
      response.body.enable = 'off';
    } else if (response.body.enable === 1) {
      response.body.enable = 'on';
    }
    return response.body;
  },

  async deleteRule(ruleId) {
    const response = await privateCall(API_GET_RULES + '/' + ruleId, 'DELETE');
    return response.body;
  },
};

export default StrategyApi;
