import {
  ALL_CHANNELS_ERROR,
  CLEAR_ALL_CHANNELS_ERROR,
  GETTING_ALL_CHANNELS,
  SET_ALL_CHANNELS,
} from '../actions/allChannels';

let initialState = {
  list: [],
  error: '',
  sending: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_CHANNELS:
      return { ...state, list: action.channels };
    case GETTING_ALL_CHANNELS:
      return { ...state, sending: action.sending };
    case ALL_CHANNELS_ERROR:
      return { ...state, error: action.error };
    case CLEAR_ALL_CHANNELS_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
