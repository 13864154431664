import { API_BASE_URL, API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

const FULL_CATALOG = `${API_BASE_URL_V2}client-data/full-catalog`;
export const CLIENT_CMS_CREDENTIALS = `${API_BASE_URL_V2}client-data/connector/credentials`;
export const TRIGGER_CONNECTOR_CATALOG = `${API_BASE_URL_V2}client-data/connector/import-catalog`;
export const CATALOG_ATTRIBUTES = `${API_BASE_URL_V2}client-data/connector/import-catalog/configuration`;
export const FULL_CATALOG_STATISTIC = `${API_BASE_URL_V2}client-data/full-catalog/stats`;
export const CHECK_CATALOG_CONFIGURED = `${API_BASE_URL_V2}client-data/connector/status`;
export const GET_PRODUCT_PRICING = `${API_BASE_URL_V2}client-data/report/get-url?type=product_pricing`;
const FULL_CATALOG_FILTERS = `${API_BASE_URL_V2}client-data/full-catalog/filters`;
export const CHANNELS_PRICING_IMPORT = `${API_BASE_URL_V2}client-data/connector/export-pricing/configuration`;
export const CATALOG_MAPPING = `${API_BASE_URL_V2}client-data/mapping`;
export const PRODUCTS_LIMIT = `${API_BASE_URL}client-data/subscription/product-limit`;
export const PRODUCTS_COUNT = `${API_BASE_URL_V2}client-data/full-catalog/filters/count`;

const CatalogConfigApi = {
  async getFullCatalogStatistic() {
    return await privateCall(FULL_CATALOG_STATISTIC, 'GET');
  },

  async checkCatalogConfigured() {
    return await privateCall(CHECK_CATALOG_CONFIGURED, 'GET');
  },

  async getProductsPricing() {
    return await privateCall(GET_PRODUCT_PRICING, 'GET');
  },

  async getCatalogCredentials() {
    return await privateCall(CLIENT_CMS_CREDENTIALS, 'GET');
  },

  async sendCmsCredentials({ options, host, connector }) {
    let fd = new FormData();
    fd.append('host', host);
    fd.append('connector', connector);

    const filteredOptions = Object.keys(options).filter(it => options[it] !== '');

    if (filteredOptions.length) {
      filteredOptions.forEach(prop => {
        fd.append(`options[${prop}]`, options[prop]);
      });
    } else {
      fd.append('options[]', '');
    }

    return await privateCall(CLIENT_CMS_CREDENTIALS, 'POST', fd);
  },
  async uploadCmsCsv(data) {
    let fd = new FormData();
    fd.append('file', data.file);
    return await privateCall(FULL_CATALOG, 'POST', fd);
  },
  async triggerConnector() {
    return await privateCall(TRIGGER_CONNECTOR_CATALOG, 'GET');
  },

  async getFullCatalogFilters() {
    return await privateCall(FULL_CATALOG_FILTERS, 'GET');
  },
  async saveFullCatalogFilters(data) {
    const filteredData = Object.keys(data).filter(it => data[it] !== '');
    let params;
    if (filteredData.length) {
      params = new FormData();
      filteredData.forEach(prop => {
        const value = data[prop];
        if (value === true || value === false) {
          params.append(`filters[${prop}]`, +value);
        } else {
          params.append(`filters[${prop}]`, value);
        }
      });
    } else {
      params = { filters: [] };
    }
    return await privateCall(FULL_CATALOG_FILTERS, 'POST', params);
  },

  async getChannelsPricingImport() {
    return await privateCall(CHANNELS_PRICING_IMPORT, 'GET');
  },
  async saveChannelsPricingImport(imports) {
    let fd = new FormData();
    Object.keys(imports).forEach(channel_uid => {
      Object.keys(imports[channel_uid]).forEach(field => {
        fd.append(`export_pricing[${channel_uid}][${field}]`, imports[channel_uid][field]);
      });
    });
    return await privateCall(CHANNELS_PRICING_IMPORT, 'POST', fd);
  },

  async getCatalogMapping(params = {}) {
    if (params && params.connector) {
      return await privateCall(CATALOG_MAPPING, 'GET', params);
    }

    return await privateCall(CATALOG_MAPPING, 'GET');
  },
  async saveCatalogMapping(mapping) {
    let fd = new FormData();
    Object.keys(mapping).forEach(it => {
      fd.append(`mapping[${it}]`, mapping[it]);
    });
    return await privateCall(CATALOG_MAPPING, 'POST', fd);
  },

  async getProductsLimit() {
    const response = await privateCall(PRODUCTS_LIMIT, 'GET');
    return response.body;
  },

  async getProductsCount(filters) {
    let response;
    if (filters !== null) {
      if (!Object.keys(filters).length) {
        //hack that forces "filters" to appear in the parameters
        //preventing the call from taking the pre-saved filters into consideration
        filters[''] = '';
      }
      response = await privateCall(PRODUCTS_COUNT, 'GET', { filters });
    } else {
      response = await privateCall(PRODUCTS_COUNT, 'GET');
    }

    return response.count;
  },

  async getCatalogAttributes() {
    return await privateCall(CATALOG_ATTRIBUTES, 'GET');
  },

  async saveCatalogAttributes(attributes) {
    let fd = new FormData();
    Object.keys(attributes).forEach(field => {
      Object.keys(attributes[field]).forEach(attribute => {
        fd.append(`import_catalog[${field}][${attribute}]`, attributes[field][attribute]);
      });
    });
    return await privateCall(CATALOG_ATTRIBUTES, 'POST', fd);
  },
};

export default CatalogConfigApi;
