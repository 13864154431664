export const CHANGE_FORM = 'CHANGE_FORM';
export const SET_AUTH = 'SET_AUTH';
export const AUTHENTICATING = 'AUTHENTICATING';
export const RESETTING_REQUEST = 'RESETTING_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const SEND_PASSWORD_RESET_REQUEST = 'SEND_PASSWORD_RESET_REQUEST';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

/**
 * Sets the form state
 * @param  {object} newFormState          The new state of the form
 * @param  {string} newFormState.email The new text of the email input field of the form
 * @param  {string} newFormState.password The new text of the password input field of the form
 */
export const changeForm = newFormState => {
  return { type: CHANGE_FORM, newFormState };
};

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export const setAuthState = newAuthState => {
  return { type: SET_AUTH, newAuthState };
};

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export const sendingRequest = sending => {
  return { type: AUTHENTICATING, sending };
};

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.email    The email of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export const loginRequest = data => {
  return { type: LOGIN_REQUEST, data };
};

/**
 * Tells the app we want to log out a user
 */
export const logout = () => {
  return { type: LOGOUT };
};

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.email    The email of the user to register
 * @param  {string} data.password The password of the user to register
 */
export const registerRequest = data => {
  return { type: REGISTER_REQUEST, data };
};

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export const requestError = error => {
  return { type: AUTH_ERROR, error };
};

/**
 * @param  {object} data          The data we're sending for resetting pwd
 * @param  {string} data.email    The email of the user used to register
 */
export const passswordResetRequest = ({ email }) => {
  return { type: PASSWORD_RESET_REQUEST, email };
};

/**
 * @param  {object} password          New password to change
 * @param  {object} token            Reset token to link the password to the correct account
 */
export const sendPassswordResetRequest = ({ password, token }) => {
  return { type: SEND_PASSWORD_RESET_REQUEST, password, token };
};

/**
 * Sets the `error` state as empty
 */
export const clearAuthError = () => {
  return { type: CLEAR_AUTH_ERROR };
};
