import { createSelector } from 'reselect';

import { getTS } from '../tools/date';
import {
  GETTING_DASHBOARD_POSITION_BY_DATE,
  SET_DASHBOARD_POSITION_BY_DATE,
  SET_DASHBOARD_POSITION_BY_DATE_ERROR,
  GETTING_DASHBOARD_MARGIN_INFO,
  SET_DASHBOARD_MARGIN_INFO,
  SET_DASHBOARD_MARGIN_INFO_ERROR,
  GETTING_DASHBOARD_STATUS_BY_DATE,
  SET_DASHBOARD_STATUS_BY_DATE,
  SET_DASHBOARD_STATUS_BY_DATE_ERROR,
  GETTING_DASHBOARD_TARGET_POSITION_BY_DATE,
  SET_DASHBOARD_TARGET_POSITION_BY_DATE,
  SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR,
} from '../actions/dashboardStats';

let initialState = {
  margin: {},
  gettingMargin: false,
  marginError: '',

  positionByDate: [],
  gettingPositionByDate: false,
  positionByDateError: '',

  targetPositionByDate: [],
  gettingTargetPositionByDate: false,
  targetPositionByDateError: '',

  statusByDate: [],
  gettingStatusByDate: false,
  statusByDateError: '',
};

export function dashboardStats(state = initialState, action) {
  switch (action.type) {
    case GETTING_DASHBOARD_POSITION_BY_DATE:
      return { ...state, gettingPositionByDate: action.gettingPositionByDate };
    case SET_DASHBOARD_POSITION_BY_DATE:
      return { ...state, positionByDate: action.positionByDate };
    case SET_DASHBOARD_POSITION_BY_DATE_ERROR:
      return { ...state, positionByDateError: action.error };
    case GETTING_DASHBOARD_TARGET_POSITION_BY_DATE:
      return { ...state, gettingTargetPositionByDate: action.gettingTargetPositionByDate };
    case SET_DASHBOARD_TARGET_POSITION_BY_DATE:
      return { ...state, targetPositionByDate: action.targetPositionByDate };
    case SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR:
      return { ...state, targetPositionByDateError: action.error };
    case GETTING_DASHBOARD_STATUS_BY_DATE:
      return { ...state, gettingStatusByDate: action.gettingStatusByDate };
    case SET_DASHBOARD_STATUS_BY_DATE:
      return { ...state, statusByDate: action.statusByDate };
    case SET_DASHBOARD_STATUS_BY_DATE_ERROR:
      return { ...state, statusByDateError: action.error };
    case GETTING_DASHBOARD_MARGIN_INFO:
      return { ...state, gettingMargin: action.gettingMargin };
    case SET_DASHBOARD_MARGIN_INFO:
      return { ...state, margin: action.margin };
    case SET_DASHBOARD_MARGIN_INFO_ERROR:
      return { ...state, marginError: action.error };
    default:
      return state;
  }
}

export const formatStatus = createSelector(
  ({ dashboardStats }) => {
    return dashboardStats.statusByDate;
  },
  (statuses = []) => {
    return {
      labels: {
        y1: 'buy_box_winner',
        y2: 'challenger',
        y3: 'out_of_competition',
        y4: 'error',
        y5: 'not_associated',
      },
      data: statuses.map(
        ({
          date,
          buy_box_winner,
          challenger,
          out_of_competition,
          no_offers,
          error,
          disable,
          not_associated,
        }) => ({
          x: getTS(date),
          y1: +(buy_box_winner + no_offers),
          y2: +challenger,
          y3: +out_of_competition,
          y4: +error,
          y5: +not_associated,
        })
      ),
    };
  }
);
