import {
  CLEAR_ERROR_CHANNEL_API_CONFIGURATION,
  GETTING_CHANNEL_CONFIGURATION_STATUS,
  GETTING_CHANNEL_SELLER_REFERENCE,
  POSTING_CHANNEL_SELLER_REFERENCE,
  PUTTING_CHANNEL_API_CONFIGURATION,
  SET_CHANNEL,
  SET_CHANNEL_API_CONFIGURATION,
  SET_CHANNEL_CONFIGURATION_STATUS,
  SET_CHANNEL_SELLER_REFERENCE,
} from '../actions/channel';

let initialState = {
  progress: [],
  api: {},
  reference: '',
  gettingProgress: false,
  postingSellerReference: false,
  puttingApiCredentials: false,
};

export function channel(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL:
      return { ...state, ...(action.channel || {}), progress: state.progress, api: state.api };
    case GETTING_CHANNEL_CONFIGURATION_STATUS:
      return { ...state, gettingProgress: action.gettingProgress };
    case SET_CHANNEL_CONFIGURATION_STATUS:
      return { ...state, progress: action.progress };

    case GETTING_CHANNEL_SELLER_REFERENCE:
      return { ...state, gettingSellerReference: action.getting };
    case POSTING_CHANNEL_SELLER_REFERENCE:
      return { ...state, postingSellerReference: action.posting };

    case PUTTING_CHANNEL_API_CONFIGURATION:
      return { ...state, api: { ...state.api }, puttingApiCredentials: action.putting };

    case CLEAR_ERROR_CHANNEL_API_CONFIGURATION:
      return { ...state, api: { ...state.api, error: '' } };

    case SET_CHANNEL_SELLER_REFERENCE:
      return { ...state, reference: action.reference };

    case SET_CHANNEL_API_CONFIGURATION:
      return { ...state, api: action.api };

    default:
      return state;
  }
}
