import { call, put, take } from 'redux-saga/effects';

import {
  DELETE_RULE,
  DELETING_RULE,
  GET_RULE_TYPES,
  GET_RULES,
  GETTING_RULE_TYPES,
  GETTING_RULES,
  SET_DELETE_RULE_ERROR,
  SET_RULE_TYPES,
  SET_RULE_TYPES_ERROR,
  SET_RULES,
  SET_RULES_ERROR,
  SET_UPDATE_RULE_ERROR,
  UPDATE_RULE,
  UPDATING_RULE,
  CREATE_RULE,
  CREATING_RULE,
  SET_CREATE_RULE_ERROR,
  ADD_RULE,
  GET_MISSING_REQUIRED_RULES,
  GETTING_MISSING_REQUIRED_RULES,
  SET_MISSING_REQUIRED_RULES_ERROR,
  SET_MISSING_REQUIRED_RULES,
} from '../actions/strategy';
import StrategyApi from '../api/strategy';

export function* getRules() {
  while (true) {
    yield take(GET_RULES);
    yield put({ type: GETTING_RULES, gettingRules: true });
    yield put({ type: SET_RULES_ERROR, rulesError: '' });
    try {
      const result = yield call(StrategyApi.getRules);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_RULES, rules: result });
      }
    } catch (error) {
      yield put({ type: SET_RULES, rules: {} });
      yield put({ type: SET_RULES_ERROR, rulesError: error });
    } finally {
      yield put({ type: GETTING_RULES, gettingRules: false });
    }
  }
}

export function* getRuleTypes() {
  while (true) {
    yield take(GET_RULE_TYPES);
    yield put({ type: GETTING_RULE_TYPES, gettingRuleTypes: true });
    yield put({ type: SET_RULE_TYPES_ERROR, ruleTypesError: '' });
    try {
      const result = yield call(StrategyApi.getRuleTypes);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_RULE_TYPES, ruleTypes: result });
      }
    } catch (error) {
      yield put({ type: SET_RULE_TYPES, ruleTypes: {} });
      yield put({ type: SET_RULE_TYPES_ERROR, ruleTypesError: error });
    } finally {
      yield put({ type: GETTING_RULE_TYPES, gettingRuleTypes: false });
    }
  }
}

export function* getMissingRequiredRules() {
  while (true) {
    yield take(GET_MISSING_REQUIRED_RULES);
    yield put({ type: GETTING_MISSING_REQUIRED_RULES, gettingMissingRules: true });
    yield put({ type: SET_MISSING_REQUIRED_RULES_ERROR, error: '' });
    try {
      const result = yield call(StrategyApi.getMissingRequiredRules);
      yield put({ type: SET_MISSING_REQUIRED_RULES, missingRules: result });
    } catch (error) {
      yield put({ type: SET_MISSING_REQUIRED_RULES, missingRules: {} });
      yield put({ type: SET_MISSING_REQUIRED_RULES_ERROR, error });
    } finally {
      yield put({ type: GETTING_MISSING_REQUIRED_RULES, gettingMissingRules: false });
    }
  }
}

export function* updateRule() {
  while (true) {
    const request = yield take(UPDATE_RULE);
    yield put({ type: UPDATING_RULE, updatingRule: true });
    yield put({ type: SET_UPDATE_RULE_ERROR, updateRuleError: '' });
    try {
      yield call(StrategyApi.updateRule, request.rule);
    } catch (error) {
      yield put({ type: SET_UPDATE_RULE_ERROR, updateRuleError: error });
    } finally {
      yield put({ type: UPDATING_RULE, updatingRule: false });
    }
  }
}

export function* createRule() {
  while (true) {
    const request = yield take(CREATE_RULE);
    yield put({ type: CREATING_RULE, creatingRule: true });
    yield put({ type: SET_CREATE_RULE_ERROR, createRuleError: '' });
    try {
      const result = yield call(StrategyApi.createRule, request.rule);
      if (result.id) {
        yield put({ type: ADD_RULE, rule: result });
      }
    } catch (error) {
      yield put({ type: SET_CREATE_RULE_ERROR, createRuleError: error });
    } finally {
      yield put({ type: CREATING_RULE, creatingRule: false });
    }
  }
}

export function* deleteRule() {
  while (true) {
    const request = yield take(DELETE_RULE);
    yield put({ type: DELETING_RULE, deletingRule: true });
    yield put({ type: SET_DELETE_RULE_ERROR, deleteRuleError: '' });
    try {
      yield call(StrategyApi.deleteRule, request.rule);
      yield put({ type: GET_RULES }); //todo: figure a way to avoid refreshing everything
    } catch (error) {
      yield put({ type: SET_DELETE_RULE_ERROR, deleteRuleError: error });
    } finally {
      yield put({ type: DELETING_RULE, deletingRule: false });
    }
  }
}
