export const COLLAPSE_LEFT = 'COLLAPSE_LEFT';
export const COLLAPSE_RIGHT = 'COLLAPSE_RIGHT';
export const SET_RIGHT_CONTENT = 'SET_RIGHT_CONTENT';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const HELP = 'HELP';
export const OPEN_HELP = 'OPEN_HELP';

export const collapseLeft = collapse => {
  return { type: COLLAPSE_LEFT, collapse };
};

export const collapseRight = collapse => {
  return { type: COLLAPSE_RIGHT, collapse };
};

export const setRightContent = content => {
  return { type: SET_RIGHT_CONTENT, content };
};

export const openHelp = ({ searchWords, location }) => {
  return { type: OPEN_HELP, params: { searchWords, location } };
};
