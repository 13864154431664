import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const FEED_MANAGER = `${API_BASE_URL_V2}client-data/feed-manager/`;

const FeedManagerApi = {
  async getFeedOptions() {
    return await privateCall(FEED_MANAGER + 'options', 'GET');
  },

  async getFeed() {
    const feedList = await privateCall(FEED_MANAGER + 'feed', 'GET');
    let feed = {};
    if (feedList) {
      const uids = Object.keys(feedList);
      if (uids.length) {
        feed = feedList[uids[0]];
        feed.uid = uids[0];
      }
    }
    return feed;
  },

  async updateFeedManager({ sourceUrl, engine, manager, uid = null }) {
    let fd = new FormData();
    fd.append('sourceUrl', sourceUrl);
    fd.append('engine', engine);
    fd.append('manager', manager);

    if (uid) {
      fd.append('uid', uid);
    }

    return await privateCall(FEED_MANAGER + 'feed', 'POST', fd);
  },
};

export default FeedManagerApi;
