export const GET_RULES = 'GET_RULES';
export const GETTING_RULES = 'GETTING_RULES';
export const GET_MISSING_REQUIRED_RULES = 'GET_MISSING_REQUIRED_RULES';
export const GETTING_MISSING_REQUIRED_RULES = 'GETTING_MISSING_REQUIRED_RULES';
export const GET_RULE_TYPES = 'GET_RULE_TYPES';
export const GETTING_RULE_TYPES = 'GETTING_RULE_TYPES';
export const SET_RULES = 'SET_RULES';
export const SET_MISSING_REQUIRED_RULES = 'SET_MISSING_REQUIRED_RULES';
export const ADD_RULE = 'ADD_RULE';
export const SET_RULES_ERROR = 'SET_RULES_ERROR';
export const SET_MISSING_REQUIRED_RULES_ERROR = 'SET_MISSING_REQUIRED_RULES_ERROR';
export const SET_RULE_TYPES = 'SET_RULE_TYPES';
export const SET_RULE_TYPES_ERROR = 'SET_RULE_TYPES_ERROR';
export const UPDATE_RULE = 'UPDATE_RULE';
export const UPDATING_RULE = 'UPDATING_RULE';
export const SET_UPDATE_RULE_ERROR = 'SET_UPDATE_RULE_ERROR';
export const CREATE_RULE = 'CREATE_RULE';
export const CREATING_RULE = 'CREATING_RULE';
export const SET_CREATE_RULE_ERROR = 'SET_CREATE_RULE_ERROR';
export const DELETE_RULE = 'DELETE_RULE';
export const DELETING_RULE = 'DELETING_RULE';
export const SET_DELETE_RULE_ERROR = 'SET_DELETE_RULE_ERROR';

export const getRules = () => {
  return { type: GET_RULES };
};

export const getRuleTypes = () => {
  return { type: GET_RULE_TYPES };
};

export const getMissingRequiredRules = () => {
  return { type: GET_MISSING_REQUIRED_RULES };
};

export const updateRule = rule => {
  return { type: UPDATE_RULE, rule };
};

export const createRule = rule => {
  return { type: CREATE_RULE, rule };
};

export const deleteRule = rule => {
  return { type: DELETE_RULE, rule };
};
