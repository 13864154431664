import { call, put, take } from 'redux-saga/effects';

import {
  GET_FEED,
  GET_FEED_OPTIONS,
  GETTING_FEED,
  GETTING_FEED_OPTIONS,
  SET_FEED,
  SET_FEED_ERROR,
  SET_FEED_OPTIONS,
  SET_FEED_OPTIONS_ERROR,
  UPDATE_FEED,
  UPDATE_FEED_ERROR,
  UPDATING_FEED,
} from '../actions/feedManager';
import FeedManagerApi from '../api/feedManager.api';

export function* getFeedOptions() {
  while (true) {
    yield take(GET_FEED_OPTIONS);
    yield put({ type: GETTING_FEED_OPTIONS, getting: true });

    try {
      const feedOptions = yield call(FeedManagerApi.getFeedOptions);
      if (feedOptions) {
        yield put({ type: SET_FEED_OPTIONS, feedOptions });
      }
    } catch (error) {
      yield put({ type: SET_FEED_OPTIONS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_FEED_OPTIONS, getting: false });
    }
  }
}

export function* getFeed() {
  while (true) {
    yield take(GET_FEED);
    yield put({ type: GETTING_FEED, getting: true });

    try {
      const feed = yield call(FeedManagerApi.getFeed);
      if (feed) {
        yield put({ type: SET_FEED, feed });
      }
    } catch (error) {
      yield put({ type: SET_FEED_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_FEED, getting: false });
    }
  }
}

export function* updateFeed() {
  while (true) {
    const { sourceUrl, manager, engine, uid = null } = yield take(UPDATE_FEED);
    yield put({ type: UPDATING_FEED, getting: true });

    try {
      const feed = yield call(FeedManagerApi.updateFeedManager, {
        sourceUrl,
        engine,
        manager,
        uid,
      });
      if (feed) {
        yield put({ type: SET_FEED, feed });
      }
    } catch (error) {
      yield put({ type: UPDATE_FEED_ERROR, error: error.message });
    } finally {
      yield put({ type: UPDATING_FEED, getting: false });
    }
  }
}
