import {
  getExpirationTimestamp,
  getToken,
  setRefreshToken,
  setToken,
  clearTokens,
} from '../tools/storage';
import { API_BASE_URL } from '../tools/constants';

import { throwError } from './index';

export const API_LOGIN_URL = API_BASE_URL + 'user/credentials';
export const API_AUTH_URL = API_BASE_URL + 'oauth/authentication';
const API_ASK_RESET_URL = API_BASE_URL + 'user/send-password-reset';
const API_RESET_URL = API_BASE_URL + 'user/reset-password';

const auth = {
  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} email The email of the user
   * @param  {string} password The password of the user
   */
  login(email, password) {
    if (auth.loggedIn()) {
      return Promise.resolve(true);
    }

    let data = new FormData();
    data.append('email', email);
    data.append('password', password);

    // perform login request
    return fetch(API_LOGIN_URL, {
      method: 'POST',
      body: data,
    })
      .then(response => {
        if (response.status !== 200) {
          throwError(response.status);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        setRefreshToken(response.credentials);
        return auth.authentication(response.credentials);
      });
  },

  authentication(token) {
    let data = new FormData();
    data.append('grant_type', 'client_credentials');

    return fetch(API_AUTH_URL, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${token}`,
      },
      body: data,
    })
      .then(response => response.json())
      .then(response => {
        const timestamp = new Date().getTime();
        const body = response.body;
        if (body.expires_in && body.access_token) {
          const duration = body.expires_in * 1000;
          const accessToken = body.access_token;
          setToken(accessToken, timestamp + duration);
        } else {
          clearTokens();
        }
        return Promise.resolve(true);
      });
  },

  /**
   * Logs the current user out
   */
  logout() {
    clearTokens();
  },
  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    return !!getToken();
  },

  isTokenExpired() {
    return new Date().getTime() > getExpirationTimestamp();
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} email The email of the user
   * @param  {string} password The password of the user
   */
  register(email, password) {
    // perform register request
    return (
      fetch('/register', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
        // Log user in after registering
        .then(() => auth.login(email, password))
    );
  },

  askResetPassword(email) {
    if (auth.loggedIn()) {
      throwError(0, 'Impossible de demander une résiliation de mot de passe');
    }

    let data = new FormData();
    data.append('email', email);
    return fetch(API_ASK_RESET_URL, { method: 'POST', body: data })
      .then(response => {
        if (response.status !== 200) {
          throwError(response.status);
        }
        return response;
      })
      .then(response => response.json());
  },

  resetPassword({ password, token }) {
    let data = new FormData();
    data.append('password', password);
    data.append('token', token);
    return fetch(API_RESET_URL, { method: 'POST', body: data })
      .then(response => {
        if (response.status !== 200) {
          throwError(response.status);
        }
        return response;
      })
      .then(response => response.json());
  },
};

export default auth;
