export const GET_DASHBOARD_MARGIN_INFO = 'GET_DASHBOARD_MARGIN_INFO';
export const GETTING_DASHBOARD_MARGIN_INFO = 'GETTING_DASHBOARD_MARGIN_INFO';
export const SET_DASHBOARD_MARGIN_INFO = 'SET_DASHBOARD_MARGIN_INFO';
export const SET_DASHBOARD_MARGIN_INFO_ERROR = 'SET_DASHBOARD_MARGIN_INFO_ERROR';

export const GET_DASHBOARD_POSITION_BY_DATE = 'GET_DASHBOARD_POSITION_BY_DATE';
export const GETTING_DASHBOARD_POSITION_BY_DATE = 'GETTING_DASHBOARD_POSITION_BY_DATE';
export const SET_DASHBOARD_POSITION_BY_DATE = 'SET_DASHBOARD_POSITION_BY_DATE';
export const SET_DASHBOARD_POSITION_BY_DATE_ERROR = 'SET_DASHBOARD_POSITION_BY_DATE_ERROR';

export const GET_DASHBOARD_TARGET_POSITION_BY_DATE = 'GET_DASHBOARD_TARGET_POSITION_BY_DATE';
export const GETTING_DASHBOARD_TARGET_POSITION_BY_DATE =
  'GETTING_DASHBOARD_TARGET_POSITION_BY_DATE';
export const SET_DASHBOARD_TARGET_POSITION_BY_DATE = 'SET_DASHBOARD_TARGET_POSITION_BY_DATE';
export const SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR =
  'SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR';

export const GET_DASHBOARD_STATUS_BY_DATE = 'GET_DASHBOARD_STATUS_BY_DATE';
export const GETTING_DASHBOARD_STATUS_BY_DATE = 'GETTING_DASHBOARD_STATUS_BY_DATE';
export const SET_DASHBOARD_STATUS_BY_DATE = 'SET_DASHBOARD_STATUS_BY_DATE';
export const SET_DASHBOARD_STATUS_BY_DATE_ERROR = 'SET_DASHBOARD_STATUS_BY_DATE_ERROR';

export const getDashboardMarginInfo = () => {
  return { type: GET_DASHBOARD_MARGIN_INFO };
};
export const getDashboardPositionByDate = () => {
  return { type: GET_DASHBOARD_POSITION_BY_DATE };
};
export const getDashboardTargetPositionByDate = () => {
  return { type: GET_DASHBOARD_TARGET_POSITION_BY_DATE };
};
export const getDashboardStatusByDate = () => {
  return { type: GET_DASHBOARD_STATUS_BY_DATE };
};
