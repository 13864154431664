import { call, put, take } from 'redux-saga/effects';

import ChannelsApi from '../api/channels';
import { GET_CHANNELS, SET_CHANNELS, CHANNELS_ERROR, GETTING_CHANNELS } from '../actions/channels';
import {
  ALL_CHANNELS_ERROR,
  GET_ALL_CHANNELS,
  GETTING_ALL_CHANNELS,
  SET_ALL_CHANNELS,
} from '../actions/allChannels';

export function* getChannels() {
  while (true) {
    yield take(GET_CHANNELS);

    yield put({ type: GETTING_CHANNELS, sending: true });
    try {
      let channels = yield call(ChannelsApi.getChannels);
      if (channels.length >= 0) {
        yield put({ type: SET_CHANNELS, channels });
      }
    } catch (error) {
      yield put({ type: SET_CHANNELS, channels: [] });
      yield put({ type: CHANNELS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_CHANNELS, sending: false });
    }
  }
}

export function* getAllChannels() {
  while (true) {
    yield take(GET_ALL_CHANNELS);

    yield put({ type: GETTING_ALL_CHANNELS, sending: true });
    try {
      let channels = yield call(ChannelsApi.getAllChannels);
      if (channels.length >= 0) {
        yield put({ type: SET_ALL_CHANNELS, channels });
      }
    } catch (error) {
      yield put({ type: SET_ALL_CHANNELS, channels: [] });
      yield put({ type: ALL_CHANNELS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_ALL_CHANNELS, sending: false });
    }
  }
}
