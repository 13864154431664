import { all, fork } from 'redux-saga/effects';

import {
  loginFlow,
  logoutFlow,
  registerFlow,
  askResetPasswordFlow,
  resetPasswordFlow,
} from './auth';
import { getInfoFlow } from './user';
import { getCatalogProductsFlow } from './catalog';
import { getChannels, getAllChannels } from './channels';
import {
  getChannelApiCredentials,
  getChannelConfigurationStatus,
  getChannelProductInformation,
  getChannelsConfigurationStatus,
  getChannelSellerReference,
  getChannelsProductInformation,
  getChannelsSellerReferences,
  getOffersHistories,
  getOffersHistory,
  getChannelProductOffers,
  setChannelApiCredentials,
  simulatePricing,
  updateChannelSellerReference,
  getChannelsProductOffers,
  simulateMultiplePricing,
  getPricingHistory,
  getChannelProductBehavior,
  getChannelsProductBehavior,
} from './channel';
import {
  checkCatalogConfiguredFlow,
  getCatalogStatisticsFlow,
  getCatalogConfigurationFlow,
  saveCatalogCredentialsFlow,
  getCatalogFiltersFlow,
  saveCatalogFiltersFlow,
  saveCatalogCsvCredentialsFlow,
  getProductPricingFlow,
  getCatalogFeedFlow,
  importCatalog,
  getProductsLimit,
  getProductsCount,
  getCatalogAttributes,
  saveCatalogAttributes,
} from './catalogConfigure';
import { getChannelsPricingImports, saveChannelsPricingImports } from './channelsPricingImport';
import { getCatalogMapping, postCatalogMapping } from './catalogMapping';
import { sendChannelSubscriptionMail } from './channelsSubscription';
import { setProductsBehaviorFlow } from './behavior';
import {
  getChannelMatchingStatus,
  getCompetitorsStats,
  getMarginInfo,
  getPositionByDate,
  getStatusByDate,
  getTargetPositionByDate,
} from './channelStats';
import {
  getDashboardPositionByDate,
  getDashboardMarginInfo,
  getDashboardStatusByDate,
  getDashboardTargetPositionByDate,
} from './dashboardStats';
import {
  createRule,
  deleteRule,
  getMissingRequiredRules,
  getRules,
  getRuleTypes,
  updateRule,
} from './strategy';
import { deleteCarrier, getCarriers, getCarrier, updateCarrier } from './carriers';
import { deleteCacheValue, getCacheValue, updateCacheValue } from './cache';
import { getFeed, getFeedOptions, updateFeed } from './feedManager';
import { getRepricingProgress } from './repricing';
import { getCatalogHistory } from './catalogHistory';
import { getReports } from './reports';
import { saveMiraklOAuthCodeFlow } from './mirakl';

export default function* rootSaga() {
  yield all([
    fork(loginFlow),
    fork(logoutFlow),
    fork(registerFlow),
    fork(askResetPasswordFlow),
    fork(resetPasswordFlow),
    fork(getInfoFlow),
    fork(getCatalogProductsFlow),
    fork(getCatalogStatisticsFlow),
    fork(getProductsLimit),
    fork(getProductsCount),
    fork(getCatalogAttributes),
    fork(saveCatalogAttributes),
    fork(getCatalogConfigurationFlow),
    fork(getProductPricingFlow),
    fork(checkCatalogConfiguredFlow),
    fork(getCatalogFiltersFlow),
    fork(saveCatalogFiltersFlow),
    fork(saveCatalogCredentialsFlow),
    fork(saveCatalogCsvCredentialsFlow),
    fork(getCatalogFeedFlow),
    fork(importCatalog),
    fork(getChannelsPricingImports),
    fork(saveChannelsPricingImports),
    fork(getCatalogMapping),
    fork(postCatalogMapping),
    fork(getChannels),
    fork(getAllChannels),
    fork(sendChannelSubscriptionMail),
    fork(getChannelConfigurationStatus),
    fork(getChannelSellerReference),
    fork(getChannelsSellerReferences),
    fork(updateChannelSellerReference),
    fork(getChannelApiCredentials),
    fork(setChannelApiCredentials),
    fork(getChannelsConfigurationStatus),
    fork(getChannelProductBehavior),
    fork(getChannelsProductBehavior),
    fork(getChannelProductInformation),
    fork(getChannelsProductInformation),
    fork(getChannelProductOffers),
    fork(getChannelsProductOffers),
    fork(simulatePricing),
    fork(simulateMultiplePricing),
    fork(getChannelMatchingStatus),
    fork(getMarginInfo),
    fork(getCompetitorsStats),
    fork(getPositionByDate),
    fork(getTargetPositionByDate),
    fork(getStatusByDate),
    fork(getOffersHistory),
    fork(getPricingHistory),
    fork(getOffersHistories),
    fork(setProductsBehaviorFlow),
    fork(getRules),
    fork(getRuleTypes),
    fork(getCarriers),
    fork(getCarrier),
    fork(updateCarrier),
    fork(deleteCarrier),
    fork(getMissingRequiredRules),
    fork(updateRule),
    fork(createRule),
    fork(deleteRule),
    fork(getDashboardMarginInfo),
    fork(getDashboardPositionByDate),
    fork(getDashboardTargetPositionByDate),
    fork(getDashboardStatusByDate),
    fork(getCacheValue),
    fork(updateCacheValue),
    fork(deleteCacheValue),
    fork(getFeedOptions),
    fork(getFeed),
    fork(updateFeed),
    fork(getReports),
    fork(getRepricingProgress),
    fork(getCatalogHistory),
    fork(saveMiraklOAuthCodeFlow),
  ]);
}
