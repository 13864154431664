import {
  SET_CHANNEL_PRODUCT_INFORMATION_ERROR,
  SET_CHANNEL_PRODUCT_INFORMATION,
  GETTING_CHANNEL_PRODUCT_INFORMATION,
  SET_SIMULATION_PRICING_INFO,
  SIMULATING_PRICING,
  SET_SIMULATION_PRICING_ERROR,
  SET_CHANNEL_PRODUCT_OFFERS,
  GETTING_CHANNEL_PRODUCT_OFFERS,
  SET_CHANNEL_PRODUCT_OFFERS_ERROR,
  GETTING_OFFERS_HISTORY,
  SET_OFFERS_HISTORY,
  SET_OFFERS_HISTORY_ERROR,
  SET_PRICING_HISTORY,
  GETTING_PRICING_HISTORY,
  SET_PRICING_HISTORY_ERROR,
  SET_CHANNEL_PRODUCT_BEHAVIOR,
  GETTING_CHANNEL_PRODUCT_BEHAVIOR,
  SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR,
} from '../actions/channel';

let initialState = {
  info: {},
  gettingInfo: false,
  infoError: '',

  offers: {},
  gettingOffers: false,
  offersError: '',

  behavior: '',
  gettingBehavior: false,
  behaviorError: '',

  pricingSimulation: {},
  simulatingPricing: false,
  pricingSimulationError: '',

  offersHistory: [],
  gettingOffersHistory: false,
  offersHistoryError: '',

  pricingHistory: [],
  gettingPricingHistory: false,
  pricingHistoryError: '',
};

export function channelProduct(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL_PRODUCT_INFORMATION:
      return { ...state, info: action.info };
    case GETTING_CHANNEL_PRODUCT_INFORMATION:
      return { ...state, gettingInfo: action.gettingInfo };
    case SET_CHANNEL_PRODUCT_INFORMATION_ERROR:
      return { ...state, infoError: action.error };
    case SET_CHANNEL_PRODUCT_OFFERS:
      return { ...state, offers: action.offers };
    case GETTING_CHANNEL_PRODUCT_OFFERS:
      return { ...state, gettingOffers: action.gettingOffers };
    case SET_CHANNEL_PRODUCT_OFFERS_ERROR:
      return { ...state, offersError: action.error };
    case SET_CHANNEL_PRODUCT_BEHAVIOR:
      return { ...state, behavior: action.behavior };
    case GETTING_CHANNEL_PRODUCT_BEHAVIOR:
      return { ...state, gettingBehavior: action.gettingBehavior };
    case SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR:
      return { ...state, behaviorError: action.error };
    case SET_SIMULATION_PRICING_INFO:
      return { ...state, pricingSimulation: action.info };
    case SIMULATING_PRICING:
      return { ...state, simulatingPricing: action.simulatingPricing };
    case SET_SIMULATION_PRICING_ERROR:
      return { ...state, pricingSimulationError: action.error };
    case SET_OFFERS_HISTORY:
      return { ...state, offersHistory: action.offersHistory };
    case GETTING_OFFERS_HISTORY:
      return { ...state, gettingOffersHistory: action.gettingOffersHistory };
    case SET_OFFERS_HISTORY_ERROR:
      return { ...state, offersHistoryError: action.error };
    case SET_PRICING_HISTORY:
      return { ...state, pricingHistory: action.pricingHistory };
    case GETTING_PRICING_HISTORY:
      return { ...state, gettingPricingHistory: action.gettingPricingHistory };
    case SET_PRICING_HISTORY_ERROR:
      return { ...state, pricingHistoryError: action.error };
    default:
      return state;
  }
}
