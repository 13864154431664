import moment from 'moment';

const format = 'YYYY-MM-DD';

export function getCurrentDate() {
  return moment().format(format);
}

export function getLastMonthDate() {
  return moment().subtract(1, 'month').format(format);
}

export function formatDate(str, dateFormat = format) {
  return moment(str).format(dateFormat);
}

export function getTS(str) {
  return moment(str).unix() * 1000;
}

export function getLastDaysDate(daysToSubstract) {
  return moment().subtract(daysToSubstract, 'days').format('DD MMM');
}

export function apiDateToReadableDate(apiDate) {
  return apiDate.replace(/(.*)T(.*)\+.*/, '$1 $2');
}
