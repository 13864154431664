import {
  GETTING_CATALOG_ATTRIBUTES,
  GETTING_CATALOG_CONFIGURED,
  GETTING_CATALOG_CREDENTIALS,
  GETTING_CATALOG_FILTERS,
  GETTING_CATALOG_STATISTICS,
  GETTING_PRODUCTS_COUNT,
  GETTING_PRODUCTS_LIMIT,
  IMPORT_CATALOG_ERROR,
  IMPORTING_CATALOG,
  POSTING_CATALOG_FILTERS,
  SAVE_CATALOG_ATTRIBUTES_ERROR,
  SAVING_CATALOG_ATTRIBUTES,
  SENDING_CMS_CREDENTIALS,
  SET_CATALOG_ATTRIBUTES,
  SET_CATALOG_ATTRIBUTES_ERROR,
  SET_CATALOG_CONFIGURED,
  SET_CATALOG_CREDENTIALS,
  SET_CATALOG_CREDENTIALS_ERROR,
  SET_CATALOG_FILTERS,
  SET_CATALOG_STATISTICS,
  SET_PRODUCT_PRICING,
  SET_PRODUCTS_COUNT,
  SET_PRODUCTS_COUNT_ERROR,
  SET_PRODUCTS_LIMIT,
  SET_PRODUCTS_LIMIT_ERROR,
} from '../actions/catalogConfigure';

let initialState = {
  statistics: {},
  filters: {},
  fetching: false,
  configured: {
    checking: false,
    sending: false,
  },
  credentials: '',
  gettingCredentials: false,
  credentialsError: '',

  importingCatalog: false,
  importCatalogError: '',

  limit: 0,
  gettingLimit: false,
  limitError: '',

  count: 0,
  gettingCount: false,
  countError: '',

  catalogAttributes: {},
  gettingCatalogAttributes: false,
  catalogAttributesError: '',

  savingCatalogAttributes: false,
  saveCatalogAttributesError: '',
};

// Takes care of changing the application state
function catalogConfigure(state = initialState, action) {
  const {
    type,
    filters,
    statistics,
    fetching,
    configured,
    checking,
    sending,
    posting_filters,
    getting_filters,
    error,
    getting,
    credentials,
    limit,
    count,
    catalogAttributes,
  } = action;
  switch (type) {
    case SET_CATALOG_FILTERS:
      return { ...state, filters };
    case POSTING_CATALOG_FILTERS:
      return { ...state, posting_filters };
    case GETTING_CATALOG_FILTERS:
      return { ...state, getting_filters };
    case SET_CATALOG_STATISTICS:
      return { ...state, statistics };
    case GETTING_CATALOG_STATISTICS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          fetching,
          error: fetching ? null : state.statistics.error,
        },
      };

    case SET_CATALOG_CONFIGURED:
      return { ...state, configured: { ...state.configured, ...configured } };
    case SET_PRODUCT_PRICING:
      return { ...state, configured: { ...state.configured, feed: action.feed } };

    case SENDING_CMS_CREDENTIALS:
      return {
        ...state,
        configured: {
          ...state.configured,
          sending,
          error: sending ? null : state.configured.error,
        },
      };
    case GETTING_CATALOG_CONFIGURED:
      return {
        ...state,
        configured: {
          ...state.configured,
          checking,
          error: checking ? null : state.configured.error,
        },
      };
    case GETTING_CATALOG_CREDENTIALS:
      return { ...state, gettingCredentials: getting };
    case SET_CATALOG_CREDENTIALS:
      return { ...state, credentials };
    case SET_CATALOG_CREDENTIALS_ERROR:
      return { ...state, credentialsError: error };
    case IMPORTING_CATALOG:
      return { ...state, importingCatalog: getting };
    case IMPORT_CATALOG_ERROR:
      return { ...state, importCatalogError: error };
    case GETTING_PRODUCTS_LIMIT:
      return { ...state, gettingLimit: getting };
    case SET_PRODUCTS_LIMIT:
      return { ...state, limit };
    case SET_PRODUCTS_LIMIT_ERROR:
      return { ...state, limitError: error };
    case GETTING_PRODUCTS_COUNT:
      return { ...state, gettingCount: getting };
    case SET_PRODUCTS_COUNT:
      return { ...state, count };
    case SET_PRODUCTS_COUNT_ERROR:
      return { ...state, countError: error };
    case GETTING_CATALOG_ATTRIBUTES:
      return { ...state, gettingCatalogAttributes: getting };
    case SET_CATALOG_ATTRIBUTES:
      return { ...state, catalogAttributes };
    case SET_CATALOG_ATTRIBUTES_ERROR:
      return { ...state, catalogAttributesError: error };
    case SAVING_CATALOG_ATTRIBUTES:
      return { ...state, savingCatalogAttributes: getting };
    case SAVE_CATALOG_ATTRIBUTES_ERROR:
      return { ...state, saveCatalogAttributesError: error };
    default:
      return state;
  }
}

export default catalogConfigure;
