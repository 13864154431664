export const GET_CATALOG_STATISTICS = 'GET_CATALOG_STATISTICS';
export const GETTING_CATALOG_STATISTICS = 'GETTING_CATALOG_STATISTICS';
export const SET_CATALOG_STATISTICS = 'SET_CATALOG_STATISTICS';

export const CHECK_CATALOG_CONFIGURED = 'CHECK_CATALOG_CONFIGURED';
export const GETTING_CATALOG_CONFIGURED = 'GETTING_CATALOG_CONFIGURED';
export const SET_CATALOG_CONFIGURED = 'SET_CATALOG_CONFIGURED';

export const SEND_CMS_CREDENTIALS = 'SEND_CMS_CREDENTIALS';
export const SEND_CMS_CSV_CREDENTIALS = 'SEND_CMS_CSV_CREDENTIALS';
export const SENDING_CMS_CREDENTIALS = 'SENDING_CMS_CREDENTIALS';

export const GET_CATALOG_CONFIGURATION = 'GET_CATALOG_CONFIGURATION';
export const GET_PRODUCT_PRICING = 'GET_PRODUCT_PRICING';
export const SET_PRODUCT_PRICING = 'SET_PRODUCT_PRICING';

export const GET_CATALOG_FILTERS = 'GET_CATALOG_FILTERS';
export const SET_CATALOG_FILTERS = 'SET_CATALOG_FILTERS';
export const POST_CATALOG_FILTERS = 'POST_CATALOG_FILTERS';
export const POSTING_CATALOG_FILTERS = 'POSTING_CATALOG_FILTERS';
export const GETTING_CATALOG_FILTERS = 'GETTING_CATALOG_FILTERS';

export const GET_CATALOG_CREDENTIALS = 'GET_CATALOG_CREDENTIALS';
export const SET_CATALOG_CREDENTIALS = 'SET_CATALOG_CREDENTIALS';
export const GETTING_CATALOG_CREDENTIALS = 'GETTING_CATALOG_CREDENTIALS';
export const SET_CATALOG_CREDENTIALS_ERROR = 'SET_CATALOG_CREDENTIALS_ERROR';

export const IMPORT_CATALOG = 'IMPORT_CATALOG';
export const IMPORTING_CATALOG = 'IMPORTING_CATALOG';
export const IMPORT_CATALOG_ERROR = 'IMPORT_CATALOG_ERROR';

export const GET_PRODUCTS_LIMIT = 'GET_PRODUCTS_LIMIT';
export const GETTING_PRODUCTS_LIMIT = 'GETTING_PRODUCTS_LIMIT';
export const SET_PRODUCTS_LIMIT = 'SET_PRODUCTS_LIMIT';
export const SET_PRODUCTS_LIMIT_ERROR = 'SET_PRODUCTS_LIMIT_ERROR';

export const GET_PRODUCTS_COUNT = 'GET_PRODUCTS_COUNT';
export const GETTING_PRODUCTS_COUNT = 'GETTING_PRODUCTS_COUNT';
export const SET_PRODUCTS_COUNT = 'SET_PRODUCTS_COUNT';
export const SET_PRODUCTS_COUNT_ERROR = 'SET_PRODUCTS_COUNT_ERROR';

export const GET_CATALOG_ATTRIBUTES = 'GET_CATALOG_ATTRIBUTES';
export const GETTING_CATALOG_ATTRIBUTES = 'GETTING_CATALOG_ATTRIBUTES';
export const SET_CATALOG_ATTRIBUTES = 'SET_CATALOG_ATTRIBUTES';
export const SET_CATALOG_ATTRIBUTES_ERROR = 'SET_CATALOG_ATTRIBUTES_ERROR';

export const SAVE_CATALOG_ATTRIBUTES = 'SAVE_CATALOG_ATTRIBUTES';
export const SAVING_CATALOG_ATTRIBUTES = 'SAVING_CATALOG_ATTRIBUTES';
export const SAVE_CATALOG_ATTRIBUTES_ERROR = 'SAVE_CATALOG_ATTRIBUTES_ERROR';

export const getCatalogStatistics = (params = {}) => {
  return { type: GET_CATALOG_STATISTICS, ...params };
};

export const checkCatalogConfigured = () => {
  return { type: CHECK_CATALOG_CONFIGURED };
};

export const getCatalogFilters = () => {
  return { type: GET_CATALOG_FILTERS };
};

export const saveCatalogFilters = filters => {
  return { type: POST_CATALOG_FILTERS, filters };
};

export const saveCatalogCredentials = data => {
  return { type: SEND_CMS_CREDENTIALS, ...data };
};

export const saveCatalogCSVCredentials = data => {
  return { type: SEND_CMS_CSV_CREDENTIALS, ...data };
};

export const clearCatalogStatistics = () => {
  return { type: SET_CATALOG_STATISTICS, statistics: {} };
};

export const getCatalogConfiguration = connector => {
  return { type: GET_CATALOG_CONFIGURATION, connector };
};

export const getProductPricing = () => {
  return { type: GET_PRODUCT_PRICING };
};

export const getCatalogCredentials = () => {
  return { type: GET_CATALOG_CREDENTIALS };
};

export const importCatalog = () => {
  return { type: IMPORT_CATALOG };
};

export const getProductsLimit = () => {
  return { type: GET_PRODUCTS_LIMIT };
};

export const getProductsCount = (filters = null) => {
  return { type: GET_PRODUCTS_COUNT, filters };
};

export const getCatalogAttributes = () => {
  return { type: GET_CATALOG_ATTRIBUTES };
};

export const saveCatalogAttributes = attributes => {
  return { type: SAVE_CATALOG_ATTRIBUTES, attributes };
};
