const KEY_TOKEN = 'token';
const KEY_REFRESH_TOKEN = 'refresh_token';
const KEY_EXPIRATION_TIMESTAMP = 'expiration';

export function setToken(token, expiration) {
  window.localStorage.setItem(KEY_TOKEN, token);
  window.localStorage.setItem(KEY_EXPIRATION_TIMESTAMP, expiration);
}

export function getToken() {
  return window.localStorage.getItem(KEY_TOKEN);
}

export function getExpirationTimestamp() {
  return window.localStorage.getItem(KEY_EXPIRATION_TIMESTAMP);
}

export function setRefreshToken(token) {
  window.localStorage.setItem(KEY_REFRESH_TOKEN, token);
}

export function getRefreshToken() {
  return window.localStorage.getItem(KEY_REFRESH_TOKEN);
}

export function clearTokens() {
  window.localStorage.removeItem(KEY_TOKEN);
  window.localStorage.removeItem(KEY_REFRESH_TOKEN);
  window.localStorage.removeItem(KEY_EXPIRATION_TIMESTAMP);
}
