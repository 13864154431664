import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import NotFound from '../pages/NotFound';
import { storeToken } from '../tools/logAs';

import PrivateRoute from './PrivateRoute';
import { ROUTES } from './routes';

const Loadable = Component => props => (
  <Suspense
    fallback={
      <div style={{ textAlign: 'center', padding: 50 }}>
        <Spin size="large" />
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
);

const Home = Loadable(lazy(() => import('../pages/Home/Home')));
const Catalog = Loadable(lazy(() => import('../pages/Catalog/Catalog')));
const Login = Loadable(lazy(() => import('../pages/Login/Login')));
const Register = Loadable(lazy(() => import('../pages/Register')));
const ForgottenPwd = Loadable(lazy(() => import('../pages/Login/ForgottenPwd')));
const ResetPwd = Loadable(lazy(() => import('../pages/Login/ResetPwd')));
const Channel = Loadable(lazy(() => import('../pages/Channel/Dashboard/Dashboard')));
const ChannelConfiguration = Loadable(
  lazy(() => import('../pages/Channel/Configuration/Configuration'))
);
const ChannelOffers = Loadable(lazy(() => import('../pages/Channel/Offers/Offers')));
const Strategies = Loadable(lazy(() => import('../pages/Strategies/Rules')));
const Carriers = Loadable(lazy(() => import('../pages/Strategies/Carriers')));
const User = Loadable(lazy(() => import('../pages/User')));
const Reports = Loadable(lazy(() => import('../pages/Reports/Reports')));
const Configuration = Loadable(lazy(() => import('../pages/confguration/Configuration')));
const ConfigureCatalog = Loadable(
  lazy(() => import('../containers/ConfigureCatalog/ConfigureCatalog'))
);
const Onboard = Loadable(lazy(() => import('../pages/Onboard/Onboard')));

// TODO(hadrien): Move into page folder ?
const ConfigureChannel = Loadable(
  lazy(() => import('../containers/ConfigureCatalog/ConfigureChannel'))
);

const routes = (
  <Switch>
    <Route exact path={ROUTES.ROOT} render={() => <Redirect to={ROUTES.HOME} />} />
    <PrivateRoute path={ROUTES.HOME} component={Home} />
    <PrivateRoute path={ROUTES.CATALOG} component={Catalog} />
    <PrivateRoute path={ROUTES.CONFIGURATIONS} component={Configuration} />
    <PrivateRoute path={ROUTES.CONFIGURE_CATALOG} component={ConfigureCatalog} />
    <PrivateRoute path={ROUTES.CONFIGURE_CHANNEL} component={ConfigureChannel} />
    <PrivateRoute path={ROUTES.CHANNEL} component={Channel} />
    <PrivateRoute path={ROUTES.CHANNEL_OFFERS} component={ChannelOffers} />
    <PrivateRoute path={ROUTES.CHANNEL_CONFIGURATION} component={ChannelConfiguration} />
    <PrivateRoute path={ROUTES.STRATEGIES} component={Strategies} />
    <PrivateRoute path={ROUTES.STRATEGIES_CARRIERS} component={Carriers} />
    <PrivateRoute path={ROUTES.USER} component={User} />
    <PrivateRoute path={ROUTES.REPORTS} component={Reports} />
    <PrivateRoute path={ROUTES.ONBOARD} component={Onboard} />
    <Route path={ROUTES.LOGIN} component={Login} />
    <Route path={ROUTES.REGISTER} component={Register} />
    <Route path={ROUTES.FORGOTTEN_PWD} component={ForgottenPwd} />
    <Route path={ROUTES.RESET_PWD} component={ResetPwd} />
    <Route
      path={ROUTES.LOG_AS}
      render={() => (storeToken() ? <Redirect to={ROUTES.HOME} /> : <Redirect to={ROUTES.LOGIN} />)}
    />

    <PrivateRoute path={ROUTES.NOT_FOUND} component={NotFound} />
    <PrivateRoute path="*" component={NotFound} />
  </Switch>
);

export default routes;
