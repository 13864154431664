import flatten from 'flat';
import React, { useState } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import fr from 'react-intl/locale-data/fr';
import en from 'react-intl/locale-data/en';
import { Provider } from 'react-redux';

import TranslationApi from './api/translations';
import App from './components/App';
import Error from './components/Error/Error';
import { InjectIntlContext } from './hooks/intl.hook';
import { store, history } from './store';
import messages_fr from './translations/fr.json';
import messages_en from './translations/en.json';

// set default
let messages = {
  fr: messages_fr,
  en: messages_en,
};

addLocaleData([...fr, ...en]);

export default function Root() {
  let lang = (navigator.language || navigator.userLanguage).substring(0, 2);
  lang = /en|fr/.test(lang) ? lang : 'en';
  const [translations, setTranslations] = useState(messages[lang]);

  return (
    <IntlProvider locale={lang} messages={translations}>
      <InjectIntlContext>
        <Provider store={store}>
          <App history={history} updateTrads={fetchApiTranslations} />
        </Provider>
      </InjectIntlContext>
    </IntlProvider>
  );

  function fetchApiTranslations() {
    TranslationApi.getFor(lang).then(apiI18N => {
      setTranslations({
        ...messages[lang],
        ...flatten(apiI18N),
      });
      if (process.env.REACT_APP_CURRENT !== 'local') {
        return;
      }
      const conflictKeys = [];
      const messageForLang = messages[lang];
      for (let i in apiI18N) {
        if (i in messageForLang) {
          conflictKeys.push(i);
        }
      }
      if (!conflictKeys.length) {
        return;
      }
      // TODO(hadrien): Send back to rollbar in prod ?
      console.log('Some api i18n are conflicting with web app keys', conflictKeys);
      // TODO(gbreux): The error is not what it should be, try to throw an error manually to reproduce.
      throw new Error('Some api i18n are conflicting with web app keys');
    });
  }
}
