import { call, put, take, select } from 'redux-saga/effects';

import {
  GET_CATALOG_PRODUCTS,
  REQUEST_ERROR,
  SENDING_REQUEST,
  SET_CATALOG_PARAMETERS,
  SET_CATALOG_PRODUCTS,
} from '../actions/catalog';
import ChannelsApi from '../api/channels';

export function* getCatalogProductsFlow() {
  while (true) {
    const request = yield take(GET_CATALOG_PRODUCTS);
    let { filtersLeft, filtersRight, sorts, page, limit } = request;
    yield put({ type: SET_CATALOG_PARAMETERS, filtersLeft, filtersRight, sorts, page, limit });
    const catalog = yield select(state => state.catalog);
    let catalogProducts = yield call(
      getCatalogProducts,
      catalog.filtersLeft,
      catalog.filtersRight,
      catalog.sorts,
      catalog.page,
      catalog.limit
    );
    if (catalogProducts) {
      yield put({
        type: SET_CATALOG_PRODUCTS,
        products: catalogProducts.products,
        total: catalogProducts.total,
      });
    }
  }
}

export function* getCatalogProducts(filtersLeft, filtersRight, sorts, page, limit) {
  yield put({
    type: SENDING_REQUEST,
    sending: true,
  });

  try {
    return yield call(ChannelsApi.getChannelOffers, {
      filtersLeft,
      filtersRight,
      sorts,
      page,
      limit,
    });
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error: error.message });
    return false;
  } finally {
    yield put({ type: SENDING_REQUEST, sending: false });
  }
}
