import { createSelector } from 'reselect';

import { GETTING_REPORTS, SET_REPORTS, SET_REPORTS_ERROR } from '../actions/reports';

let initialState = {
  reports: [],
  gettingReports: false,
  reportsError: '',
};

function reports(state = initialState, action) {
  const { type, reports, getting, error } = action;
  switch (type) {
    case SET_REPORTS:
      return { ...state, reports };
    case GETTING_REPORTS:
      return { ...state, gettingReports: getting };
    case SET_REPORTS_ERROR:
      return { ...state, reportsError: error };
    default:
      return state;
  }
}

export const formatReports = createSelector(
  ({ reports }) => {
    return reports.reports;
  },
  (reports = []) => {
    const formattedReports = [];
    reports.forEach(report => {
      report.pages.forEach(page => {
        formattedReports.push({
          name: page.name,
          width: page.width,
          height: page.height,
          url: page.url,
        });
      });
    });
    return formattedReports;
  }
);

export default reports;
