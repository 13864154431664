import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const API_TRANSLATIONS = `${API_BASE_URL_V2}translation`;

const TranslationApi = {
  async getFor(lang) {
    return await privateCall(`${API_TRANSLATIONS}?lang=${lang}`, 'GET');
  },
};

export default TranslationApi;
