import React from 'react';

export default () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m441.952 113.022-26.653-53.3231c-.39-.7857-.931-1.4867-1.593-2.0626-.662-.576-1.431-1.0155-2.263-1.2934-.832-.2778-1.71-.3886-2.585-.3258-.875.0627-1.729.2977-2.513.6914l-53.34 26.6702c-.83.3626-1.578.8904-2.197 1.5511-.62.6608-1.099 1.4407-1.407 2.2924-.309.8516-.441 1.7571-.388 2.6614.052.9043.288 1.7885.693 2.5987.405.8101.971 1.5294 1.663 2.114s1.495 1.0223 2.362 1.2864c.866.2641 1.778.3491 2.678.2499s1.771-.3806 2.559-.8271l38.632-19.2985-10.536 30.095c-22.68 64.597-63.518 121.277-117.613 163.239-54.094 41.963-119.148 67.425-187.3551 73.333l1.1301 13.335c70.776-6.132 138.278-32.558 194.406-76.106s98.496-102.369 122.019-169.402l10.762-30.7386 19.612 39.2406c.362.83.89 1.577 1.551 2.197.661.619 1.441 1.098 2.292 1.407.852.308 1.757.44 2.662.388.904-.053 1.788-.289 2.598-.694.811-.405 1.53-.971 2.114-1.662.585-.692 1.023-1.496 1.287-2.363.264-.866.349-1.777.25-2.678-.099-.9-.381-1.771-.827-2.559z" />
      <path d="m175.998 96.0014h-86.6691v13.3346h86.6691z" />
      <path d="m175.998 122.67h-86.6691v13.335h86.6691z" />
      <path d="m89.3289 149.342h59.9991v13.335h-59.9991z" />
      <path d="m149.328 176.011h-59.9991v13.335h59.9991z" />
      <path d="m56 442.677h400v13.336h-400z" />
      <path
        clipRule="evenodd"
        d="m89.3278 376.003h46.6812c1.763.004 3.453.708 4.698 1.956s1.944 2.94 1.944 4.703v39.987c0 1.766-.702 3.46-1.951 4.709-1.248 1.248-2.942 1.95-4.708 1.95h-46.6642c-1.766 0-3.4597-.702-4.7085-1.95-1.2488-1.249-1.9504-2.943-1.9504-4.709v-39.987c0-1.766.7016-3.461 1.9504-4.709 1.2488-1.249 2.9425-1.95 4.7085-1.95zm6.6763 39.987h33.3459l-.017-26.67h-33.3289z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m209.343 356.009h-46.664c-1.766 0-3.459.701-4.708 1.949-1.249 1.249-1.95 2.943-1.95 4.709v59.999c0 1.766.701 3.46 1.95 4.709 1.249 1.248 2.942 1.95 4.708 1.95h46.664c1.766 0 3.46-.702 4.709-1.95 1.249-1.249 1.95-2.943 1.95-4.709v-59.999c0-1.766-.701-3.46-1.95-4.709-1.249-1.248-2.943-1.949-4.709-1.949zm-6.658 59.998h-33.347v-46.663h33.329z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m236.014 322.68h46.664c.874 0 1.74.172 2.548.506.808.335 1.542.825 2.161 1.444.618.618 1.108 1.352 1.443 2.16s.507 1.674.507 2.548v93.328c0 .875-.172 1.741-.507 2.549-.335.807-.825 1.542-1.443 2.16-.619.618-1.353 1.109-2.161 1.443-.808.335-1.674.508-2.548.508h-46.664c-1.766 0-3.46-.702-4.709-1.951-1.248-1.249-1.95-2.943-1.95-4.709v-93.328c0-1.766.702-3.46 1.95-4.708 1.249-1.249 2.943-1.95 4.709-1.95zm6.659 93.327h33.346l-.017-79.975h-33.329z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m355.996 275.999h-46.665c-1.766 0-3.459.701-4.708 1.95s-1.95 2.943-1.95 4.709v139.992c0 1.766.701 3.46 1.95 4.709 1.249 1.248 2.942 1.95 4.708 1.95h46.665c1.766 0 3.459-.702 4.708-1.95 1.249-1.249 1.951-2.943 1.951-4.709v-139.992c0-1.766-.702-3.46-1.951-4.709s-2.942-1.95-4.708-1.95zm-6.66 139.992h-33.346v-126.657h33.33z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m382.666 182.67h46.664c1.766 0 3.46.702 4.709 1.951 1.248 1.249 1.95 2.942 1.95 4.708v233.338c0 1.766-.702 3.46-1.95 4.709-1.249 1.248-2.943 1.95-4.709 1.95h-46.664c-1.766 0-3.46-.702-4.709-1.95-1.248-1.249-1.95-2.943-1.95-4.709v-233.338c0-1.766.702-3.459 1.95-4.708 1.249-1.249 2.943-1.951 4.709-1.951zm6.659 233.338h33.346l-.017-220.003h-33.329z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
