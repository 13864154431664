import { COLLAPSE_LEFT, COLLAPSE_RIGHT, NOTIFICATIONS, SET_RIGHT_CONTENT } from '../actions/siders';

let initialState = {
  leftCollapsed: true,
  rightCollapsed: true,
  rightContent: NOTIFICATIONS,
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  switch (action.type) {
    case COLLAPSE_LEFT:
      return { ...state, leftCollapsed: action.collapse };
    case COLLAPSE_RIGHT:
      return { ...state, rightCollapsed: action.collapse };
    case SET_RIGHT_CONTENT:
      return { ...state, rightContent: action.content, rightCollapsed: action.collapseRight };
    default:
      return state;
  }
}

export default reducer;
