import {
  CLEAR_ERROR,
  REQUEST_ERROR,
  SENDING_REQUEST,
  SET_CATALOG_PARAMETERS,
  SET_CATALOG_PRODUCTS,
} from '../actions/catalog';

let initialState = {
  products: [],
  total: 0,
  filtersLeft: [],
  filtersRight: [],
  sorts: [],
  page: 1,
  limit: 25,
  error: '',
  currentlySending: false,
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  const {
    type,
    products = [],
    total = 0,
    filtersLeft = state.filtersLeft,
    filtersRight = state.filtersRight,
    sorts = state.sorts,
    page = state.page,
    limit = state.limit,
    error,
  } = action;
  switch (type) {
    case SET_CATALOG_PRODUCTS:
      return { ...state, products, total };
    case SET_CATALOG_PARAMETERS:
      return { ...state, filtersLeft, filtersRight, sorts, page, limit };
    case SENDING_REQUEST:
      return { ...state, currentlySending: action.sending };
    case REQUEST_ERROR:
      return { ...state, error };
    case CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
