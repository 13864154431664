import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Button } from 'antd';
import { withRouter, matchPath } from 'react-router';

import ProfileButton from '../../components/ProfileButton/ProfileButton';
import { ROUTES } from '../../routes/routes.js';
import { RocketIcon } from '../../components/Icons';
import { useFormatMessage } from '../../hooks/intl.hook';
import MainMenu from '../../components/MainMenu/MainMenu';
import pricingLabIcon from '../../img/logo_mypricing.svg';

import './MainHeader.less';

function MainHeader({ history, match, channels, reports }) {
  const intl = useFormatMessage();

  return (
    <Layout.Header className="main-header">
      <div className="main-header__menu-row">
        <img
          className="main-header__logo"
          src={pricingLabIcon}
          alt="Boost My Shop Logo"
          height={24}
        />
        <MainMenu match={match} channels={channels} reports={reports} />

        <div className="main-header__menu-row__right">
          <Button
            shape="circle"
            icon={<QuestionCircleOutlined style={{ fontSize: '25px', lineHeight: 1 }} />}
            type="text"
            size="large"
            onClick={() => {
              openHelp({ location: window.location.pathname });
            }}
          />
          <Button
            shape="circle"
            icon={<RocketIcon style={{ fontSize: '25px' }} />}
            type="text"
            size="large"
            onClick={() => history.push(ROUTES.ONBOARD)}
          />
          <ProfileButton />
        </div>
      </div>
    </Layout.Header>
  );

  function openHelp({ location, searchWords }) {
    if (searchWords === '') {
      return;
    }
    if (typeof window.ZohoHCAsap !== 'undefined') {
      if (searchWords) {
        window.ZohoHCAsap.kbArticles.search({ searchWords });
      } else {
        let categoryId = '';
        if (location) {
          if (matchPath(location, { path: ROUTES.HOME })) {
            categoryId = intl({ id: 'zoho.category.home' });
          } else if (matchPath(location, { path: ROUTES.CHANNEL })) {
            categoryId = intl({ id: 'zoho.category.channels_dashboard' });
          } else if (matchPath(location, { path: ROUTES.ONBOARD })) {
            categoryId = intl({ id: 'zoho.category.onboard' });
          } else if (matchPath(location, { path: ROUTES.CHANNEL_OFFERS })) {
            categoryId = intl({ id: 'zoho.category.channels_offers' });
          } else if (matchPath(location, { path: ROUTES.CHANNEL_CONFIGURATION })) {
            categoryId = intl({ id: 'zoho.category.channels_configuration' });
          } else if (matchPath(location, { path: ROUTES.CATALOG })) {
            categoryId = intl({ id: 'zoho.category.catalogue' });
          } else if (matchPath(location, { path: ROUTES.STRATEGIES })) {
            categoryId = intl({ id: 'zoho.category.strategies' });
          } else if (matchPath(location, { path: ROUTES.STRATEGIES_CARRIERS })) {
            categoryId = intl({ id: 'zoho.category.strategies_transporteurs' });
          } else if (matchPath(location, { path: ROUTES.CONFIGURE_CATALOG })) {
            categoryId = intl({ id: 'zoho.category.configuration_catalog' });
          } else if (matchPath(location, { path: ROUTES.CONFIGURE_CHANNEL })) {
            categoryId = intl({ id: 'zoho.category.configuration_channel' });
          }
        }
        window.ZohoHCAsapReady(function () {
          const params = {};
          if (categoryId) {
            params.categoryId = categoryId;
          }
          window.ZohoHCAsap.kbArticles.list({ ...params });
        });
      }
    }
  }
}

export default withRouter(MainHeader);
