import { GETTING_PROGRESS, SET_PROGRESS, SET_PROGRESS_ERROR } from '../actions/repricing';

let initialState = {
  progress: '100%',
  toReprice: 'no',
  inProgress: 'no',
  error: '',
  gettingProgress: false,
};

export function repricing(state = initialState, action) {
  const { progress, toReprice, inProgress, processing, error, type } = action;
  switch (type) {
    case SET_PROGRESS:
      return { ...state, progress, toReprice, inProgress };
    case GETTING_PROGRESS:
      return { ...state, gettingProgress: processing };
    case SET_PROGRESS_ERROR:
      return { ...state, error };
    default:
      return state;
  }
}
