import { call, put, take } from 'redux-saga/effects';

import {
  GET_CATALOG_HISTORY,
  GETTING_CATALOG_HISTORY,
  SET_CATALOG_HISTORY,
  SET_CATALOG_HISTORY_ERROR,
} from '../actions/catalogHistory';
import CatalogHistoryApi from '../api/catalogHistory.api';

export function* getCatalogHistory() {
  while (true) {
    yield take(GET_CATALOG_HISTORY);
    yield put({ type: GETTING_CATALOG_HISTORY, getting: true });

    try {
      const result = yield call(CatalogHistoryApi.getCatalogHistory);
      if (result) {
        const formattedResult = Object.keys(result).map(uid => result[uid]);
        yield put({ type: SET_CATALOG_HISTORY, history: formattedResult });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_HISTORY_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_CATALOG_HISTORY, getting: false });
    }
  }
}
