// TODO Hadrien Get from API or something ? or simple img src ?
import catalogPrestashop from '../img/catalog-prestashop.png';
import catalogMagento from '../img/catalog-magento.png';
import catalogMagento2Api from '../img/catalog-magento2api.png';
import catalogShopify from '../img/catalog-shopify.png';
import catalogAmazon from '../img/catalog-amazon.png';
import catalogCustom from '../img/assistant/custom-input-file.svg';

export const customFileCatalog = 'custom';

export const CATALOGS = [
  { id: 'prestashop', logo: catalogPrestashop, enabled: true },
  { id: customFileCatalog, logo: catalogCustom, enabled: true },
  { id: 'shopify', logo: catalogShopify, enabled: true },
  { id: 'shopifyapi', logo: catalogShopify, enabled: true },
  { id: 'magento2', logo: catalogMagento, enabled: true },
  { id: 'magento2api', logo: catalogMagento2Api, enabled: true },
  { id: 'amazon', logo: catalogAmazon, enabled: false },
];

export const Colors = {
  primaryColor: '#c44972',
  primaryColorLight: '#0947a1',
  primaryColorLighter: '#0b5dd2',
  primaryColorLightest: '#dfebfd',
  disabledPrimaryColor: '#7B8899',
  primaryColorText: '#061F3E',
  borderColor: '#CCCCCC',
  backgroundColor: '#F8F8F8',
  backgroundDarkColor: '#F0F2F5',
  validGreen: '#52C41A',
  invalidRed: '#F10303',
  disabledGrey: '#696969',
  cellTextColorChecked: 'white',
  cellTextColor: '#000000a6',
  pricingStatusBuyBoxWinner: '#52C41A',
  pricingStatusChallenger: '#c7c71c',
  pricingStatusOutOfCompetition: '#c7851c',
  pricingStatusNoOffers: '#96e072',
  pricingStatusDisabled: '#7B8899',
  pricingStatusError: '#F10303',
  pricingStatusNotAssociated: '#0b5dd2',
};

export const CARD_DEFAULT_HEIGHT = 250;

export const API_BASE_URL = `${process.env.REACT_APP_API}/v1/`;
export const API_BASE_URL_V2 = `${process.env.REACT_APP_API}/v2/`;
