import {
  CLEAR_CATALOG_MAPPING_ERROR,
  DONE_CATALOG_MAPPING,
  GETTING_CATALOG_MAPPING,
  POSTING_CATALOG_MAPPING,
  SET_CATALOG_MAPPING,
} from '../actions/catalogMapping';

let initialState = {
  mapping: [],
  attributes: [],
  getting: false,
  done: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATALOG_MAPPING:
      return { ...state, mapping: action.mapping, attributes: action.attributes };
    case GETTING_CATALOG_MAPPING:
      return { ...state, getting: action.getting };
    case POSTING_CATALOG_MAPPING:
      return { ...state, posting: action.posting };
    case DONE_CATALOG_MAPPING:
      return { ...state, done: action.done };
    case CLEAR_CATALOG_MAPPING_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
