import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const REPORTS = `${API_BASE_URL_V2}client-data/analytic-report/`;

const ReportsApi = {
  async getReports() {
    return await privateCall(REPORTS, 'GET');
  },
};

export default ReportsApi;
