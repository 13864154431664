import React from 'react';
import { FormattedMessage } from 'react-intl';

let NotFound = props => {
  return (
    <div>
      <h1>
        <FormattedMessage id="errors.pagenotfound" />
      </h1>
    </div>
  );
};

export default NotFound;
