import { call, put, take } from 'redux-saga/effects';

import ChannelsApi from '../api/channels';
import {
  GET_CHANNEL_COMPETITORS_STATS,
  GET_CHANNEL_MARGIN_INFO,
  GET_CHANNEL_MATCHING_STATUS,
  GET_CHANNEL_POSITION_BY_DATE,
  GET_CHANNEL_STATUS_BY_DATE,
  GET_CHANNEL_TARGET_POSITION_BY_DATE,
  GETTING_CHANNEL_MATCHING_STATUS,
  GETTING_COMPETITORS_STATS,
  GETTING_MARGIN_INFO,
  GETTING_POSITION_BY_DATE,
  GETTING_STATUS_BY_DATE,
  GETTING_TARGET_POSITION_BY_DATE,
  SET_CHANNEL_MATCHING_STATUS,
  SET_CHANNEL_MATCHING_STATUS_ERROR,
  SET_COMPETITORS_STATS,
  SET_COMPETITORS_STATS_ERROR,
  SET_MARGIN_INFO,
  SET_MARGIN_INFO_ERROR,
  SET_POSITION_BY_DATE,
  SET_POSITION_BY_DATE_ERROR,
  SET_STATUS_BY_DATE,
  SET_STATUS_BY_DATE_ERROR,
  SET_TARGET_POSITION_BY_DATE,
  SET_TARGET_POSITION_BY_DATE_ERROR,
} from '../actions/channelStats';

export function* getChannelMatchingStatus() {
  while (true) {
    const request = yield take(GET_CHANNEL_MATCHING_STATUS);
    yield put({ type: GETTING_CHANNEL_MATCHING_STATUS, gettingMatching: true });
    try {
      const result = yield call(ChannelsApi.getChannelMatchingStatus, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_CHANNEL_MATCHING_STATUS, matching: result });
      }
    } catch (error) {
      yield put({ type: SET_CHANNEL_MATCHING_STATUS, matching: {} });
      yield put({ type: SET_CHANNEL_MATCHING_STATUS_ERROR, error });
    } finally {
      yield put({ type: GETTING_CHANNEL_MATCHING_STATUS, gettingMatching: false });
    }
  }
}

export function* getMarginInfo() {
  while (true) {
    const request = yield take(GET_CHANNEL_MARGIN_INFO);
    yield put({ type: GETTING_MARGIN_INFO, gettingMargin: true });
    try {
      const result = yield call(ChannelsApi.getChannelMarginInfo, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_MARGIN_INFO, margin: result });
      }
    } catch (error) {
      yield put({ type: SET_MARGIN_INFO, matching: {} });
      yield put({ type: SET_MARGIN_INFO_ERROR, error });
    } finally {
      yield put({ type: GETTING_MARGIN_INFO, gettingMargin: false });
    }
  }
}
export function* getCompetitorsStats() {
  while (true) {
    const request = yield take(GET_CHANNEL_COMPETITORS_STATS);
    yield put({ type: GETTING_COMPETITORS_STATS, gettingCompetitorsStats: true });
    try {
      const result = yield call(ChannelsApi.getChannelCompetitorsStats, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_COMPETITORS_STATS, competitorsStats: result });
      }
    } catch (error) {
      yield put({ type: SET_COMPETITORS_STATS, competitorsStats: {} });
      yield put({ type: SET_COMPETITORS_STATS_ERROR, error });
    } finally {
      yield put({ type: GETTING_COMPETITORS_STATS, gettingCompetitorsStats: false });
    }
  }
}

export function* getPositionByDate() {
  while (true) {
    const request = yield take(GET_CHANNEL_POSITION_BY_DATE);
    yield put({ type: GETTING_POSITION_BY_DATE, gettingPositionByDate: true });
    try {
      const result = yield call(ChannelsApi.getChannelPositionByDate, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_POSITION_BY_DATE, positionByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_POSITION_BY_DATE, positionByDate: {} });
      yield put({ type: SET_POSITION_BY_DATE_ERROR, error });
    } finally {
      yield put({ type: GETTING_POSITION_BY_DATE, gettingPositionByDate: false });
    }
  }
}

export function* getTargetPositionByDate() {
  while (true) {
    const request = yield take(GET_CHANNEL_TARGET_POSITION_BY_DATE);
    yield put({ type: GETTING_TARGET_POSITION_BY_DATE, gettingTargetPositionByDate: true });
    try {
      const result = yield call(ChannelsApi.getChannelTargetPositionByDate, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_TARGET_POSITION_BY_DATE, targetPositionByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_TARGET_POSITION_BY_DATE, targetPositionByDate: {} });
      yield put({ type: SET_TARGET_POSITION_BY_DATE_ERROR, error });
    } finally {
      yield put({ type: GETTING_TARGET_POSITION_BY_DATE, gettingTargetPositionByDate: false });
    }
  }
}

export function* getStatusByDate() {
  while (true) {
    const request = yield take(GET_CHANNEL_STATUS_BY_DATE);
    yield put({ type: GETTING_STATUS_BY_DATE, gettingStatusByDate: true });
    try {
      const result = yield call(ChannelsApi.getChannelStatusByDate, request.channel);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_STATUS_BY_DATE, statusByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_STATUS_BY_DATE, statusByDate: {} });
      yield put({ type: SET_STATUS_BY_DATE_ERROR, error });
    } finally {
      yield put({ type: GETTING_STATUS_BY_DATE, gettingStatusByDate: false });
    }
  }
}
