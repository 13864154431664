export const ROUTES = {
  ROOT: '/',
  HOME: '/home',
  CATALOG: '/catalog',
  CATALOG_STRATEGY: '/strategies',
  CONFIGURATIONS: '/configuration/:segment(catalog|channel)',
  CONFIGURE_CATALOG: '/configure/catalog/:catalog_id',
  CONFIGURE_CHANNEL: '/configure/channel/:channel_id',
  CHANNEL: '/channels/:channel_id/:channel_name/dashboard',
  CHANNEL_CONFIGURATION: '/channels/:channel_id/:channel_name/configuration',
  CHANNEL_OFFERS: '/channels/:channel_id/:channel_name/offers',
  STRATEGIES: '/strategies/rules',
  STRATEGIES_CARRIERS: '/strategies/carriers',
  REPORTS: '/reports',
  USER: '/user',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOTTEN_PWD: '/forgotten',
  RESET_PWD: '/reset-password',
  NOT_FOUND: '/404',
  ONBOARD: '/onboard',
  LOG_AS: '/logas',
};

export const routeWithParams = (route, params) => {
  let keys = Object.keys(params);
  return keys.reduce((acc, it) => {
    const param = params[it] ? params[it].replace(/\./g, '-') : undefined;
    const placeholder = new RegExp(':' + it + '(\\(.*?\\))?');
    const trailingQuestionMark = /(\?)$/g;
    return acc.replace(placeholder, param).replace(trailingQuestionMark, '');
  }, route);
};
