import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';
import rootReducer from './reducers';

const history = createBrowserHistory();
const logger = () => next => action => next(action); // Empty logger reducer

const sagaMiddleware = createSagaMiddleware();

const reduxDevtoolsExt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancer =
  typeof reduxDevtoolsExt === 'function'
    ? reduxDevtoolsExt({ trace: true, traceLimit: 25 })
    : compose;
const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancer(applyMiddleware(logger, routerMiddleware(history), sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export { store, history };
