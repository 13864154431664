import React from 'react';

export default () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m280.096 263.807c-6.415 6.411-15.078 10.067-24.147 10.19l.042-.062c-9.067-.123-17.728-3.78-24.14-10.191-6.412-6.412-10.068-15.073-10.191-24.14v-171.6542c-.039-4.5021.842-8.9647 2.588-13.1146s4.321-7.8999 7.566-11.0204c3.14-3.2263 6.894-5.7907 11.041-7.5418 4.147-1.751 8.603-2.6532 13.104-2.6532 4.502 0 8.958.9022 13.105 2.6532 4.148 1.7511 7.902 4.3155 11.041 7.5418 3.253 3.117 5.835 6.8653 7.588 11.0154s2.641 8.6145 2.608 13.1196v171.7162c-.129 9.069-3.79 17.73-10.205 24.141z" />
      <path d="m379.645 108.796c25.689 18.987 46.512 43.794 60.761 72.384 14.348 28.563 21.746 60.11 21.592 92.073.084 27.484-5.484 54.692-16.359 79.933-20.646 49.626-60.088 89.068-109.714 109.714-25.268 10.804-52.463 16.375-79.944 16.375-27.48 0-54.675-5.571-79.943-16.375-49.63-20.647-89.0784-60.087-109.7346-109.714-17.992-42.237-21.2667-89.295-9.2983-133.616 11.9683-44.322 38.4853-83.335 75.2919-110.774 3.603-2.779 7.729-4.804 12.131-5.955s8.991-1.405 13.493-.746c4.471.516 8.78 1.978 12.642 4.289s7.188 5.417 9.756 9.113c2.787 3.543 4.81 7.625 5.942 11.989s1.348 8.914.635 13.366c-.557 4.493-2.021 8.827-4.303 12.738-2.283 3.91-5.336 7.316-8.975 10.011-17.152 12.792-31.069 29.422-40.638 48.56-8.812 17.686-13.687 37.071-14.29 56.821-.604 19.75 3.079 39.397 10.795 57.587 13.797 33.114 40.118 59.436 73.232 73.233 16.838 7.174 34.952 10.872 53.254 10.872 18.303 0 36.416-3.698 53.254-10.872 33.111-13.801 59.432-40.121 73.233-73.233 7.714-18.191 11.396-37.837 10.792-57.587-.603-19.75-5.477-39.135-14.288-56.821-9.568-19.138-23.486-35.769-40.638-48.56-3.641-2.693-6.695-6.098-8.978-10.009-2.282-3.911-3.745-8.246-4.3-12.74-.713-4.452-.497-9.002.635-13.366s3.155-8.446 5.942-11.989c2.589-3.708 5.939-6.822 9.826-9.132 3.887-2.311 8.222-3.767 12.717-4.27 4.479-.674 9.049-.427 13.43.725s8.48 3.186 12.049 5.976z" />
    </g>
  </svg>
);
