import auth from '../auth';
import { getRefreshToken, getToken } from '../../tools/storage';
import { throwError } from '../index';

export async function privateCall(url, method = 'GET', data = null) {
  const token = getRefreshToken();
  if (auth.isTokenExpired() && token) {
    await auth.authentication(token);
  }
  let params = {
    method: method,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  if (data !== null) {
    if (method === 'POST' || method === 'PUT') {
      params = appendData(params, data);
    } else {
      url = appendQueryParams(url, data);
    }
  }

  return fetch(url, params)
    .then(response => {
      if (response.status !== 200 && response.status !== 400) {
        // TODO Improve dev mode check
        let message = IS_DEV() ? response.body && response.body.message : null;
        throwError(response.status, message);
      }
      return response.json();
    })
    .then(response => {
      if (
        (response.body && response.body.success === 'false') ||
        (response.error && typeof response.error === 'object')
      ) {
        // TODO Improve dev mode check
        let message = IS_DEV() ? (response.body || response.error).message : 'errors.generic';
        let code =
          !response.error || response.error.message ? 0 : response.error && response.error.code;
        throwError(code, message);
      }
      return Promise.resolve(response);
    });
}

function appendQueryParams(url, data = {}) {
  // TODO Hadrien maybe use a more refine lib
  return `${url}?${serialize(data)}`;
}

function serialize(obj, prefix) {
  let str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      );
    }
  }
  return str.join('&');
}

function appendData(reqParams, data = {}) {
  let params = reqParams;
  if (data instanceof FormData) {
    params = { ...reqParams, body: data };
  } else {
    params = { ...reqParams, body: JSON.stringify(data) };
    params.headers['Content-Type'] = 'application/json';
  }
  return params;
}

export function IS_DEV() {
  return /development|local/.test(process.env.REACT_APP_CURRENT);
}
