import { CLEAR_ERROR, REQUEST_ERROR, GETTING_USER, SET_USER_INFO } from '../actions/user';

let initialState = {
  info: {
    name: null,
    company: null,
    contactEmail: null,
    contactFirstname: null,
    contactLastname: null,
    language: null,
  },
  error: '',
  currentlySending: false,
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, info: action.info };
    case GETTING_USER:
      return { ...state, currentlySending: action.sending };
    case REQUEST_ERROR:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
