import {
  SAVE_MIRAKL_OAUTH_ERROR,
  SAVED_MIRAKL_OAUTH,
  SAVING_MIRAKL_OAUTH,
} from '../actions/mirakl';

let initialState = {
  error: '',
  success: false,
  saving: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVED_MIRAKL_OAUTH:
      return { ...state, success: action.success, error: '' };
    case SAVING_MIRAKL_OAUTH:
      return { ...state, saving: action.saving };
    case SAVE_MIRAKL_OAUTH_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export default reducer;
