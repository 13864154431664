// Logos
import AMAZON_LOGO from '../img/marketplace/logo-amazon.png';
import BOL_LOGO from '../img/marketplace/logo-bol.png';
import BOULANGER_LOGO from '../img/marketplace/logo-boulanger.png';
import CDISCOUNT_LOGO from '../img/marketplace/logo-cdiscount.png';
import CONFORAMA_LOGO from '../img/marketplace/logo-conforama.png';
import DARTY_LOGO from '../img/marketplace/logo-darty.png';
import FNAC_LOGO from '../img/marketplace/logo-fnac.png';
import GOOGLE_LOGO from '../img/marketplace/logo-google.png';
import LA_REDOUTE_LOGO from '../img/marketplace/logo-la-redoute.png';
import MAGENTO_LOGO from '../img/marketplace/logo-magento.png';
import MANO_MANO_LOGO from '../img/marketplace/logo-mano-mano.png';
import PIXMANIA_LOGO from '../img/marketplace/logo-pixmania.png';
import PRESTASHOP_LOGO from '../img/marketplace/logo-prestashop.png';
import PRICE_MINISTER_LOGO from '../img/marketplace/logo-priceminister.png';
import RUE_DU_COMMERCE_LOGO from '../img/marketplace/logo-rue-du-commerce.png';
import SHOPIFY_LOGO from '../img/marketplace/logo-shopify.png';
import UBALDI_LOGO from '../img/marketplace/logo-ubaldi.png';
import BACKMARKET_LOGO from '../img/marketplace/logo-backmarket.png';
import CASHEXPRESS_LOGO from '../img/marketplace/logo-cashexpress.png';
import MONECHELLE_LOGO from '../img/marketplace/logo-monechelle.png';
import TWEAKERS_LOGO from '../img/marketplace/logo-tweakers.png';
import LEROYMERLIN_LOGO from '../img/marketplace/logo-leroymerlin.png';
import BUT_LOGO from '../img/marketplace/logo-but.png';
import EPRICE_LOGO from '../img/marketplace/logo-eprice.png';
import WORTEN_LOGO from '../img/marketplace/logo-worten.png';
import CARREFOUR_LOGO from '../img/marketplace/logo-carrefour.png';
import PCCOMPONENTES_LOGO from '../img/marketplace/logo-pccomponentes.png';
import DECATHLON_LOGO from '../img/marketplace/logo-decathlon.png';
import LAPOSTE_LOGO from '../img/marketplace/logo-laposte.png';
import GALERIELAFAYETTE_LOGO from '../img/marketplace/logo-galerielafayette.png';
import MAISONDUMOND_LOGO from '../img/marketplace/logo-maisondumonde.png';
import LECLERC_LOGO from '../img/marketplace/logo-leclerc.png';
import BRICOPRIVE_LOGO from '../img/marketplace/logo-bricoprive.png';
import TRUFFAUT_LOGO from '../img/marketplace/logo-truffaut.png';
import GOSPORT_LOGO from '../img/marketplace/logo-gosport.png';
import AUCHAN_LOGO from '../img/marketplace/logo-auchan.png';
import ANIMALIS_LOGO from '../img/marketplace/logo-animalis.png';
import SHOWROOMPRIVE_LOGO from '../img/marketplace/logo-showroomprive.png';
import INTERMARCHE_LOGO from '../img/marketplace/logo-intermarche.png';
import ATLASFORMEN_LOGO from '../img/marketplace/logo-atlasformen.png';
import NATUREETDECOUVERTE_LOGO from '../img/marketplace/logo-natureetdecouverte.png';
import ALLTRICKS_LOGO from '../img/marketplace/logo-alltricks.png';
import MACWAY_LOGO from '../img/marketplace/logo-macway.png';
import CUSTOM_LOGO from '../img/marketplace/logo-custom.png';
// Logos squared
import AMAZON_LOGO_SQUARED from '../img/marketplace/squared/logo-amazon--squared.png';
import BACKMARKET_LOGO_SQUARED from '../img/marketplace/squared/logo-backmarket--squared.png';
import BOL_LOGO_SQUARED from '../img/marketplace/squared/logo-bol--squared.png';
import BOULANGER_LOGO_SQUARED from '../img/marketplace/squared/logo-boulanger--squared.png';
import CASHEXPRESS_LOGO_SQUARED from '../img/marketplace/squared/logo-cashexpress--squared.png';
import CDISCOUNT_LOGO_SQUARED from '../img/marketplace/squared/logo-cdiscount--squared.png';
import CONFORAMA_LOGO_SQUARED from '../img/marketplace/squared/logo-conforama--squared.png';
import DARTY_LOGO_SQUARED from '../img/marketplace/squared/logo-darty--squared.png';
import FNAC_LOGO_SQUARED from '../img/marketplace/squared/logo-fnac--squared.png';
import GOOGLE_LOGO_SQUARED from '../img/marketplace/squared/logo-google--squared.png';
import LA_REDOUTE_LOGO_SQUARED from '../img/marketplace/squared/logo-laredoute--squared.png';
import MAGENTO_LOGO_SQUARED from '../img/marketplace/squared/logo-magento--squared.png';
import MANO_MANO_LOGO_SQUARED from '../img/marketplace/squared/logo-manomano--squared.png';
import MONECHELLE_LOGO_SQUARED from '../img/marketplace/squared/logo-monechelle--squared.png';
import PIXMANIA_LOGO_SQUARED from '../img/marketplace/squared/logo-pixmania--squared.png';
import PRESTASHOP_LOGO_SQUARED from '../img/marketplace/squared/logo-prestashop--squared.png';
import PRICE_MINISTER_LOGO_SQUARED from '../img/marketplace/squared/logo-rakuten--squared.png';
import RUE_DU_COMMERCE_LOGO_SQUARED from '../img/marketplace/squared/logo-rueducommerce--squared.png';
import SHOPIFY_LOGO_SQUARED from '../img/marketplace/squared/logo-shopify--squared.png';
import UBALDI_LOGO_SQUARED from '../img/marketplace/squared/logo-ubaldi--squared.png';
import TWEAKERS_LOGO_SQUARED from '../img/marketplace/squared/logo-tweakers--squared.png';
import LEROYMERLIN_LOGO_SQUARED from '../img/marketplace/squared/logo-leroymerlin--squared.png';
import BUT_LOGO_SQUARED from '../img/marketplace/squared/logo-but--squared.png';
import EPRICE_LOGO_SQUARED from '../img/marketplace/squared/logo-eprice--squared.png';
import WORTEN_LOGO_SQUARED from '../img/marketplace/squared/logo-worten--squared.png';
import CARREFOUR_LOGO_SQUARED from '../img/marketplace/squared/logo-carrefour--squared.png';
import PCCOMPONENTES_LOGO_SQUARED from '../img/marketplace/squared/logo-pccomponentes--squared.png';
import DECATHLON_LOGO_SQUARED from '../img/marketplace/squared/logo-decathlon--squared.png';
import LAPOSTE_LOGO_SQUARED from '../img/marketplace/squared/logo-laposte--squared.png';
import GALERIELAFAYETTE_LOGO_SQUARED from '../img/marketplace/squared/logo-galerielafayette--squared.png';
import MAISONDUMOND_LOGO_SQUARED from '../img/marketplace/squared/logo-maisondumonde--squared.png';
import LECLERC_LOGO_SQUARED from '../img/marketplace/squared/logo-leclerc--squared.png';
import BRICOPRIVE_LOGO_SQUARED from '../img/marketplace/squared/logo-bricoprive--squared.png';
import TRUFFAUT_LOGO_SQUARED from '../img/marketplace/squared/logo-truffaut--squared.png';
import GOSPORT_LOGO_SQUARED from '../img/marketplace/squared/logo-gosport--squared.png';
import AUCHAN_LOGO_SQUARED from '../img/marketplace/squared/logo-auchan--squared.png';
import ANIMALIS_LOGO_SQUARED from '../img/marketplace/squared/logo-animalis--squared.png';
import SHOWROOMPRIVE_LOGO_SQUARED from '../img/marketplace/squared/logo-showroomprive--squared.png';
import INTERMARCHE_LOGO_SQUARED from '../img/marketplace/squared/logo-intermarche--squared.png';
import ATLASFORMEN_LOGO_SQUARED from '../img/marketplace/squared/logo-atlasformen--squared.png';
import NATUREETDECOUVERTE_LOGO_SQUARED from '../img/marketplace/squared/logo-natureetdecouverte--squared.png';
import ALLTRICKS_LOGO_SQUARED from '../img/marketplace/squared/logo-alltricks--squared.png';
import MACWAY_LOGO_SQUARED from '../img/marketplace/squared/logo-macway--squared.png';
import CUSTOM_LOGO_SQUARED from '../img/marketplace/squared/logo-custom--squared.png';
import ALL_MARKETPLACE from '../img/marketplace/squared/all-market.svg';
// Country flags
import ALL from '../img/flag/globe.svg';
import AU from '../img/flag/australia.svg';
import EU from '../img/flag/european-union.svg';
import FR from '../img/flag/france.svg';
import DE from '../img/flag/germany.svg';
import IT from '../img/flag/italy.svg';
import ES from '../img/flag/spain.svg';
import UK from '../img/flag/united-kingdom.svg';
import US from '../img/flag/united-states.svg';
import NL from '../img/flag/netherlands.svg';
import BE from '../img/flag/belgium.svg';
import DK from '../img/flag/denmark.svg';
import NO from '../img/flag/norway.svg';

export default {
  getLogo(name = '', squared) {
    switch (name) {
      case (name.match(/^amazon/) || {}).input:
        return squared ? AMAZON_LOGO_SQUARED : AMAZON_LOGO;
      case (name.match(/^bol/) || {}).input:
        return squared ? BOL_LOGO_SQUARED : BOL_LOGO;
      case (name.match(/^boulanger/) || {}).input:
        return squared ? BOULANGER_LOGO_SQUARED : BOULANGER_LOGO;
      case (name.match(/^cdiscount/) || {}).input:
        return squared ? CDISCOUNT_LOGO_SQUARED : CDISCOUNT_LOGO;
      case (name.match(/^conforama/) || {}).input:
        return squared ? CONFORAMA_LOGO_SQUARED : CONFORAMA_LOGO;
      case (name.match(/^darty/) || {}).input:
        return squared ? DARTY_LOGO_SQUARED : DARTY_LOGO;
      case (name.match(/^fnac/) || {}).input:
        return squared ? FNAC_LOGO_SQUARED : FNAC_LOGO;
      case (name.match(/^google/) || {}).input:
        return squared ? GOOGLE_LOGO_SQUARED : GOOGLE_LOGO;
      case (name.match(/^la-?redoute/) || {}).input:
        return squared ? LA_REDOUTE_LOGO_SQUARED : LA_REDOUTE_LOGO;
      case (name.match(/^magento/) || {}).input:
        return squared ? MAGENTO_LOGO_SQUARED : MAGENTO_LOGO;
      case (name.match(/^manomano/) || {}).input:
        return squared ? MANO_MANO_LOGO_SQUARED : MANO_MANO_LOGO;
      case (name.match(/^pixmania/) || {}).input:
        return squared ? PIXMANIA_LOGO_SQUARED : PIXMANIA_LOGO;
      case (name.match(/^prestashop/) || {}).input:
        return squared ? PRESTASHOP_LOGO_SQUARED : PRESTASHOP_LOGO;
      case (name.match(/^priceminister/) || {}).input:
        return squared ? PRICE_MINISTER_LOGO_SQUARED : PRICE_MINISTER_LOGO;
      case (name.match(/^rue-?du-?commerce/) || {}).input:
        return squared ? RUE_DU_COMMERCE_LOGO_SQUARED : RUE_DU_COMMERCE_LOGO;
      case (name.match(/^shopify/) || {}).input:
        return squared ? SHOPIFY_LOGO_SQUARED : SHOPIFY_LOGO;
      case (name.match(/^ubaldi/) || {}).input:
        return squared ? UBALDI_LOGO_SQUARED : UBALDI_LOGO;
      case (name.match(/^backmarket/) || {}).input:
        return squared ? BACKMARKET_LOGO_SQUARED : BACKMARKET_LOGO;
      case (name.match(/^cash-?express/) || {}).input:
        return squared ? CASHEXPRESS_LOGO_SQUARED : CASHEXPRESS_LOGO;
      case (name.match(/^mon-?echelle/) || {}).input:
        return squared ? MONECHELLE_LOGO_SQUARED : MONECHELLE_LOGO;
      case (name.match(/^tweakers/) || {}).input:
        return squared ? TWEAKERS_LOGO_SQUARED : TWEAKERS_LOGO;
      case (name.match(/^leroymerlin/) || {}).input:
        return squared ? LEROYMERLIN_LOGO_SQUARED : LEROYMERLIN_LOGO;
      case (name.match(/^but/) || {}).input:
        return squared ? BUT_LOGO_SQUARED : BUT_LOGO;
      case (name.match(/^eprice/) || {}).input:
        return squared ? EPRICE_LOGO_SQUARED : EPRICE_LOGO;
      case (name.match(/^worten/) || {}).input:
        return squared ? WORTEN_LOGO_SQUARED : WORTEN_LOGO;
      case (name.match(/^carrefour/) || {}).input:
        return squared ? CARREFOUR_LOGO_SQUARED : CARREFOUR_LOGO;
      case (name.match(/^pccomponentes/) || {}).input:
        return squared ? PCCOMPONENTES_LOGO_SQUARED : PCCOMPONENTES_LOGO;
      case (name.match(/^decathlon/) || {}).input:
        return squared ? DECATHLON_LOGO_SQUARED : DECATHLON_LOGO;
      case (name.match(/^laposte/) || {}).input:
        return squared ? LAPOSTE_LOGO_SQUARED : LAPOSTE_LOGO;
      case (name.match(/^galerielafayette/) || {}).input:
        return squared ? GALERIELAFAYETTE_LOGO_SQUARED : GALERIELAFAYETTE_LOGO;
      case (name.match(/^maisondumonde/) || {}).input:
        return squared ? MAISONDUMOND_LOGO_SQUARED : MAISONDUMOND_LOGO;
      case (name.match(/^leclerc/) || {}).input:
        return squared ? LECLERC_LOGO_SQUARED : LECLERC_LOGO;
      case (name.match(/^bricoprive/) || {}).input:
        return squared ? BRICOPRIVE_LOGO_SQUARED : BRICOPRIVE_LOGO;
      case (name.match(/^truffaut/) || {}).input:
        return squared ? TRUFFAUT_LOGO_SQUARED : TRUFFAUT_LOGO;
      case (name.match(/^gosport/) || {}).input:
        return squared ? GOSPORT_LOGO_SQUARED : GOSPORT_LOGO;
      case (name.match(/^auchan/) || {}).input:
        return squared ? AUCHAN_LOGO_SQUARED : AUCHAN_LOGO;
      case (name.match(/^animalis/) || {}).input:
        return squared ? ANIMALIS_LOGO_SQUARED : ANIMALIS_LOGO;
      case (name.match(/^showroomprive/) || {}).input:
        return squared ? SHOWROOMPRIVE_LOGO_SQUARED : SHOWROOMPRIVE_LOGO;
      case (name.match(/^intermarche/) || {}).input:
        return squared ? INTERMARCHE_LOGO_SQUARED : INTERMARCHE_LOGO;
      case (name.match(/^atlasformen/) || {}).input:
        return squared ? ATLASFORMEN_LOGO_SQUARED : ATLASFORMEN_LOGO;
      case (name.match(/^natureetdecouverte/) || {}).input:
        return squared ? NATUREETDECOUVERTE_LOGO_SQUARED : NATUREETDECOUVERTE_LOGO;
      case (name.match(/^alltricks/) || {}).input:
        return squared ? ALLTRICKS_LOGO_SQUARED : ALLTRICKS_LOGO;
      case (name.match(/^macway/) || {}).input:
        return squared ? MACWAY_LOGO_SQUARED : MACWAY_LOGO;
      case 'all':
        return ALL_MARKETPLACE;
      default:
        return squared ? CUSTOM_LOGO_SQUARED : CUSTOM_LOGO;
    }
  },
  getFlag(country) {
    switch (country) {
      case 'au':
        return AU;
      case 'eu':
        return EU;
      case 'fr':
        return FR;
      case 'de':
        return DE;
      case 'it':
        return IT;
      case 'es':
        return ES;
      case 'uk':
        return UK;
      case 'us':
        return US;
      case 'nl':
        return NL;
      case 'be':
        return BE;
      case 'dk':
        return DK;
      case 'no':
        return NO;
      default:
        return ALL;
    }
  },
};
