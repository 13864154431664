export const GETTING_CATALOG_MAPPING = 'GETTING_CATALOG_MAPPING';
export const CLEAR_CATALOG_MAPPING_ERROR = 'CLEAR_CATALOG_MAPPING_ERROR';
export const CATALOG_MAPPING_ERROR = 'CATALOG_MAPPING_ERROR';

export const GET_CATALOG_MAPPING = 'GET_CATALOG_MAPPING';
export const SET_CATALOG_MAPPING = 'SET_CATALOG_MAPPING';
export const POST_CATALOG_MAPPING = 'POST_CATALOG_MAPPING';
export const POSTING_CATALOG_MAPPING = 'POSTING_CATALOG_MAPPING';
export const DONE_CATALOG_MAPPING = 'DONE_CATALOG_MAPPING';

export const getCatalogMapping = connector => {
  return { type: GET_CATALOG_MAPPING, connector };
};

export const postCatalogMapping = mapping => {
  return { type: POST_CATALOG_MAPPING, mapping };
};
