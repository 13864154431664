export const GETTING_ALL_CHANNELS = 'GETTING_ALL_CHANNELS';
export const ALL_CHANNELS_ERROR = 'ALL_CHANNELS_ERROR';
export const CLEAR_ALL_CHANNELS_ERROR = 'CLEAR_ALL_CHANNELS_ERROR';

export const GET_ALL_CHANNELS = 'GET_ALL_CHANNELS';
export const SET_ALL_CHANNELS = 'SET_ALL_CHANNELS';

export const getAllChannels = () => {
  return { type: GET_ALL_CHANNELS };
};
