import { call, put, take } from 'redux-saga/effects';

import { GET_REPORTS, GETTING_REPORTS, SET_REPORTS, SET_REPORTS_ERROR } from '../actions/reports';
import ReportsApi from '../api/reports.api';

export function* getReports() {
  while (true) {
    yield take(GET_REPORTS);
    yield put({ type: GETTING_REPORTS, getting: true });

    try {
      const reports = yield call(ReportsApi.getReports);
      if (reports) {
        yield put({ type: SET_REPORTS, reports });
      }
    } catch (error) {
      yield put({ type: SET_REPORTS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_REPORTS, getting: false });
    }
  }
}
