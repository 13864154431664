import { API_BASE_URL, API_BASE_URL_V2 } from '../tools/constants';
import { getCurrentDate, getLastMonthDate } from '../tools/date';

import { privateCall } from './tools/privateCall';
import { Channel } from './model/channel.model';
import { Product } from './model/product.model';

export const API_GET_CHANNELS = API_BASE_URL + 'monitoring/channel/subscribed';
export const API_GET_ALL_CHANNELS = API_BASE_URL + 'monitoring/channel/all';
export const API_SIMULATE_PRICING = API_BASE_URL + 'pricer/pricing/simulate';
const API_GET_CHANNEL_CONFIGURATION_STATUS = API_BASE_URL_V2 + 'client-data/channel/status';
export const API_GET_CHANNEL_OFFERS = API_BASE_URL_V2 + 'pricer/products';
export const API_GET_CHANNEL_PRODUCT_INFORMATION =
  API_BASE_URL_V2 + 'channel-data/product/information';
export const API_GET_CHANNEL_PRODUCT_BEHAVIOR = API_BASE_URL_V2 + 'pricer/pricing/product';
export const API_GET_CHANNEL_PRODUCT_OFFERS = API_BASE_URL_V2 + 'channel-data/product/offers';
export const API_GET_CHANNEL_MATCHING_STATUS = API_BASE_URL_V2 + 'pricer/pricing/stats-by-status';
export const API_GET_MARGIN_INFO = API_BASE_URL_V2 + 'pricer/pricing/margin-by-date';
export const API_GET_CHANNEL_COMPETITORS_STATS =
  API_BASE_URL_V2 + 'client-data/channel/competitors-stats';
export const API_GET_COMPETITORS_STATS = API_BASE_URL_V2 + 'client-data/competitors-stats';
export const API_GET_CHANNEL_POSITION_BY_DATE =
  API_BASE_URL_V2 + 'client-data/statistics/last-days';
export const API_GET_CHANNEL_STATUS_BY_DATE =
  API_BASE_URL_V2 + 'client-data/statistics/pricing-status-by-date';
export const API_GET_OFFERS_HISTORY = API_BASE_URL_V2 + 'channel-data/offers-history';
export const API_GET_PRICING_HISTORY = API_BASE_URL + 'pricer/pricing/product-history';
export const API_SET_PRODUCTS_BEHAVIOR = API_BASE_URL + 'pricer/rule/product/save';

const ChannelsApi = {
  async getChannels() {
    const response = await privateCall(API_GET_CHANNELS, 'GET');
    return Channel.asList(response.body);
  },

  async getAllChannels() {
    const response = await privateCall(API_GET_ALL_CHANNELS, 'GET');
    return Channel.asList(response.body);
  },

  async getChannelConfigurationStatus(channel) {
    return await privateCall(API_GET_CHANNEL_CONFIGURATION_STATUS, 'GET', channel);
  },

  async getChannelOffers(data) {
    const filters = data.filtersLeft.concat(data.filtersRight);
    data.channel && filters.push({ key: 'channel', value: data.channel });
    const params = filters.reduce((acc, it) => {
      acc[`filters[${it.key}]`] = it.value;
      return acc;
    }, {});

    if (data.sorts) {
      data.sorts.reduce((acc, it) => {
        acc[`sort[${it.id}]`] = it.desc ? 'desc' : 'asc';
        return acc;
      }, params);
    }

    params.limit = data.limit || 20;
    params.page = data.page || 1; // page are 1 based
    params.dto = 'product_list';
    try {
      const response = await privateCall(API_GET_CHANNEL_OFFERS, 'GET', params);
      return { products: Product.asList(response.products || []), total: response.total };
    } catch (e) {
      return { products: [], total: 0 };
    }
  },

  async getChannelProductInformation(channelReference, channelId) {
    return await privateCall(API_GET_CHANNEL_PRODUCT_INFORMATION, 'GET', {
      channel: channelId,
      reference: channelReference,
    });
  },

  async getChannelProductBehavior(productId, channelId) {
    const res = await privateCall(API_GET_CHANNEL_PRODUCT_BEHAVIOR, 'GET', {
      channel: channelId,
      seller_sku: productId,
    });
    return res ? res.behavior : '';
  },

  async getProductOffers(channelReference, channelId) {
    return await privateCall(API_GET_CHANNEL_PRODUCT_OFFERS, 'GET', {
      channel: channelId,
      reference: channelReference,
    });
  },

  async getChannelMatchingStatus(channel) {
    const params = { channel };
    return await privateCall(API_GET_CHANNEL_MATCHING_STATUS, 'GET', { ...params });
  },

  async getChannelCompetitorsStats(channel) {
    let endpoint = API_GET_COMPETITORS_STATS;
    if (channel) {
      var params = { channel };
      endpoint = API_GET_CHANNEL_COMPETITORS_STATS;
    }
    return await privateCall(endpoint, 'GET', params);
  },

  async simulatePricing(productId, channelId, behavior) {
    const params = {
      channel: channelId,
      product_id: productId,
    };
    if (behavior) {
      params['rules[behavior]'] = behavior;
    }

    const response = await privateCall(API_SIMULATE_PRICING, 'GET', params);
    return response.body;
  },

  async getChannelStatusByDate(
    channel,
    from = getLastMonthDate(),
    to = getCurrentDate(),
    interval = 'day'
  ) {
    const params = {
      days: 31,
      interval,
    };
    if (channel) {
      params.channel = channel.replace('_default', '');
    }
    return await privateCall(API_GET_CHANNEL_STATUS_BY_DATE, 'GET', { ...params });
  },

  async getChannelPositionByDate(channel = '*', days = 31) {
    const params = {
      field: 'pricer.global.' + channel.replace('_default', '') + '.position.*.total',
      days,
    };

    return await privateCall(API_GET_CHANNEL_POSITION_BY_DATE, 'GET', { ...params });
  },

  async getChannelTargetPositionByDate(channel = '*', days = 31) {
    const params = {
      field: 'pricer.global.' + channel.replace('_default', '') + '.target_position.*.total',
      days,
    };

    return await privateCall(API_GET_CHANNEL_POSITION_BY_DATE, 'GET', { ...params });
  },

  async getChannelMarginInfo(
    channel,
    from = getLastMonthDate(),
    to = getCurrentDate(),
    interval = 'day'
  ) {
    const params = {
      from,
      to,
      interval,
    };
    if (channel) {
      params.channel = channel;
    }
    const margin = await privateCall(API_GET_MARGIN_INFO, 'GET', { ...params });

    if (margin && margin['margin-avg']) {
      margin['margin-avg'] = Math.round(margin['margin-avg']);
    }

    return margin;
  },

  async getOffersHistory(channelId, reference, from = getLastMonthDate(), to = getCurrentDate()) {
    const offersHistory = await privateCall(API_GET_OFFERS_HISTORY, 'GET', {
      channel: channelId,
      reference,
      from,
      to,
      size: 1000,
    });

    //todo: see if we can trim the result to only keep the relevant data
    //api desc sort dates by default, we asc sort ourselves to make sure we always get the current date in the results, no matter the limit
    return offersHistory.reverse();
  },

  async getPricingHistory(channelId, reference, limit = 10) {
    const pricingHistory = await privateCall(API_GET_PRICING_HISTORY, 'GET', {
      channel: channelId,
      product_id: reference,
      limit,
    });

    return pricingHistory.body;
  },

  /**
   *
   * @param products array product ids
   * @param behavior string behavior v1
   * @param channel string channel id
   */
  async setProductsBehavior(products, behavior, channel) {
    let fd = new FormData();

    fd.append('type', 'behavior');
    fd.append('behavior', behavior);
    fd.append('channel', channel);
    products.forEach((productId, idx) => {
      fd.append('productIds[' + idx + ']', productId);
    });

    const response = await privateCall(API_SET_PRODUCTS_BEHAVIOR, 'POST', fd);

    return response.body;
  },

  async sendSubscriptionMail(channelId) {
    //todo (Robin): replace with real call once created
    return await new Promise(resolve =>
      setTimeout(() => resolve({ channelId, error: false }), 1000)
    );
  },
};

export default ChannelsApi;
