import {
  CLEAR_CHANNELS_PRICING_IMPORT_ERROR,
  DONE_CHANNELS_PRICING_IMPORTS,
  GETTING_CHANNELS_PRICING_IMPORT,
  POSTING_CHANNELS_PRICING_IMPORT,
  SET_CHANNELS_PRICING_IMPORT,
} from '../actions/channelsPricingImport';

let initialState = {
  configs: {},
  done: false,
  getting: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNELS_PRICING_IMPORT:
      return { ...state, configs: action.imports };
    case POSTING_CHANNELS_PRICING_IMPORT:
      return { ...state, posting: action.posting };
    case GETTING_CHANNELS_PRICING_IMPORT:
      return { ...state, getting: action.getting };
    case DONE_CHANNELS_PRICING_IMPORTS:
      return { ...state, done: action.done };
    case CLEAR_CHANNELS_PRICING_IMPORT_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
