export const SETTING_BEHAVIOR = 'SETTING_BEHAVIOR';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_PRODUCTS_BEHAVIOR = 'SET_PRODUCTS_BEHAVIOR';

export const setProductsBehavior = (products, behavior, channels) => {
  return { type: SET_PRODUCTS_BEHAVIOR, products, behavior, channels };
};

export const clearError = () => {
  return { type: CLEAR_ERROR };
};
