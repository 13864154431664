import {
  SEND_CHANNELS_SUBSCRIPTION_ERROR,
  SENDING_CHANNELS_SUBSCRIPTION_MAIL,
  CLEAR_CHANNELS_SUBSCRIPTION_ERROR,
  SET_CHANNELS_SUBSCRIPTION_MAIL_SENT,
} from '../actions/channelsSubscription';

let initialState = {
  sent: [],
  error: '',
  sending: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SENDING_CHANNELS_SUBSCRIPTION_MAIL:
      if (action.sending) {
        return { ...state, sending: [...state.sending, action.channelId] };
      }
      return {
        ...state,
        sending: [...state.sending].filter(channelId => channelId !== action.channelId),
      };
    case SET_CHANNELS_SUBSCRIPTION_MAIL_SENT:
      return { ...state, sent: [...state.sent, action.channelId] };
    case SEND_CHANNELS_SUBSCRIPTION_ERROR:
      return { ...state, error: action.error };
    case CLEAR_CHANNELS_SUBSCRIPTION_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
