import {
  DELETE_CACHE_VALUE_ERROR,
  DELETING_CACHE_VALUE,
  GET_CACHE_VALUE_ERROR,
  GETTING_CACHE_VALUE,
  SET_CACHE_VALUE,
  UPDATE_CACHE_VALUE_ERROR,
  UPDATING_CACHE_VALUE,
} from '../actions/cache';

let initialState = {
  trackedProducts: [],
  gettingCacheValue: false,
  updatingCacheValue: false,
  deletingCacheValue: false,
  getCacheValueError: '',
  updateCacheValueError: '',
  deleteCacheValueError: '',
};

export function cache(state = initialState, action) {
  const { key, value, processing, error, type } = action;
  switch (type) {
    case SET_CACHE_VALUE:
      return { ...state, [key]: value };
    case GETTING_CACHE_VALUE:
      return { ...state, gettingCacheValue: processing };
    case GET_CACHE_VALUE_ERROR:
      return { ...state, getCacheValueError: error };
    case UPDATING_CACHE_VALUE:
      return { ...state, updatingCacheValue: processing };
    case UPDATE_CACHE_VALUE_ERROR:
      return { ...state, updateCacheValueError: error };
    case DELETING_CACHE_VALUE:
      return { ...state, deletingCacheValue: processing };
    case DELETE_CACHE_VALUE_ERROR:
      return { ...state, deleteCacheValueError: error };
    default:
      return state;
  }
}
