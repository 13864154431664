import { call, put, take } from 'redux-saga/effects';

import CarrierApi from '../api/carriers';
import {
  DELETE_CARRIER,
  DELETING_CARRIER,
  GET_CARRIER,
  GETTING_CARRIER,
  GET_CARRIERS,
  GETTING_CARRIERS,
  SET_CARRIER_GRID,
  SET_CARRIERS,
  SET_CARRIERS_ERROR,
  SET_DELETE_CARRIER_ERROR,
  SET_UPDATE_CARRIER_ERROR,
  UPDATE_CARRIER,
  UPDATING_CARRIER,
} from '../actions/carriers';

export function* getCarriers() {
  while (true) {
    yield take(GET_CARRIERS);
    yield put({ type: GETTING_CARRIERS, gettingCarriers: true });
    let result = [];
    try {
      result = yield call(CarrierApi.getCarriers);
    } catch (error) {
      yield put({ type: SET_CARRIERS_ERROR, carriersError: error });
      yield put({ type: SET_CARRIERS, carriers: {} });
    }
    for (let i = 0; i < (result || []).length; i++) {
      yield put({ type: SET_CARRIER_GRID, carrierName: result[i], carrierId: i, carrierGrid: [] });
    }
    yield put({ type: GETTING_CARRIERS, gettingCarriers: false });
  }
}

export function* getCarrier() {
  while (true) {
    const request = yield take(GET_CARRIER);
    const { carrierName, carrierId } = request;
    yield put({ type: GETTING_CARRIER, gettingCarrier: true });
    try {
      const result = yield call(CarrierApi.getCarriersGrid, carrierName);
      yield put({ type: SET_CARRIER_GRID, carrierName, carrierId, carrierGrid: result });
    } catch (error) {
      yield put({ type: SET_CARRIER_GRID, carrierName, carrierId, carrierGrid: [] });
    }
    yield put({ type: GETTING_CARRIER, gettingCarrier: false });
  }
}

export function* updateCarrier() {
  while (true) {
    const request = yield take(UPDATE_CARRIER);
    yield put({ type: SET_UPDATE_CARRIER_ERROR, updateCarrierError: '' });
    yield put({ type: UPDATING_CARRIER, updatingCarrier: true });
    try {
      yield call(CarrierApi.saveCarrierGrid, request.carrier);
    } catch (error) {
      yield put({ type: SET_UPDATE_CARRIER_ERROR, updateCarrierError: error });
    } finally {
      yield put({ type: UPDATING_CARRIER, updatingCarrier: false });
    }
  }
}

export function* deleteCarrier() {
  while (true) {
    const request = yield take(DELETE_CARRIER);
    yield put({ type: SET_DELETE_CARRIER_ERROR, deleteCarrierError: '' });
    yield put({ type: DELETING_CARRIER, updatingCarrier: true });
    try {
      yield call(CarrierApi.deleteCarrier, request.carrierName);
    } catch (error) {
      yield put({ type: SET_DELETE_CARRIER_ERROR, deleteCarrierError: error });
    } finally {
      yield put({ type: DELETING_CARRIER, updatingCarrier: false });
    }
  }
}
