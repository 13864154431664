import { API_BASE_URL } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const GET_PROGRESS = `${API_BASE_URL}pricer/pricing/in-progress/`;

const RepricingApi = {
  async getProgress() {
    const response = await privateCall(GET_PROGRESS, 'GET');
    return response.body;
  },
};

export default RepricingApi;
