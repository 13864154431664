import {
  GETTING_CHANNELS_CONFIGURATION_STATUS,
  SET_CHANNELS_CONFIGURATION_STATUS,
} from '../actions/channelsConfiguration';

let initialState = {
  statuses: [],
  getting: false,
};

export function channelConfig(state = initialState, action) {
  switch (action.type) {
    case GETTING_CHANNELS_CONFIGURATION_STATUS:
      return { ...state, getting: action.getting };
    case SET_CHANNELS_CONFIGURATION_STATUS:
      return { ...state, statuses: action.statuses };
    default:
      return state;
  }
}
