import { combineReducers } from 'redux';

import authReducer from './auth';
import sidersReducer from './siders';
import userReducer from './user';
import catalogReducer from './catalog';
import channelsReducer from './channels';
import allChannelsReducer from './allChannels';
import getChannelsPricingImport from './channelsPricingImport';
import catalogMapping from './catalogMapping';
import { channel } from './channel';
import { channelStats } from './channelStats';
import { dashboardStats } from './dashboardStats';
import catalogConfigure from './catalogConfigure';
import { channelConfig } from './channelConfig';
import channelsSubscriptionReducer from './channelsSubscription';
import behavior from './behavior';
import { strategy } from './strategy';
import { channelProduct } from './channelProduct';
import { carriers } from './carriers';
import { cache } from './cache';
import feedManager from './feedManager';
import { repricing } from './repricing';
import catalogHistory from './catalogHistory';
import reports from './reports';
import mirakl from './mirakl';

export default combineReducers({
  auth: authReducer,
  siders: sidersReducer,
  user: userReducer,
  catalog: catalogReducer,
  catalogConfigure: catalogConfigure,
  channels: channelsReducer,
  allChannels: allChannelsReducer,
  channelsPricingImport: getChannelsPricingImport,
  catalogMapping: catalogMapping,
  channel,
  channelConfig,
  channelsSubscription: channelsSubscriptionReducer,
  channelStats,
  dashboardStats,
  channelProduct,
  behavior,
  strategy,
  carriers,
  cache,
  feedManager,
  reports,
  repricing,
  catalogHistory,
  mirakl,
});
