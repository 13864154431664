import { call, put, take } from 'redux-saga/effects';

import CatalogConfigApi from '../api/catalogConfig.api';
import {
  CATALOG_MAPPING_ERROR,
  DONE_CATALOG_MAPPING,
  GET_CATALOG_MAPPING,
  GETTING_CATALOG_MAPPING,
  POST_CATALOG_MAPPING,
  POSTING_CATALOG_MAPPING,
  SET_CATALOG_MAPPING,
} from '../actions/catalogMapping';

export function* getCatalogMapping() {
  while (true) {
    const request = yield take(GET_CATALOG_MAPPING);

    yield put({ type: GETTING_CATALOG_MAPPING, getting: true });

    try {
      let res = yield call(CatalogConfigApi.getCatalogMapping, { connector: request.connector });
      const mapping = Object.keys(res.mapping).map(key => ({
        key: key,
        label: res.mapping[key].name,
        value: res.mapping[key].value,
        sample: getSample(res, key),
      }));
      const attributes = res.attributes.sort();
      yield put({ type: SET_CATALOG_MAPPING, mapping, attributes });
      yield put({ type: DONE_CATALOG_MAPPING, done: false });
    } catch (error) {
      yield put({ type: SET_CATALOG_MAPPING, mapping: { error } });
    } finally {
      yield put({ type: GETTING_CATALOG_MAPPING, getting: false });
    }
  }

  function getSample(res, key) {
    const samples = res.samples[res.mapping[key].value] || [];
    return samples.filter(value => !!value)[0] || '';
  }
}

export function* postCatalogMapping() {
  while (true) {
    const request = yield take(POST_CATALOG_MAPPING);
    yield put({ type: POSTING_CATALOG_MAPPING, posting: true });
    try {
      yield call(CatalogConfigApi.saveCatalogMapping, request.mapping);
      yield put({ type: DONE_CATALOG_MAPPING, done: true });
    } catch (error) {
      yield put({ type: CATALOG_MAPPING_ERROR, error });
    } finally {
      yield put({ type: POSTING_CATALOG_MAPPING, posting: false });
    }
  }
}
