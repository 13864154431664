import {
  DELETING_CARRIER,
  GETTING_CARRIER,
  GETTING_CARRIERS,
  SET_CARRIER_GRID,
  SET_CARRIERS,
  SET_CARRIERS_ERROR,
  SET_DELETE_CARRIER_ERROR,
  SET_UPDATE_CARRIER_ERROR,
  UPDATING_CARRIER,
} from '../actions/carriers';

let initialState = {
  carriers: {},
  gettingCarriers: false,
  updatingCarrier: false,
  deletingCarrier: false,
  carriersError: '',
  updateCarrierError: '',
  deleteCarrierError: '',
};

export function carriers(state = initialState, action) {
  const {
    type,
    carriers,
    carrierId,
    carrierName,
    carrierGrid,
    gettingCarrier,
    gettingCarriers,
    updatingCarrier,
    deletingCarrier,
    carriersError,
    updateCarrierError,
    deleteCarrierError,
  } = action;

  switch (type) {
    case SET_CARRIERS:
      return { ...state, carriers };
    case SET_CARRIER_GRID:
      return {
        ...state,
        carriers: { ...state.carriers, [carrierId]: { name: carrierName, grid: carrierGrid } },
      };
    case GETTING_CARRIER:
      return { ...state, gettingCarrier };
    case GETTING_CARRIERS:
      return { ...state, gettingCarriers };
    case SET_CARRIERS_ERROR:
      return { ...state, carriersError: carriersError };
    case UPDATING_CARRIER:
      return { ...state, updatingCarrier };
    case DELETING_CARRIER:
      return { ...state, deletingCarrier };
    case SET_UPDATE_CARRIER_ERROR:
      return { ...state, updateCarrierError };
    case SET_DELETE_CARRIER_ERROR:
      return { ...state, deleteCarrierError };
    default:
      return state;
  }
}
