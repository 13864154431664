import { API_BASE_URL_V2 } from '../tools/constants';

import { IS_DEV, privateCall } from './tools/privateCall';

export const API_MIRAKL_OAUTH_CODE_URL = () => `${API_BASE_URL_V2}channel-data/oauth/mirakl/token`;

const Mirakl = {
  saveMiraklOAuthCode(code, channel) {
    // Mirakl dev env does not properly work for redirection on our backend dev env, it triggers a 500 on redirect.
    // So we need to specify the env to use : sandbox or prod
    return privateCall(API_MIRAKL_OAUTH_CODE_URL(), 'POST', {
      code,
      channel,
      use_sandbox: IS_DEV(),
    });
  },
};

export default Mirakl;
