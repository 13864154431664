import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const API_GET_CHANNELS_SELLERS = API_BASE_URL_V2 + 'channel-data/seller/suggest';
export const API_GET_SELLER_INFORMATION = API_BASE_URL_V2 + 'channel-data/seller/information';
export const API_SELLER_REFERENCE = API_BASE_URL_V2 + 'channel-data/seller-api/reference';
export const API_SELLER_CREDENTIALS = API_BASE_URL_V2 + 'channel-data/seller-api/credentials';

const ChannelsSellerApi = {
  async getSuggestions(channel, query, limit = 10) {
    const params = { query, limit };
    if (channel) {
      params.channel = channel;
    }
    return await privateCall(API_GET_CHANNELS_SELLERS, 'GET', params);
  },

  async getSellerInformation(channel, sellerReferences) {
    const fd = new FormData();
    fd.append('channel', channel);
    sellerReferences.forEach(sellerReference => {
      fd.append('references[]', sellerReference);
    });
    return await privateCall(API_GET_SELLER_INFORMATION, 'POST', fd);
  },

  async getSellerReference(data) {
    const response = await privateCall(API_SELLER_REFERENCE, 'GET', data);
    return response.reference;
  },

  async saveSellerReference(data) {
    let fd = new FormData();
    fd.append('channel', data.channel);
    fd.append('reference', data.reference);
    const response = await privateCall(API_SELLER_REFERENCE, 'POST', fd);
    return response;
  },

  async getApiCredentials(channel) {
    const credentials = await privateCall(API_SELLER_CREDENTIALS, 'GET', { channel });
    return Object.keys(credentials).length === 0 ? {} : credentials;
  },

  async saveApiCredentials(data) {
    const response = await privateCall(API_SELLER_CREDENTIALS, 'POST', data);
    return response;
  },
};

export default ChannelsSellerApi;
