export const GET_FEED_OPTIONS = 'GET_FEED_OPTIONS';
export const SET_FEED_OPTIONS = 'SET_FEED_OPTIONS';
export const GETTING_FEED_OPTIONS = 'GETTING_FEED_OPTIONS';
export const SET_FEED_OPTIONS_ERROR = 'SET_FEED_OPTIONS_ERROR';

export const GET_FEED = 'GET_FEED';
export const SET_FEED = 'SET_FEED';
export const GETTING_FEED = 'GETTING_FEED';
export const SET_FEED_ERROR = 'SET_FEED_ERROR';

export const UPDATE_FEED = 'UPDATE_FEED';
export const UPDATING_FEED = 'UPDATING_FEED';
export const UPDATE_FEED_ERROR = 'UPDATE_FEED_ERROR';

export const getFeedOptions = () => {
  return { type: GET_FEED_OPTIONS };
};

export const getFeed = () => {
  return { type: GET_FEED };
};

export const updateFeed = (sourceUrl, engine, manager, uid = null) => {
  return { type: UPDATE_FEED, sourceUrl, engine, manager, uid };
};
