import { call, put, take } from 'redux-saga/effects';

import ChannelsApi from '../api/channels';
import {
  GET_DASHBOARD_MARGIN_INFO,
  GETTING_DASHBOARD_MARGIN_INFO,
  SET_DASHBOARD_MARGIN_INFO,
  SET_DASHBOARD_MARGIN_INFO_ERROR,
  GET_DASHBOARD_STATUS_BY_DATE,
  GETTING_DASHBOARD_STATUS_BY_DATE,
  SET_DASHBOARD_STATUS_BY_DATE,
  SET_DASHBOARD_STATUS_BY_DATE_ERROR,
  GET_DASHBOARD_POSITION_BY_DATE,
  GETTING_DASHBOARD_POSITION_BY_DATE,
  SET_DASHBOARD_POSITION_BY_DATE,
  SET_DASHBOARD_POSITION_BY_DATE_ERROR,
  GET_DASHBOARD_TARGET_POSITION_BY_DATE,
  GETTING_DASHBOARD_TARGET_POSITION_BY_DATE,
  SET_DASHBOARD_TARGET_POSITION_BY_DATE,
  SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR,
} from '../actions/dashboardStats';

export function* getDashboardMarginInfo() {
  while (true) {
    yield take(GET_DASHBOARD_MARGIN_INFO);
    yield put({ type: GETTING_DASHBOARD_MARGIN_INFO, gettingMargin: true });
    try {
      const result = yield call(ChannelsApi.getChannelMarginInfo);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_DASHBOARD_MARGIN_INFO, margin: result });
      }
    } catch (error) {
      yield put({ type: SET_DASHBOARD_MARGIN_INFO, margin: {} });
      yield put({ type: SET_DASHBOARD_MARGIN_INFO_ERROR, error });
    } finally {
      yield put({ type: GETTING_DASHBOARD_MARGIN_INFO, gettingMargin: false });
    }
  }
}

export function* getDashboardPositionByDate() {
  while (true) {
    yield take(GET_DASHBOARD_POSITION_BY_DATE);
    yield put({ type: GETTING_DASHBOARD_POSITION_BY_DATE, gettingPositionByDate: true });
    try {
      const result = yield call(ChannelsApi.getChannelPositionByDate);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_DASHBOARD_POSITION_BY_DATE, positionByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_DASHBOARD_POSITION_BY_DATE, positionByDate: [] });
      yield put({ type: SET_DASHBOARD_POSITION_BY_DATE_ERROR, error });
    } finally {
      yield put({
        type: GETTING_DASHBOARD_POSITION_BY_DATE,
        gettingPositionByDate: false,
      });
    }
  }
}

export function* getDashboardTargetPositionByDate() {
  while (true) {
    yield take(GET_DASHBOARD_TARGET_POSITION_BY_DATE);
    yield put({
      type: GETTING_DASHBOARD_TARGET_POSITION_BY_DATE,
      gettingTargetPositionByDate: true,
    });
    try {
      const result = yield call(ChannelsApi.getChannelTargetPositionByDate);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_DASHBOARD_TARGET_POSITION_BY_DATE, targetPositionByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_DASHBOARD_TARGET_POSITION_BY_DATE, targetPositionByDate: [] });
      yield put({ type: SET_DASHBOARD_TARGET_POSITION_BY_DATE_ERROR, error });
    } finally {
      yield put({
        type: GETTING_DASHBOARD_TARGET_POSITION_BY_DATE,
        gettingTargetPositionByDate: false,
      });
    }
  }
}

export function* getDashboardStatusByDate() {
  while (true) {
    yield take(GET_DASHBOARD_STATUS_BY_DATE);
    yield put({ type: GETTING_DASHBOARD_STATUS_BY_DATE, gettingStatusByDate: true });
    try {
      const result = yield call(ChannelsApi.getChannelStatusByDate);
      if (Object.keys(result).length >= 0) {
        yield put({ type: SET_DASHBOARD_STATUS_BY_DATE, statusByDate: result });
      }
    } catch (error) {
      yield put({ type: SET_DASHBOARD_STATUS_BY_DATE, statusByDate: [] });
      yield put({ type: SET_DASHBOARD_STATUS_BY_DATE_ERROR, error });
    } finally {
      yield put({
        type: GETTING_DASHBOARD_STATUS_BY_DATE,
        gettingStatusByDate: false,
      });
    }
  }
}
