export function throwError(code, message) {
  let error = '';
  switch (code) {
    case 400:
      error = 'api.badRequest';
      break;
    case 401:
      error = 'api.badCredentials';
      break;
    case 403:
      error = 'api.forbiddenAccess';
      break;
    case 500:
      error = 'api.internalError';
      break;
    default:
      error = message ? message : 'api.unknownError';
  }
  throw new Error(error);
}
