export const GETTING_CHANNELS_PRICING_IMPORT = 'GETTING_CHANNELS_PRICING_IMPORT';
export const CHANNELS_PRICING_IMPORT_ERROR = 'CHANNELS_PRICING_IMPORT_ERROR';
export const CLEAR_CHANNELS_PRICING_IMPORT_ERROR = 'CLEAR_CHANNELS_PRICING_IMPORT_ERROR';

export const GET_CHANNELS_PRICING_IMPORT = 'GET_CHANNELS_PRICING_IMPORT';
export const SET_CHANNELS_PRICING_IMPORT = 'SET_CHANNELS_PRICING_IMPORT';

export const POST_CHANNELS_PRICING_IMPORT = 'POST_CHANNELS_PRICING_IMPORT';
export const POSTING_CHANNELS_PRICING_IMPORT = 'POSTING_CHANNELS_PRICING_IMPORT';

export const DONE_CHANNELS_PRICING_IMPORTS = 'DONE_CHANNELS_PRICING_IMPORTS';

export const getChannelsPricingImport = () => {
  return { type: GET_CHANNELS_PRICING_IMPORT };
};

export const saveChannelsPricingImport = imports => {
  return { type: POST_CHANNELS_PRICING_IMPORT, imports };
};
