import { call, put, take } from 'redux-saga/effects';

import CatalogConfigApi from '../api/catalogConfig.api';
import {
  CHANNELS_PRICING_IMPORT_ERROR,
  DONE_CHANNELS_PRICING_IMPORTS,
  GET_CHANNELS_PRICING_IMPORT,
  GETTING_CHANNELS_PRICING_IMPORT,
  POST_CHANNELS_PRICING_IMPORT,
  POSTING_CHANNELS_PRICING_IMPORT,
  SET_CHANNELS_PRICING_IMPORT,
} from '../actions/channelsPricingImport';

export function* getChannelsPricingImports() {
  while (true) {
    yield take(GET_CHANNELS_PRICING_IMPORT);
    yield put({ type: GETTING_CHANNELS_PRICING_IMPORT, getting: true });

    try {
      const configuration = yield call(CatalogConfigApi.getChannelsPricingImport);
      if (configuration) {
        yield put({ type: SET_CHANNELS_PRICING_IMPORT, imports: configuration });
        yield put({ type: DONE_CHANNELS_PRICING_IMPORTS, done: false });
      }
    } catch (error) {
      yield put({ type: SET_CHANNELS_PRICING_IMPORT, imports: { error: error.message } });
    } finally {
      yield put({ type: GETTING_CHANNELS_PRICING_IMPORT, getting: false });
    }
  }
}

export function* saveChannelsPricingImports() {
  while (true) {
    const request = yield take(POST_CHANNELS_PRICING_IMPORT);

    yield put({ type: POSTING_CHANNELS_PRICING_IMPORT, posting: true });

    try {
      yield call(CatalogConfigApi.saveChannelsPricingImport, request.imports);
      yield put({ type: DONE_CHANNELS_PRICING_IMPORTS, done: true });
    } catch (error) {
      yield put({ type: CHANNELS_PRICING_IMPORT_ERROR, error });
    } finally {
      yield put({ type: POSTING_CHANNELS_PRICING_IMPORT, posting: false });
    }
  }
}
