import {
  GETTING_FEED,
  GETTING_FEED_OPTIONS,
  SET_FEED,
  SET_FEED_ERROR,
  SET_FEED_OPTIONS,
  SET_FEED_OPTIONS_ERROR,
  UPDATE_FEED_ERROR,
  UPDATING_FEED,
} from '../actions/feedManager';

let initialState = {
  feedOptions: null,
  gettingFeedOptions: false,
  feedOptionsError: '',

  feed: null,
  gettingFeed: false,
  feedError: '',

  updatingFeed: false,
  updateFeedError: '',
};

function feedManager(state = initialState, action) {
  const { type, feedOptions, feed, getting, error } = action;
  switch (type) {
    case SET_FEED_OPTIONS:
      return { ...state, feedOptions };
    case GETTING_FEED_OPTIONS:
      return { ...state, gettingFeedOptions: getting };
    case SET_FEED_OPTIONS_ERROR:
      return { ...state, feedOptionsError: error };

    case SET_FEED:
      return { ...state, feed };
    case GETTING_FEED:
      return { ...state, gettingFeed: getting };
    case SET_FEED_ERROR:
      return { ...state, feedListError: error };

    case UPDATING_FEED:
      return { ...state, updatingFeed: getting };
    case UPDATE_FEED_ERROR:
      return { ...state, updateFeedError: error };
    default:
      return state;
  }
}

export default feedManager;
