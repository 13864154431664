export const SENDING_REQUEST = 'SENDING_REQUEST';
export const GET_CATALOG_PRODUCTS = 'GET_CATALOG_PRODUCTS';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_CATALOG_PRODUCTS = 'SET_CATALOG_PRODUCTS';
export const SET_CATALOG_PARAMETERS = 'SET_CATALOG_PARAMETERS';

export const getCatalogProducts = (param = {}) => {
  const { filtersLeft, filtersRight, sorts, page, limit } = param;
  return { type: GET_CATALOG_PRODUCTS, filtersLeft, filtersRight, sorts, page, limit };
};

export const clearError = () => {
  return { type: CLEAR_ERROR };
};
