export const GET_CHANNEL_MATCHING_STATUS = 'GET_CHANNEL_MATCHING_STATUS';
export const CLEAR_ERROR_CHANNEL_MATCHING_STATUS = 'CLEAR_ERROR_CHANNEL_MATCHING_STATUS';
export const GETTING_CHANNEL_MATCHING_STATUS = 'GETTING_CHANNEL_MATCHING_STATUS';
export const SET_CHANNEL_MATCHING_STATUS = 'SET_CHANNEL_MATCHING_STATUS';
export const SET_CHANNEL_MATCHING_STATUS_ERROR = 'SET_CHANNEL_MATCHING_STATUS_ERROR';
export const GET_CHANNEL_MARGIN_INFO = 'GET_CHANNEL_MARGIN_INFO';
export const GETTING_MARGIN_INFO = 'GETTING_MARGIN_INFO';
export const SET_MARGIN_INFO = 'SET_MARGIN_INFO';
export const SET_MARGIN_INFO_ERROR = 'SET_MARGIN_INFO_ERROR';
export const GET_CHANNEL_COMPETITORS_STATS = 'GET_CHANNEL_COMPETITORS_STATS';
export const GETTING_COMPETITORS_STATS = 'GETTING_COMPETITORS_STATS';
export const SET_COMPETITORS_STATS = 'SET_COMPETITORS_STATS';
export const SET_COMPETITORS_STATS_ERROR = 'SET_COMPETITORS_STATS_ERROR';
export const GET_CHANNEL_POSITION_BY_DATE = 'GET_CHANNEL_POSITION_BY_DATE';
export const GETTING_POSITION_BY_DATE = 'GETTING_POSITION_BY_DATE';
export const SET_POSITION_BY_DATE = 'SET_POSITION_BY_DATE';
export const SET_POSITION_BY_DATE_ERROR = 'SET_POSITION_BY_DATE_ERROR';
export const GET_CHANNEL_TARGET_POSITION_BY_DATE = 'GET_CHANNEL_TARGET_POSITION_BY_DATE';
export const GETTING_TARGET_POSITION_BY_DATE = 'GETTING_TARGET_POSITION_BY_DATE';
export const SET_TARGET_POSITION_BY_DATE = 'SET_TARGET_POSITION_BY_DATE';
export const SET_TARGET_POSITION_BY_DATE_ERROR = 'SET_TARGET_POSITION_BY_DATE_ERROR';
export const GET_CHANNEL_STATUS_BY_DATE = 'GET_CHANNEL_STATUS_BY_DATE';
export const GETTING_STATUS_BY_DATE = 'GETTING_STATUS_BY_DATE';
export const SET_STATUS_BY_DATE = 'SET_STATUS_BY_DATE';
export const SET_STATUS_BY_DATE_ERROR = 'SET_STATUS_BY_DATE_ERROR';

export const getChannelMatchingStatus = channel => {
  return { type: GET_CHANNEL_MATCHING_STATUS, channel };
};

export const getChannelMarginInfo = channel => {
  return { type: GET_CHANNEL_MARGIN_INFO, channel };
};

export const getChannelCompetitorsStats = channel => {
  return { type: GET_CHANNEL_COMPETITORS_STATS, channel };
};

export const getChannelStatusByDate = channel => {
  return { type: GET_CHANNEL_STATUS_BY_DATE, channel };
};

export const getChannelPositionByDate = channel => {
  return { type: GET_CHANNEL_POSITION_BY_DATE, channel };
};

export const getChannelTargetPositionByDate = channel => {
  return { type: GET_CHANNEL_TARGET_POSITION_BY_DATE, channel };
};
