import { API_BASE_URL } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const API_GET_USER_INFO_URL = API_BASE_URL + 'client-data/profile';

const user = {
  getInfo() {
    return privateCall(API_GET_USER_INFO_URL, 'GET');
  },
};

export default user;
