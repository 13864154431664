import { call, put, take } from 'redux-saga/effects';

import {
  CHECK_CATALOG_CONFIGURED,
  GET_CATALOG_ATTRIBUTES,
  GET_CATALOG_CONFIGURATION,
  GET_CATALOG_CREDENTIALS,
  GET_CATALOG_FILTERS,
  GET_CATALOG_STATISTICS,
  GET_PRODUCT_PRICING,
  GET_PRODUCTS_COUNT,
  GET_PRODUCTS_LIMIT,
  GETTING_CATALOG_ATTRIBUTES,
  GETTING_CATALOG_CONFIGURED,
  GETTING_CATALOG_CREDENTIALS,
  GETTING_CATALOG_FILTERS,
  GETTING_CATALOG_STATISTICS,
  GETTING_PRODUCTS_COUNT,
  GETTING_PRODUCTS_LIMIT,
  IMPORT_CATALOG,
  IMPORT_CATALOG_ERROR,
  IMPORTING_CATALOG,
  POST_CATALOG_FILTERS,
  POSTING_CATALOG_FILTERS,
  SAVE_CATALOG_ATTRIBUTES,
  SAVING_CATALOG_ATTRIBUTES,
  SEND_CMS_CREDENTIALS,
  SEND_CMS_CSV_CREDENTIALS,
  SENDING_CMS_CREDENTIALS,
  SET_CATALOG_ATTRIBUTES,
  SET_CATALOG_ATTRIBUTES_ERROR,
  SET_CATALOG_CONFIGURED,
  SET_CATALOG_CREDENTIALS,
  SET_CATALOG_CREDENTIALS_ERROR,
  SET_CATALOG_FILTERS,
  SET_CATALOG_STATISTICS,
  SET_PRODUCT_PRICING,
  SET_PRODUCTS_COUNT,
  SET_PRODUCTS_COUNT_ERROR,
  SET_PRODUCTS_LIMIT,
  SET_PRODUCTS_LIMIT_ERROR,
} from '../actions/catalogConfigure';
import CatalogConfigApi from '../api/catalogConfig.api';
import { GET_CHANNELS_PRICING_IMPORT } from '../actions/channelsPricingImport';
import { GET_CATALOG_MAPPING } from '../actions/catalogMapping';
import { customFileCatalog } from '../tools/constants';

export function* getCatalogStatisticsFlow() {
  while (true) {
    yield take(GET_CATALOG_STATISTICS);

    yield put({ type: GETTING_CATALOG_STATISTICS, fetching: true });

    try {
      const statistics = yield call(CatalogConfigApi.getFullCatalogStatistic);
      if (statistics) {
        yield put({ type: SET_CATALOG_STATISTICS, statistics });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_STATISTICS, statistics: error });
    } finally {
      yield put({ type: GETTING_CATALOG_STATISTICS, fetching: false });
    }
  }
}

export function* saveCatalogCredentialsFlow() {
  while (true) {
    const data = yield take(SEND_CMS_CREDENTIALS);

    yield put({ type: SENDING_CMS_CREDENTIALS, sending: true });

    try {
      const configured = yield call(CatalogConfigApi.sendCmsCredentials, data);
      if (configured) {
        yield put({ type: SET_CATALOG_CONFIGURED, configured: { ok: configured } });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_CONFIGURED, configured: { error: error.message } });
    } finally {
      yield put({ type: SENDING_CMS_CREDENTIALS, sending: false });
    }
  }
}

export function* importCatalog() {
  while (true) {
    yield take(IMPORT_CATALOG);
    yield put({ type: IMPORTING_CATALOG, getting: true });
    try {
      yield call(CatalogConfigApi.triggerConnector);
    } catch (error) {
      yield put({ type: IMPORT_CATALOG_ERROR, configured: { error: error.message } });
    } finally {
      yield put({ type: IMPORTING_CATALOG, getting: false });
    }
  }
}

export function* getCatalogFeedFlow() {
  while (true) {
    yield take(GET_CATALOG_CREDENTIALS);
    yield put({ type: GETTING_CATALOG_CREDENTIALS, getting: true });
    try {
      const response = yield call(CatalogConfigApi.getCatalogCredentials);
      if (response) {
        yield put({ type: SET_CATALOG_CREDENTIALS, credentials: response });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_CREDENTIALS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_CATALOG_CREDENTIALS, getting: false });
    }
  }
}

export function* saveCatalogCsvCredentialsFlow() {
  while (true) {
    const data = yield take(SEND_CMS_CSV_CREDENTIALS);

    yield put({ type: SENDING_CMS_CREDENTIALS, sending: true });

    try {
      delete data.type;
      let configured;
      if (data.file) {
        configured = yield call(CatalogConfigApi.uploadCmsCsv, data);
        data.host = 'local';
        configured = yield call(CatalogConfigApi.sendCmsCredentials, data);
        delete data.file;
      } else {
        configured = yield call(CatalogConfigApi.sendCmsCredentials, data);
      }
      if (configured) {
        yield put({
          type: SET_CATALOG_CONFIGURED,
          configured: { /*...connector, */ ok: configured },
        });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_CONFIGURED, configured: { error: error.message } });
    } finally {
      yield put({ type: SENDING_CMS_CREDENTIALS, sending: false });
    }
  }
}

export function* checkCatalogConfiguredFlow() {
  while (true) {
    yield take(CHECK_CATALOG_CONFIGURED);
    yield put({ type: GETTING_CATALOG_CONFIGURED, checking: true });
    try {
      const configured = yield call(CatalogConfigApi.checkCatalogConfigured);
      if (configured) {
        configured.progress = Object.keys(configured)
          .filter(it => it !== 'type')
          .map(it => ({
            title: 'word.' + it,
            status: configured[it] ? 'OK' : 'ERROR',
          }));
        yield put({ type: SET_CATALOG_CONFIGURED, configured });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_CONFIGURED, configured: error });
    } finally {
      yield put({ type: GETTING_CATALOG_CONFIGURED, checking: false });
    }
  }
}

export function* getCatalogConfigurationFlow() {
  while (true) {
    const { connector } = yield take(GET_CATALOG_CONFIGURATION);

    try {
      yield put({ type: GET_CATALOG_MAPPING });
      if (connector === customFileCatalog) {
        yield put({ type: GET_PRODUCT_PRICING });
      } else {
        yield put({ type: GET_CHANNELS_PRICING_IMPORT });
      }
    } catch (error) {
      yield put({ type: SET_CATALOG_CONFIGURED, configured: error });
    } finally {
      yield put({ type: GETTING_CATALOG_CONFIGURED, checking: false });
    }
  }
}

export function* getProductPricingFlow() {
  while (true) {
    yield take(GET_PRODUCT_PRICING);
    try {
      const feed = yield call(CatalogConfigApi.getProductsPricing);
      yield put({ type: SET_PRODUCT_PRICING, feed });
    } catch (error) {
      yield put({ type: SET_CATALOG_CONFIGURED, configured: error });
    }
  }
}

export function* getCatalogFiltersFlow() {
  while (true) {
    yield take(GET_CATALOG_FILTERS);
    yield put({ type: GETTING_CATALOG_FILTERS, getting_filters: true });
    try {
      const filters = yield call(CatalogConfigApi.getFullCatalogFilters);
      yield put({ type: SET_CATALOG_FILTERS, filters });
    } catch (error) {
      // TODO(hadrien): improve error managemnt
    } finally {
      yield put({ type: GETTING_CATALOG_FILTERS, getting_filters: false });
      // TODO(hadrien): improve error managemnt
    }
  }
}

export function* saveCatalogFiltersFlow() {
  while (true) {
    const request = yield take(POST_CATALOG_FILTERS);
    yield put({ type: POSTING_CATALOG_FILTERS, posting_filters: true });
    try {
      yield call(CatalogConfigApi.saveFullCatalogFilters, request.filters);
    } catch (error) {
      // TODO(hadrien): improve error managemnt
    } finally {
      yield put({ type: POSTING_CATALOG_FILTERS, posting_filters: false });
    }
  }
}

export function* getProductsLimit() {
  while (true) {
    yield take(GET_PRODUCTS_LIMIT);
    yield put({ type: GETTING_PRODUCTS_LIMIT, getting: true });
    try {
      const limit = yield call(CatalogConfigApi.getProductsLimit);
      yield put({ type: SET_PRODUCTS_LIMIT, limit });
    } catch (error) {
      yield put({ type: SET_PRODUCTS_LIMIT_ERROR, error: error.message });
    }
    yield put({ type: GETTING_PRODUCTS_LIMIT, getting: false });
  }
}

export function* getProductsCount() {
  while (true) {
    const { filters } = yield take(GET_PRODUCTS_COUNT);
    yield put({ type: GETTING_PRODUCTS_COUNT, getting: true });
    try {
      const count = yield call(CatalogConfigApi.getProductsCount, filters);
      yield put({ type: SET_PRODUCTS_COUNT, count });
    } catch (error) {
      yield put({ type: SET_PRODUCTS_COUNT_ERROR, error: error.message });
    }
    yield put({ type: GETTING_PRODUCTS_COUNT, getting: false });
  }
}

export function* getCatalogAttributes() {
  while (true) {
    yield take(GET_CATALOG_ATTRIBUTES);
    yield put({ type: GETTING_CATALOG_ATTRIBUTES, getting: true });
    try {
      const catalogAttributes = yield call(CatalogConfigApi.getCatalogAttributes);
      yield put({ type: SET_CATALOG_ATTRIBUTES, catalogAttributes });
    } catch (error) {
      yield put({ type: SET_CATALOG_ATTRIBUTES_ERROR, error: error.message });
    }
    yield put({ type: GETTING_CATALOG_ATTRIBUTES, getting: false });
  }
}

export function* saveCatalogAttributes() {
  while (true) {
    const { attributes } = yield take(SAVE_CATALOG_ATTRIBUTES);
    yield put({ type: SAVING_CATALOG_ATTRIBUTES, getting: true });
    try {
      yield call(CatalogConfigApi.saveCatalogAttributes, attributes);
    } catch (error) {
      yield put({ type: SET_CATALOG_ATTRIBUTES_ERROR, error: error.message });
    }
    yield put({ type: SAVING_CATALOG_ATTRIBUTES, getting: false });
  }
}
