import { clearTokens, setToken } from './storage';

export function storeToken() {
  const urlObj = new URL(window.location.href);
  const accessToken = urlObj.searchParams.get('access_token');
  const expiration = urlObj.searchParams.get('expires_in');
  const expirationTimestamp = Date.parse(expiration);

  clearTokens();
  setToken(accessToken, expirationTimestamp);

  return !isTokenExpired(accessToken);
}

function isTokenExpired(accessToken) {
  return new Date().getTime() > accessToken;
}
