import { call, put, take } from 'redux-saga/effects';

import {
  GET_PROGRESS,
  GETTING_PROGRESS,
  SET_PROGRESS,
  SET_PROGRESS_ERROR,
} from '../actions/repricing';
import RepricingApi from '../api/repricing.api';

export function* getRepricingProgress() {
  while (true) {
    yield take(GET_PROGRESS);
    yield put({ type: GETTING_PROGRESS, processing: true });
    try {
      const result = yield call(RepricingApi.getProgress);
      if (result) {
        yield put({
          type: SET_PROGRESS,
          progress: result.progress,
          toReprice: result.to_reprice,
          inProgress: result.in_progress,
        });
      }
    } catch (error) {
      yield put({ type: SET_PROGRESS_ERROR, error: error.message });
    } finally {
      yield put({ type: GETTING_PROGRESS, processing: false });
    }
  }
}
