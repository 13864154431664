import React from 'react';

export default () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m347.048 278.868c3.737-.023 7.315-1.518 9.957-4.161 2.643-2.642 4.138-6.22 4.161-9.957v-6.511c0-3.763-1.495-7.372-4.156-10.033s-6.269-4.155-10.032-4.155-7.372 1.494-10.033 4.155c-2.66 2.661-4.155 6.27-4.155 10.033v6.511c.023 3.762 1.537 7.36 4.21 10.007 2.673 2.646 6.287 4.125 10.048 4.111z" />
      <path d="m370.096 307.491v-6.512c0-3.763 1.494-7.372 4.155-10.033 2.661-2.66 6.269-4.155 10.032-4.155s7.373 1.495 10.033 4.155c2.661 2.661 4.156 6.27 4.156 10.033v6.512c0 3.763-1.495 7.371-4.156 10.032-2.66 2.661-6.27 4.156-10.033 4.156s-7.371-1.495-10.032-4.156-4.155-6.269-4.155-10.032z" />
      <path d="m345.778 320.989c1.585.825 3.432.987 5.136.451s3.125-1.727 3.952-3.31l33.529-64.235c.751-1.576.861-3.381.308-5.037-.554-1.656-1.729-3.032-3.277-3.839s-3.348-.982-5.023-.489c-1.674.494-3.092 1.618-3.955 3.136l-33.529 64.235c-.824 1.584-.986 3.431-.45 5.135s1.726 3.126 3.309 3.953z" />
      <path
        clipRule="evenodd"
        d="m449.259 119.234c1.788 0 3.502-.71 4.767-1.974 1.264-1.265 1.974-2.979 1.974-4.767v-77.7519c0-1.7879-.71-3.5025-1.974-4.7667-1.265-1.2642-2.979-1.9744-4.767-1.9744h-330.789c-1.788 0-3.503.7102-4.767 1.9744s-1.974 2.9788-1.974 4.7667v270.1019h-48.9879c-.8853.002-1.7614.179-2.5784.52s-1.5588.839-2.1831 1.467c-.6244.627-1.1189 1.372-1.4556 2.191-.3366.818-.5087 1.695-.5064 2.581v61.64c0 1.788.7103 3.502 1.9745 4.767 1.2642 1.264 2.9788 1.974 4.7666 1.974 1.7879 0 3.5025-.71 4.7667-1.974 1.2642-1.265 1.9744-2.979 1.9744-4.767v-54.899h236.2552c4.721 7.943 10.969 14.871 18.384 20.384 7.415 5.512 15.85 9.5 24.815 11.733v119.769h-279.4718v-65.682c0-1.787-.7103-3.502-1.9745-4.766s-2.9788-1.975-4.7666-1.975c-1.7879 0-3.5025.711-4.7667 1.975s-1.9744 2.979-1.9744 4.766v72.352c0 1.788.7102 3.503 1.9744 4.767s2.9788 1.974 4.7667 1.974h386.4649c1.788 0 3.502-.71 4.767-1.974 1.264-1.264 1.974-2.979 1.974-4.767v-332.836c0-1.788-.71-3.502-1.974-4.767-1.265-1.264-2.979-1.974-4.767-1.974s-3.503.71-4.767 1.974c-1.264 1.265-1.974 2.979-1.974 4.767v326.183h-79.94v-117.845h3.141c14.751.014 29.126-4.651 41.058-13.323s20.806-20.905 25.346-34.94 4.512-29.147-.081-43.165c-4.592-14.018-13.511-26.218-25.475-34.846s-26.356-13.239-41.107-13.17c-14.75.069-29.099 4.814-40.982 13.553s-20.689 21.021-25.15 35.081c-4.462 14.06-4.349 29.172.321 43.164h-174.385v-263.2728h317.272v71.0108c0 .885.174 1.762.513 2.58.338.817.835 1.561 1.461 2.187s1.369 1.122 2.187 1.461 1.694.513 2.58.513zm-83.593 107.416c11.108.004 21.965 3.3 31.2 9.474 9.234 6.173 16.431 14.946 20.68 25.209s5.36 21.555 3.192 32.449c-2.168 10.895-7.517 20.901-15.372 28.756-7.854 7.854-17.861 13.204-28.755 15.371-10.894 2.168-22.187 1.057-32.45-3.192s-19.035-11.446-25.209-20.68c-6.173-9.234-9.47-20.092-9.473-31.2.014-14.897 5.938-29.18 16.472-39.715 10.534-10.534 24.818-16.458 39.715-16.472z"
        fillRule="evenodd"
      />
      <path d="m220.045 166.422c1.788 0 3.502-.71 4.766-1.975 1.265-1.264 1.975-2.979 1.975-4.766v-52.588h11.241c1.788 0 3.502-.71 4.766-1.974 1.265-1.264 1.975-2.979 1.975-4.767 0-1.7878-.71-3.5023-1.975-4.7665-1.264-1.2642-2.978-1.9744-4.766-1.9744h-35.947c-1.788 0-3.502.7102-4.766 1.9744-1.265 1.2642-1.975 2.9787-1.975 4.7665 0 1.788.71 3.503 1.975 4.767 1.264 1.264 2.978 1.974 4.766 1.974h11.241v52.588c0 1.784.708 3.496 1.968 4.76 1.261 1.264 2.971 1.976 4.756 1.981z" />
      <path
        clipRule="evenodd"
        d="m306.622 164.447c-1.264 1.265-2.979 1.975-4.767 1.975-.886.002-1.765-.171-2.585-.508-.82-.338-1.565-.834-2.193-1.46-.627-.627-1.126-1.371-1.465-2.19-.34-.819-.515-1.697-.515-2.583v-22.941h-22.482v22.941c0 1.787-.71 3.502-1.975 4.766-1.264 1.265-2.979 1.975-4.767 1.975-1.787 0-3.502-.71-4.766-1.975-1.264-1.264-1.974-2.979-1.974-4.766v-50.329c.004-4.173 1.664-8.174 4.615-11.1254 2.951-2.951 6.952-4.6108 11.126-4.6155h17.982c4.173.0047 8.174 1.6645 11.125 4.6155 2.951 2.9514 4.611 6.9524 4.615 11.1254v50.329c0 1.787-.71 3.502-1.974 4.766zm-33.99-55.095v13.923h22.482v-13.923c-.004-.593-.242-1.16-.661-1.58-.419-.419-.987-.657-1.58-.661h-17.982c-.596 0-1.168.235-1.591.655s-.663.99-.668 1.586z"
        fillRule="evenodd"
      />
      <path d="m326.208 165.452c.758.46 1.598.765 2.474.9.876.134 1.77.094 2.63-.117.861-.212 1.671-.591 2.385-1.116s1.317-1.185 1.775-1.943l12.212-20.153 12.212 20.153c.936 1.528 2.441 2.621 4.183 3.04s3.579.128 5.108-.808c1.528-.936 2.621-2.441 3.04-4.183.419-1.743.128-3.58-.808-5.108l-15.882-26.17 15.882-26.17c.936-1.529 1.227-3.366.808-5.1082-.419-1.7424-1.512-3.2472-3.04-4.1833-1.529-.936-3.366-1.2267-5.108-.808-1.742.4186-3.247 1.5122-4.183 3.0403l-12.212 20.1532-12.212-20.1532c-.936-1.5281-2.44-2.6217-4.183-3.0403-1.742-.4187-3.579-.128-5.107.808-1.528.9361-2.622 2.4409-3.041 4.1833-.419 1.7422-.128 3.5792.808 5.1082l15.882 26.17-15.882 26.17c-.471.759-.787 1.605-.929 2.487-.141.883-.106 1.785.104 2.653.211.869.592 1.687 1.121 2.407s1.197 1.328 1.963 1.788z" />
      <path d="m400.271 90.4703v79.1457c0 3.246-.639 6.46-1.882 9.459-1.243 2.998-3.064 5.723-5.36 8.017s-5.021 4.114-8.021 5.354c-2.999 1.241-6.214 1.878-9.46 1.876h-183.368c-6.552 0-12.836-2.603-17.469-7.236s-7.236-10.917-7.236-17.47v-79.1633c0-6.5523 2.603-12.8363 7.236-17.4694 4.633-4.6332 10.917-7.2361 17.469-7.2361h23.594c1.788 0 3.503.7102 4.767 1.9744s1.974 2.9788 1.974 4.7667c0 1.7878-.71 3.5024-1.974 4.7666s-2.979 1.9744-4.767 1.9744h-23.594c-2.981 0-5.84 1.1844-7.948 3.2925s-3.293 4.9672-3.293 7.9485v79.1457c.005 2.98 1.191 5.837 3.298 7.944s4.964 3.293 7.943 3.297h183.386c2.981 0 5.84-1.184 7.948-3.292 2.109-2.108 3.293-4.967 3.293-7.949v-79.128c0-2.9813-1.184-5.8405-3.293-7.9486-2.108-2.1081-4.967-3.2924-7.948-3.2924h-128.169c-1.788 0-3.502-.7102-4.766-1.9744-1.265-1.2642-1.975-2.9788-1.975-4.7667 0-1.7878.71-3.5025 1.975-4.7667 1.264-1.2642 2.978-1.9744 4.766-1.9744h128.169c6.552 0 12.836 2.6029 17.469 7.2361s7.236 10.9171 7.236 17.4694z" />
      <path d="m292.644 401.424h-22.624c-1.787 0-3.502-.711-4.766-1.975-1.265-1.264-1.975-2.979-1.975-4.767s.71-3.502 1.975-4.766c1.264-1.265 2.979-1.975 4.766-1.975h22.624c1.787 0 3.502.71 4.766 1.975 1.264 1.264 1.975 2.978 1.975 4.766s-.711 3.503-1.975 4.767-2.979 1.975-4.766 1.975z" />
      <path
        clipRule="evenodd"
        d="m171.463 401.406h4.782c1.552 7.614 5.688 14.457 11.706 19.373 6.018 4.915 13.55 7.599 21.32 7.599s15.302-2.684 21.32-7.599c6.019-4.916 10.154-11.759 11.706-19.373h4.659c1.788 0 3.502-.71 4.767-1.974 1.264-1.264 1.974-2.979 1.974-4.767 0-1.787-.71-3.502-1.974-4.766-1.265-1.264-2.979-1.975-4.767-1.975h-4.659c-1.552-7.614-5.687-14.457-11.706-19.372-6.018-4.915-13.55-7.6-21.32-7.6s-15.302 2.685-21.32 7.6-10.154 11.758-11.706 19.372h-4.782c-1.788 0-3.503.711-4.767 1.975s-1.974 2.979-1.974 4.766c0 1.788.71 3.503 1.974 4.767s2.979 1.974 4.767 1.974zm37.799-26.964c4 0 7.91 1.186 11.236 3.408 3.325 2.222 5.918 5.381 7.448 9.076 1.531 3.695 1.931 7.762 1.151 11.685s-2.706 7.526-5.535 10.354c-2.828 2.828-6.431 4.754-10.354 5.535-3.923.78-7.989.38-11.685-1.151-3.695-1.531-6.853-4.122-9.076-7.448-2.222-3.326-3.408-7.236-3.408-11.236.005-5.362 2.137-10.503 5.928-14.295 3.792-3.791 8.933-5.923 14.295-5.928z"
        fillRule="evenodd"
      />
      <path d="m148.505 401.424h-22.624c-1.788 0-3.502-.711-4.766-1.975s-1.975-2.979-1.975-4.767.711-3.502 1.975-4.766c1.264-1.265 2.978-1.975 4.766-1.975h22.624c1.787 0 3.502.71 4.766 1.975 1.264 1.264 1.975 2.978 1.975 4.766s-.711 3.503-1.975 4.767-2.979 1.975-4.766 1.975z" />
      <path
        clipRule="evenodd"
        d="m107.105 449.176c0 1.788.71 3.503 1.975 4.767 1.264 1.264 2.978 1.975 4.766 1.975h190.833c1.788 0 3.502-.711 4.766-1.975 1.265-1.264 1.975-2.979 1.975-4.767.004-3.897 1.555-7.633 4.31-10.389 2.756-2.755 6.492-4.306 10.39-4.311 1.787 0 3.502-.71 4.766-1.974s1.975-2.979 1.975-4.767v-65.875c0-1.788-.711-3.503-1.975-4.767s-2.979-1.974-4.766-1.974c-3.898-.005-7.634-1.555-10.39-4.311-2.755-2.756-4.306-6.492-4.31-10.389 0-1.788-.71-3.502-1.975-4.767-1.264-1.264-2.978-1.974-4.766-1.974h-190.833c-1.788 0-3.502.71-4.766 1.974-1.265 1.265-1.975 2.979-1.975 4.767-.005 3.897-1.555 7.633-4.311 10.389-2.755 2.756-6.4915 4.306-10.3887 4.311-1.7878 0-3.5024.71-4.7666 1.974s-1.9744 2.979-1.9744 4.767v65.875c0 1.788.7102 3.503 1.9744 4.767s2.9788 1.974 4.7666 1.974c3.8972.005 7.6337 1.556 10.3887 4.311 2.756 2.756 4.306 6.492 4.311 10.389zm-7.9586-81.387c4.9906-1.234 9.5496-3.808 13.1856-7.443 3.635-3.636 6.209-8.195 7.444-13.186h178.991c1.235 4.991 3.808 9.55 7.444 13.186 3.635 3.635 8.194 6.209 13.185 7.443v54.017c-4.991 1.235-9.55 3.808-13.185 7.444-3.636 3.635-6.209 8.194-7.444 13.185h-178.991c-1.235-4.991-3.809-9.55-7.444-13.185-3.636-3.636-8.195-6.209-13.1856-7.444z"
        fillRule="evenodd"
      />
      <path d="m295.483 220.929c.339.818.513 1.695.513 2.58 0 .892-.176 1.774-.519 2.596-.344.823-.847 1.569-1.48 2.196s-1.385 1.122-2.211 1.457c-.826.334-1.71.502-2.601.492h-115.057c-1.788 0-3.503-.71-4.767-1.974s-1.974-2.979-1.974-4.767.71-3.502 1.974-4.766 2.979-1.975 4.767-1.975h115.127c.885 0 1.762.175 2.58.513.818.339 1.561.836 2.187 1.462s1.123 1.369 1.461 2.186z" />
      <path d="m253.221 276.979h-79.093c-1.788 0-3.503.71-4.767 1.974s-1.974 2.979-1.974 4.767.71 3.502 1.974 4.766c1.264 1.265 2.979 1.975 4.767 1.975h79.093c1.787 0 3.502-.71 4.766-1.975 1.264-1.264 1.975-2.978 1.975-4.766s-.711-3.503-1.975-4.767-2.979-1.974-4.766-1.974z" />
      <path d="m174.128 246.838h79.093c1.787 0 3.502.71 4.766 1.975 1.264 1.264 1.975 2.978 1.975 4.766s-.711 3.503-1.975 4.767-2.979 1.974-4.766 1.974h-79.093c-1.788 0-3.503-.71-4.767-1.974s-1.974-2.979-1.974-4.767.71-3.502 1.974-4.766c1.264-1.265 2.979-1.975 4.767-1.975z" />
    </g>
  </svg>
);
