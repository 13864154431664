import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const API_GET_CACHE_VALUE = API_BASE_URL_V2 + 'client-data/cache/get';
export const API_SET_CACHE_VALUE = API_BASE_URL_V2 + 'client-data/cache/set';
export const API_DELETE_CACHE_VALUE = API_BASE_URL_V2 + 'client-data/cache/delete';

const Cache = {
  async getCacheValue(key) {
    const result = await privateCall(API_GET_CACHE_VALUE, 'GET', { key });
    return typeof result === 'string' ? JSON.parse(result) : result;
  },

  async setCacheValue(key, value) {
    const valueFlat = value === [] ? '' : JSON.stringify(value);
    return await privateCall(API_SET_CACHE_VALUE, 'POST', { key, value: valueFlat });
  },

  async deleteCacheValue(key) {
    return await privateCall(API_DELETE_CACHE_VALUE, 'GET', { key });
  },
};

export default Cache;
