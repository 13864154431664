export const GET_CARRIER = 'GET_CARRIER';
export const GETTING_CARRIER = 'GETTING_CARRIER';
export const GET_CARRIERS = 'GET_CARRIERS';
export const GETTING_CARRIERS = 'GETTING_CARRIERS';
export const SET_CARRIERS = 'SET_CARRIERS';
export const SET_CARRIER_GRID = 'SET_CARRIER_GRID';
export const SET_CARRIERS_ERROR = 'SET_CARRIERS_ERROR';
export const UPDATE_CARRIER = 'UPDATE_CARRIER';
export const UPDATING_CARRIER = 'UPDATING_CARRIER';
export const SET_UPDATE_CARRIER_ERROR = 'SET_UPDATE_CARRIER_ERROR';
export const DELETE_CARRIER = 'DELETE_CARRIER';
export const DELETING_CARRIER = 'DELETING_CARRIER';
export const SET_DELETE_CARRIER_ERROR = 'SET_DELETE_CARRIER_ERROR';

export const getCarriers = () => {
  return { type: GET_CARRIERS };
};

export const updateCarrier = carrier => {
  return { type: UPDATE_CARRIER, carrier };
};

export const deleteCarrier = carrierName => {
  return { type: DELETE_CARRIER, carrierName };
};

export const getCarrier = (carrierName, carrierId) => {
  return { type: GET_CARRIER, carrierName, carrierId };
};
