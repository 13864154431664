import { call, put, take } from 'redux-saga/effects';

import Mirakl from '../api/mirakl';
import {
  SAVE_MIRAKL_OAUTH,
  SAVE_MIRAKL_OAUTH_ERROR,
  SAVED_MIRAKL_OAUTH,
  SAVING_MIRAKL_OAUTH,
} from '../actions/mirakl';

export function* saveMiraklOAuthCodeFlow() {
  while (true) {
    const { code, uid } = yield take(SAVE_MIRAKL_OAUTH);
    yield put({ type: SAVING_MIRAKL_OAUTH, saving: false });
    try {
      const oauthSuccess = yield call(Mirakl.saveMiraklOAuthCode, code, uid);
      if (oauthSuccess) {
        yield put({ type: SAVED_MIRAKL_OAUTH, success: oauthSuccess });
      }
    } catch (error) {
      yield put({ type: SAVE_MIRAKL_OAUTH_ERROR, error: error.message });
    } finally {
      yield put({ type: SAVING_MIRAKL_OAUTH, saving: false });
    }
  }
}
