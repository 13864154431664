import { API_BASE_URL } from '../tools/constants';

import { privateCall } from './tools/privateCall';

const API_GET_CARRIERS = API_BASE_URL + 'pricer/shipping/grid';
const API_GET_CARRIER_GRID = API_BASE_URL + 'pricer/shipping/grid-rows';

const CarrierApi = {
  async getCarriers() {
    const response = await privateCall(API_GET_CARRIERS, 'GET');
    return response.body;
  },

  async getCarriersGrid(carrierName) {
    const params = { name: carrierName };
    const response = await privateCall(API_GET_CARRIER_GRID, 'GET', params);
    return response.body;
  },

  async saveCarrierGrid(carrier) {
    let fd = new FormData();

    fd.append('old_name', carrier.name);
    if (carrier.newName) {
      fd.append('name', carrier.newName);
    } else {
      fd.append('name', carrier.name);
    }

    carrier.grid.forEach((row, idx) =>
      ['value', 'price', 'reference'].forEach(field =>
        fd.append('rows[' + idx + '][' + field + ']', row[field])
      )
    );

    const response = await privateCall(API_GET_CARRIERS, 'POST', fd);
    return response.body;
  },

  async deleteCarrier(carrierName) {
    const params = { name: carrierName };
    const response = await privateCall(API_GET_CARRIERS, 'DELETE', params);
    return response.body;
  },
};

export default CarrierApi;
