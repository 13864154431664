import { API_BASE_URL_V2 } from '../tools/constants';

import { privateCall } from './tools/privateCall';

export const GET_CATALOG_HISTORY = `${API_BASE_URL_V2}client-data/feeds`;
export const DOWNLOAD_CATALOG = `${API_BASE_URL_V2}client-data/feeds/content`;

const CatalogHistoryApi = {
  async getCatalogHistory() {
    return await privateCall(GET_CATALOG_HISTORY, 'GET');
  },

  async downloadCatalog(uid) {
    return await privateCall(DOWNLOAD_CATALOG + '/' + uid, 'GET');
  },
};

export default CatalogHistoryApi;
