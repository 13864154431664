import {
  CLEAR_ERROR,
  REQUEST_ERROR,
  SETTING_BEHAVIOR,
  SET_PRODUCTS_BEHAVIOR,
} from '../actions/behavior';

let initialState = {
  behavior: null,
  products: [],
  channels: [],
  error: '',
  currentlySending: false,
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  const { type, error } = action;
  switch (type) {
    case SET_PRODUCTS_BEHAVIOR:
      return { ...state, products: action.products, behavior: action.behavior };
    case SETTING_BEHAVIOR:
      return { ...state, currentlySending: action.sending };
    case REQUEST_ERROR:
      return { ...state, error };
    case CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
}

export default reducer;
