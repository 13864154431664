const RULE_TYPES = [
  'cost',
  'cost_shipping',
  'additional_cost',
  'commission',
  'tax_rate',
  'behavior',
  'margin',
  'adjustment',
  'enable',
  'min_price',
  'max_price',
  'price_without_competitor',
  'shipping_price',
  'final_price',
];

export class Rule {
  static categorize(rules) {
    const categorizedRules = {};
    RULE_TYPES.forEach(type => {
      const existingRules = rules.filter(rule => rule.type === type.toUpperCase());
      if (existingRules.length) {
        categorizedRules[type] = existingRules;
      }
    });

    return categorizedRules;
  }

  static categorizeRuleTypes(ruleTypes) {
    return RULE_TYPES.map(type => ruleTypes.filter(rule => rule.type === type)[0]);
  }

  static getRuleById(ruleId, categorizedRules) {
    let rule = null;
    Object.keys(categorizedRules).some(ruleType => {
      const filteredRules = categorizedRules[ruleType].filter(rule => rule.id === ruleId);
      const hasFilteredRules = filteredRules.length > 0;
      if (hasFilteredRules) {
        rule = filteredRules[0];
      }
      return hasFilteredRules;
    });
    return rule;
  }

  static getRequiredParameters(rule, ruleTypes) {
    return ruleTypes.rules_fields
      .filter(ruleType => ruleType.type === rule.type.toLowerCase())[0]
      .require_fields.map(requiredField => requiredField.name);
  }

  static isRequired(ruleType, requiredRules) {
    return requiredRules.filter(type => type === ruleType).length > 0;
  }
}
