import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getChannels } from '../actions/channels';
import { useFormatMessage } from '../hooks/intl.hook';
import { getRepricingProgress } from '../actions/repricing';
import { formatReports } from '../reducers/reports';
import { getReports } from '../actions/reports';

import MainHeader from './MainHeader/MainHeader';

function Navigation({ children, match, channels, repricing, reports, dispatch }) {
  const intl = useFormatMessage();

  useEffect(() => {
    dispatch(getChannels());
    dispatch(getRepricingProgress());
    dispatch(getReports());
  }, [dispatch]);

  return (
    <Layout>
      <MainHeader match={match} channels={channels} reports={reports} />

      {repricing.toReprice === 'yes' && (
        <span
          style={{
            color: '#f47900',
            backgroundColor: '#fff',
            borderBottom: '1px solid #bfbfbf',
            textAlign: 'right',
            paddingRight: '10px',
            lineHeight: '2em',
            display: 'inline-block',
          }}
        >
          {repricing.inProgress === 'yes'
            ? intl({ id: 'repricing.progression' }, { progress: repricing.progress })
            : intl({ id: 'repricing.planned' })}
        </span>
      )}
      <Layout>
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

Navigation.propTypes = {
  channels: PropTypes.array.isRequired,
};

export default withRouter(
  connect(state => ({
    channels: state.channels.list,
    repricing: state.repricing,
    reports: formatReports(state),
  }))(Navigation)
);
