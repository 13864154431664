import React from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';

import { clearAuthError } from '../actions/auth';
import Navigation from '../containers/Navigation';
import Error from '../components/Error/Error';
import { IS_DEV } from '../api/tools/privateCall';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearAuthError());
  }

  componentDidCatch(error) {
    // TODO check if errors caught error are still sent to rollbar ?
    this.setState({ runtime_error: error.message });
  }

  render() {
    const { runtime_error: error } = this.state;
    const { component: Component, ...rest } = this.props;
    const { loggedIn } = rest;

    return (
      <Route
        {...rest}
        render={props =>
          loggedIn === true ? (
            <Navigation>
              {error ? (
                <Error
                  message={IS_DEV() ? error : 'errors.generic'}
                  type="error"
                  style={{ margin: 20 }}
                />
              ) : (
                <Component {...props} />
              )}
            </Navigation>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
