export const GET_CHANNEL_CONFIGURATION_STATUS = 'GET_CHANNEL_CONFIGURATION_STATUS';
export const GETTING_CHANNEL_CONFIGURATION_STATUS = 'GETTING_CHANNEL_CONFIGURATION_STATUS';
export const SET_CHANNEL_CONFIGURATION_STATUS = 'SET_CHANNEL_CONFIGURATION_STATUS';

export const GET_MATCHING_STATUS = 'GET_MATCHING_STATUS';

export const GET_CHANNEL_API_CONFIGURATION = 'GET_CHANNEL_API_CONFIGURATION';
export const GETTING_CHANNEL_API_CONFIGURATION = 'GETTING_CHANNEL_API_CONFIGURATION';

export const GET_CHANNEL_SELLER_REFERENCE = 'GET_CHANNEL_SELLER_REFERENCE';
export const GET_CHANNELS_SELLER_REFERENCES = 'GET_CHANNELS_SELLER_REFERENCES';
export const GETTING_CHANNEL_SELLER_REFERENCE = 'GETTING_CHANNEL_SELLER_REFERENCE';
export const POST_CHANNEL_SELLER_REFERENCE = 'POST_CHANNEL_SELLER_REFERENCE';
export const POSTING_CHANNEL_SELLER_REFERENCE = 'POSTING_CHANNEL_SELLER_REFERENCE';
export const SET_CHANNEL_SELLER_REFERENCE = 'SET_CHANNEL_SELLER_REFERENCE';

export const SET_CHANNEL_API_CONFIGURATION = 'SET_CHANNEL_API_CONFIGURATION';
export const PUT_CHANNEL_API_CONFIGURATION = 'PUT_CHANNEL_API_CONFIGURATION';
export const PUTTING_CHANNEL_API_CONFIGURATION = 'PUTTING_CHANNEL_API_CONFIGURATION';
export const CLEAR_ERROR_CHANNEL_API_CONFIGURATION = 'CLEAR_ERROR_CHANNEL_API_CONFIGURATION';

export const GET_CHANNEL_PRODUCT_INFORMATION = 'GET_CHANNEL_PRODUCT_INFORMATION';
export const GET_CHANNELS_PRODUCT_INFORMATION = 'GET_CHANNELS_PRODUCT_INFORMATION';
export const SET_CHANNEL_PRODUCT_INFORMATION = 'SET_CHANNEL_PRODUCT_INFORMATION';
export const GETTING_CHANNEL_PRODUCT_INFORMATION = 'GETTING_CHANNEL_PRODUCT_INFORMATION';
export const SET_CHANNEL_PRODUCT_INFORMATION_ERROR = 'SET_CHANNEL_PRODUCT_INFORMATION_ERROR';

export const GET_CHANNEL_PRODUCT_BEHAVIOR = 'GET_CHANNEL_PRODUCT_BEHAVIOR';
export const GET_CHANNELS_PRODUCT_BEHAVIOR = 'GET_CHANNELS_PRODUCT_BEHAVIOR';
export const SET_CHANNEL_PRODUCT_BEHAVIOR = 'SET_CHANNEL_PRODUCT_BEHAVIOR';
export const GETTING_CHANNEL_PRODUCT_BEHAVIOR = 'GETTING_CHANNEL_PRODUCT_BEHAVIOR';
export const SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR = 'SET_CHANNEL_PRODUCT_BEHAVIOR_ERROR';

export const GET_CHANNEL_PRODUCT_OFFERS = 'GET_CHANNEL_PRODUCT_OFFERS';
export const GET_CHANNELS_PRODUCT_OFFERS = 'GET_CHANNELS_PRODUCT_OFFERS';
export const SET_CHANNEL_PRODUCT_OFFERS = 'SET_CHANNEL_PRODUCT_OFFERS';
export const GETTING_CHANNEL_PRODUCT_OFFERS = 'GETTING_CHANNEL_PRODUCT_OFFERS';
export const SET_CHANNEL_PRODUCT_OFFERS_ERROR = 'SET_CHANNEL_PRODUCT_OFFERS_ERROR';

export const SIMULATE_PRICING = 'SIMULATE_PRICING';
export const SIMULATE_MULTIPLE_PRICING = 'SIMULATE_MULTIPLE_PRICING';
export const SIMULATING_PRICING = 'SIMULATING_PRICING';
export const SET_SIMULATION_PRICING_INFO = 'SET_SIMULATION_PRICING_INFO';
export const SET_SIMULATION_PRICING_ERROR = 'SET_SIMULATION_PRICING_ERROR';

export const SET_CHANNEL = 'SET_CHANNEL';

export const GET_OFFERS_HISTORY = 'GET_CHANNEL_OFFERS_HISTORY';
export const GET_OFFERS_HISTORIES = 'GET_CHANNELS_OFFERS_HISTORIES';
export const GETTING_OFFERS_HISTORY = 'GETTING_OFFERS_HISTORY';
export const SET_OFFERS_HISTORY = 'SET_OFFERS_HISTORY';
export const SET_OFFERS_HISTORY_ERROR = 'SET_OFFERS_HISTORY_ERROR';

export const GET_PRICING_HISTORY = 'GET_CHANNEL_PRICING_HISTORY';
export const GET_PRICING_HISTORIES = 'GET_CHANNELS_PRICING_HISTORIES';
export const GETTING_PRICING_HISTORY = 'GETTING_PRICING_HISTORY';
export const SET_PRICING_HISTORY = 'SET_PRICING_HISTORY';
export const SET_PRICING_HISTORY_ERROR = 'SET_PRICING_HISTORY_ERROR';

export const getChannelConfigurationStatus = channel_id => {
  return { type: GET_CHANNEL_CONFIGURATION_STATUS, channel_id };
};

export const getChannelSellerReference = channel_id => {
  return { type: GET_CHANNEL_SELLER_REFERENCE, channel_id };
};

export const getChannelsSellerReferences = channels => {
  return { type: GET_CHANNELS_SELLER_REFERENCES, channels };
};

export const updateChannelSellerReference = (channel_id, reference) => {
  return { type: POST_CHANNEL_SELLER_REFERENCE, channel_id, ...reference };
};

export const getChannelApiCredentials = channel_id => {
  return { type: GET_CHANNEL_API_CONFIGURATION, channel_id };
};

export const getChannelProductInformation = (channelReference, channelId) => {
  return { type: GET_CHANNEL_PRODUCT_INFORMATION, channelReference, channelId };
};

export const getChannelsProductInformation = channelsReferences => {
  return { type: GET_CHANNELS_PRODUCT_INFORMATION, channelsReferences };
};

export const getChannelProductOffers = (channelReference, channelId) => {
  return { type: GET_CHANNEL_PRODUCT_OFFERS, channelReference, channelId };
};

export const getChannelsProductOffers = channelsReferences => {
  return { type: GET_CHANNELS_PRODUCT_OFFERS, channelsReferences };
};

export const updateChannelApiCredentials = (channel, credentials) => {
  return { type: PUT_CHANNEL_API_CONFIGURATION, channel, credentials };
};

export const setChannel = channel => {
  return { type: SET_CHANNEL, channel };
};

export const getMatchingStatus = channel => {
  return { type: GET_MATCHING_STATUS, channel };
};

export const simulatePricing = (productId, channelId, rules) => {
  return { type: SIMULATE_PRICING, productId, channelId, rules };
};

export const simulateMultiplePricing = (productId, channelIds, rules) => {
  return { type: SIMULATE_MULTIPLE_PRICING, productId, channelIds, rules };
};

export const getChannelProductBehavior = (productId, channelId) => {
  return { type: GET_CHANNEL_PRODUCT_BEHAVIOR, productId, channelId };
};

export const getChannelsProductBehavior = (productId, channelIds) => {
  return { type: GET_CHANNELS_PRODUCT_BEHAVIOR, productId, channelIds };
};

export const getOffersHistory = (channel, reference) => {
  return { type: GET_OFFERS_HISTORY, channel, reference };
};

export const getPricingHistory = (channel, reference) => {
  return { type: GET_PRICING_HISTORY, channel, reference };
};

export const getOffersHistories = channelsReferences => {
  return { type: GET_OFFERS_HISTORIES, channelsReferences };
};
